import {IPokemonVariety} from "../models/PokemonVariety";

export const pokemonVarieties: Record<number, IPokemonVariety> =
    {
        0: {
            id: 0,
            name: "-",
            specie: 0,
            is_default: true,
            type1: "unknown",
            evolutions: [],
            baseHp: 0,
            baseAtk: 0,
            baseDef: 0,
            baseSpeed: 0,
            moves: [0]
        },
        1: {
            id: 1,
            name: "bulbasaur",
            specie: 1,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 2, method: "level_up", minLevel: 16},],
            baseHp: 45,
            baseAtk: 57,
            baseDef: 57,
            baseSpeed: 45,
            moves: [15, 20, 22, 33, 72, 75, 99, 173, 189, 210, 237, 249, 331, 345, 496, 497]
        }, 2: {
            id: 2,
            name: "ivysaur",
            specie: 2,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 3, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 71,
            baseDef: 72,
            baseSpeed: 60,
            moves: [15, 20, 22, 33, 72, 75, 99, 173, 189, 210, 237, 249, 331, 496, 497]
        }, 3: {
            id: 3,
            name: "venusaur",
            specie: 3,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [],
            baseHp: 80,
            baseAtk: 91,
            baseDef: 92,
            baseSpeed: 80,
            moves: [15, 20, 22, 33, 72, 75, 99, 173, 189, 210, 237, 249, 331, 496, 497, 523]
        }, 4: {
            id: 4,
            name: "charmander",
            specie: 4,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 5, method: "level_up", minLevel: 16},],
            baseHp: 39,
            baseAtk: 56,
            baseDef: 47,
            baseSpeed: 65,
            moves: [10, 15, 44, 52, 83, 99, 129, 173, 189, 210, 225, 232, 237, 246, 249, 314, 317, 332, 488, 496, 497, 510, 612]
        }, 5: {
            id: 5,
            name: "charmeleon",
            specie: 5,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 6, method: "level_up", minLevel: 36},],
            baseHp: 58,
            baseAtk: 72,
            baseDef: 62,
            baseSpeed: 80,
            moves: [10, 15, 52, 83, 99, 129, 173, 189, 210, 225, 232, 237, 249, 317, 332, 488, 496, 497, 510, 612]
        }, 6: {
            id: 6,
            name: "charizard",
            specie: 6,
            is_default: true,
            type1: "fire",
            type2: "flying",
            evolutions: [],
            baseHp: 78,
            baseAtk: 97,
            baseDef: 82,
            baseSpeed: 100,
            moves: [10, 15, 17, 52, 83, 99, 129, 173, 189, 210, 225, 232, 237, 239, 249, 314, 317, 332, 466, 488, 496, 497, 507, 510, 523, 525, 612, 693]
        }, 7: {
            id: 7,
            name: "squirtle",
            specie: 7,
            is_default: true,
            type1: "water",
            evolutions: [{to: 8, method: "level_up", minLevel: 16},],
            baseHp: 44,
            baseAtk: 49,
            baseDef: 65,
            baseSpeed: 43,
            moves: [33, 44, 55, 93, 99, 145, 173, 189, 196, 205, 229, 237, 249, 250, 252, 317, 352, 453, 496, 612]
        }, 8: {
            id: 8,
            name: "wartortle",
            specie: 8,
            is_default: true,
            type1: "water",
            evolutions: [{to: 9, method: "level_up", minLevel: 36},],
            baseHp: 59,
            baseAtk: 64,
            baseDef: 80,
            baseSpeed: 58,
            moves: [33, 44, 55, 99, 145, 173, 189, 196, 205, 229, 237, 249, 250, 317, 352, 496, 612]
        }, 9: {
            id: 9,
            name: "blastoise",
            specie: 9,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 79,
            baseAtk: 84,
            baseDef: 103,
            baseSpeed: 78,
            moves: [33, 44, 55, 99, 145, 173, 189, 196, 205, 229, 237, 249, 250, 317, 352, 419, 479, 496, 523, 525, 612]
        }, 10: {
            id: 10,
            name: "caterpie",
            specie: 10,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 11, method: "level_up", minLevel: 7},],
            baseHp: 45,
            baseAtk: 25,
            baseDef: 28,
            baseSpeed: 45,
            moves: [33, 173, 450, 527]
        }, 11: {
            id: 11,
            name: "metapod",
            specie: 11,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 12, method: "level_up", minLevel: 10},],
            baseHp: 50,
            baseAtk: 23,
            baseDef: 40,
            baseSpeed: 30,
            moves: [450, 527]
        }, 12: {
            id: 12,
            name: "butterfree",
            specie: 12,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 60,
            baseAtk: 68,
            baseDef: 65,
            baseSpeed: 70,
            moves: [16, 72, 93, 99, 129, 168, 173, 237, 239, 314, 318, 332, 450, 466, 496, 512, 522, 527, 611]
        }, 13: {
            id: 13,
            name: "weedle",
            specie: 13,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [{to: 14, method: "level_up", minLevel: 7},],
            baseHp: 40,
            baseAtk: 28,
            baseDef: 25,
            baseSpeed: 50,
            moves: [40, 450, 527]
        }, 14: {
            id: 14,
            name: "kakuna",
            specie: 14,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [{to: 15, method: "level_up", minLevel: 10},],
            baseHp: 45,
            baseAtk: 25,
            baseDef: 38,
            baseSpeed: 35,
            moves: [450, 527]
        }, 15: {
            id: 15,
            name: "beedrill",
            specie: 15,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [],
            baseHp: 65,
            baseAtk: 68,
            baseDef: 60,
            baseSpeed: 75,
            moves: [15, 31, 41, 42, 72, 99, 129, 168, 173, 206, 210, 228, 237, 249, 314, 318, 332, 371, 372, 450, 466, 496, 512, 522, 527, 565, 611, 693]
        }, 16: {
            id: 16,
            name: "pidgey",
            specie: 16,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 17, method: "level_up", minLevel: 18},],
            baseHp: 40,
            baseAtk: 40,
            baseDef: 38,
            baseSpeed: 56,
            moves: [16, 17, 33, 98, 99, 129, 168, 173, 185, 189, 228, 237, 239, 314, 332, 365, 466, 496]
        }, 17: {
            id: 17,
            name: "pidgeotto",
            specie: 17,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 18, method: "level_up", minLevel: 36},],
            baseHp: 63,
            baseAtk: 55,
            baseDef: 53,
            baseSpeed: 71,
            moves: [16, 17, 33, 98, 99, 129, 168, 173, 189, 237, 239, 314, 332, 365, 466, 496]
        }, 18: {
            id: 18,
            name: "pidgeot",
            specie: 18,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 83,
            baseAtk: 75,
            baseDef: 73,
            baseSpeed: 101,
            moves: [16, 17, 33, 98, 99, 129, 168, 173, 189, 237, 239, 314, 332, 365, 466, 496]
        }, 19: {
            id: 19,
            name: "rattata",
            specie: 19,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 20, method: "level_up", minLevel: 20},],
            baseHp: 30,
            baseAtk: 41,
            baseDef: 35,
            baseSpeed: 72,
            moves: [15, 33, 44, 55, 98, 99, 129, 154, 168, 172, 173, 189, 196, 228, 237, 249, 279, 343, 351, 365, 372, 451, 496]
        }, 20: {
            id: 20,
            name: "raticate",
            specie: 20,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 55,
            baseAtk: 66,
            baseDef: 65,
            baseSpeed: 97,
            moves: [15, 33, 44, 55, 98, 99, 129, 168, 173, 189, 196, 228, 237, 249, 343, 351, 365, 372, 451, 496]
        }, 21: {
            id: 21,
            name: "spearow",
            specie: 21,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 22, method: "level_up", minLevel: 20},],
            baseHp: 40,
            baseAtk: 46,
            baseDef: 31,
            baseSpeed: 70,
            moves: [31, 64, 98, 99, 129, 168, 173, 185, 189, 206, 228, 237, 239, 310, 314, 332, 365, 372, 466, 496, 497]
        }, 22: {
            id: 22,
            name: "fearow",
            specie: 22,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 65,
            baseAtk: 76,
            baseDef: 63,
            baseSpeed: 100,
            moves: [31, 64, 99, 129, 168, 173, 189, 206, 228, 237, 239, 314, 332, 365, 372, 466, 496, 497]
        }, 23: {
            id: 23,
            name: "ekans",
            specie: 23,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 24, method: "level_up", minLevel: 22},],
            baseHp: 35,
            baseAtk: 50,
            baseDef: 49,
            baseSpeed: 55,
            moves: [20, 35, 40, 44, 51, 72, 99, 168, 173, 228, 237, 305, 317, 342, 371, 491, 496, 523, 611, 693]
        }, 24: {
            id: 24,
            name: "arbok",
            specie: 24,
            is_default: true,
            type1: "poison",
            evolutions: [],
            baseHp: 60,
            baseAtk: 80,
            baseDef: 74,
            baseSpeed: 80,
            moves: [20, 35, 40, 44, 51, 72, 99, 168, 173, 237, 317, 371, 491, 496, 523, 525, 611, 693]
        }, 25: {
            id: 25,
            name: "pikachu",
            specie: 25,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 26, method: "level_up", minLevel: 32},],
            baseHp: 35,
            baseAtk: 53,
            baseDef: 45,
            baseSpeed: 90,
            moves: [6, 84, 98, 99, 129, 173, 189, 205, 237, 249, 343, 351, 364, 451, 496, 497, 527, 609]
        }, 26: {
            id: 26,
            name: "raichu",
            specie: 26,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 60,
            baseAtk: 90,
            baseDef: 68,
            baseSpeed: 110,
            moves: [6, 84, 98, 99, 129, 168, 173, 189, 205, 237, 249, 343, 351, 451, 496, 497, 527]
        }, 27: {
            id: 27,
            name: "sandshrew",
            specie: 27,
            is_default: true,
            type1: "ground",
            evolutions: [{to: 28, method: "level_up", minLevel: 22},],
            baseHp: 50,
            baseAtk: 48,
            baseDef: 58,
            baseSpeed: 40,
            moves: [10, 15, 40, 99, 129, 154, 168, 173, 189, 205, 210, 229, 232, 237, 249, 317, 328, 332, 341, 343, 496, 523]
        }, 28: {
            id: 28,
            name: "sandslash",
            specie: 28,
            is_default: true,
            type1: "ground",
            evolutions: [],
            baseHp: 75,
            baseAtk: 73,
            baseDef: 83,
            baseSpeed: 65,
            moves: [10, 15, 40, 99, 129, 154, 168, 173, 189, 205, 210, 229, 237, 249, 317, 328, 332, 343, 496, 523]
        }, 29: {
            id: 29,
            name: "nidoran-f",
            specie: 29,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 30, method: "level_up", minLevel: 16},],
            baseHp: 55,
            baseAtk: 44,
            baseDef: 46,
            baseSpeed: 41,
            moves: [10, 15, 24, 33, 40, 44, 99, 154, 168, 173, 189, 228, 237, 249, 305, 332, 342, 351, 352, 496, 497]
        }, 30: {
            id: 30,
            name: "nidorina",
            specie: 30,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 31, method: "level_up", minLevel: 32},],
            baseHp: 70,
            baseAtk: 59,
            baseDef: 61,
            baseSpeed: 56,
            moves: [10, 15, 24, 33, 40, 44, 55, 99, 154, 168, 173, 189, 237, 249, 305, 332, 351, 352, 496, 497]
        }, 31: {
            id: 31,
            name: "nidoqueen",
            specie: 31,
            is_default: true,
            type1: "poison",
            type2: "ground",
            evolutions: [],
            baseHp: 90,
            baseAtk: 84,
            baseDef: 86,
            baseSpeed: 76,
            moves: [6, 10, 15, 24, 33, 40, 55, 99, 168, 173, 189, 196, 210, 237, 249, 250, 317, 332, 351, 352, 419, 479, 496, 497, 510, 523, 525, 612]
        }, 32: {
            id: 32,
            name: "nidoran-m",
            specie: 32,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 33, method: "level_up", minLevel: 16},],
            baseHp: 46,
            baseAtk: 49,
            baseDef: 40,
            baseSpeed: 50,
            moves: [15, 24, 31, 33, 40, 64, 93, 99, 168, 173, 189, 237, 249, 342, 351, 352, 496, 497]
        }, 33: {
            id: 33,
            name: "nidorino",
            specie: 33,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 34, method: "level_up", minLevel: 32},],
            baseHp: 61,
            baseAtk: 64,
            baseDef: 56,
            baseSpeed: 65,
            moves: [15, 24, 31, 33, 40, 55, 64, 99, 168, 173, 189, 237, 249, 351, 352, 496, 497]
        }, 34: {
            id: 34,
            name: "nidoking",
            specie: 34,
            is_default: true,
            type1: "poison",
            type2: "ground",
            evolutions: [],
            baseHp: 81,
            baseAtk: 94,
            baseDef: 76,
            baseSpeed: 85,
            moves: [6, 15, 24, 33, 40, 55, 64, 99, 168, 173, 189, 196, 210, 237, 249, 250, 317, 351, 352, 419, 479, 496, 497, 510, 523, 525, 612]
        }, 35: {
            id: 35,
            name: "clefairy",
            specie: 35,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 36, method: "level_up", minLevel: 32},],
            baseHp: 70,
            baseAtk: 53,
            baseDef: 57,
            baseSpeed: 35,
            moves: [1, 3, 55, 99, 173, 189, 196, 205, 237, 249, 343, 351, 352, 451, 496, 497, 500, 510, 574, 612]
        }, 36: {
            id: 36,
            name: "clefable",
            specie: 36,
            is_default: true,
            type1: "fairy",
            evolutions: [],
            baseHp: 95,
            baseAtk: 83,
            baseDef: 82,
            baseSpeed: 60,
            moves: [3, 55, 99, 173, 189, 196, 205, 237, 249, 343, 351, 352, 451, 496, 497, 510, 574, 612]
        }, 37: {
            id: 37,
            name: "vulpix",
            specie: 37,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 38, method: "level_up", minLevel: 32},],
            baseHp: 38,
            baseAtk: 46,
            baseDef: 53,
            baseSpeed: 65,
            moves: [52, 83, 98, 99, 129, 173, 185, 237, 343, 371, 466, 488, 496, 510, 541]
        }, 38: {
            id: 38,
            name: "ninetales",
            specie: 38,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 73,
            baseAtk: 79,
            baseDef: 88,
            baseSpeed: 100,
            moves: [52, 83, 98, 99, 129, 173, 237, 343, 371, 466, 488, 496, 510]
        }, 39: {
            id: 39,
            name: "jigglypuff",
            specie: 39,
            is_default: true,
            type1: "normal",
            type2: "fairy",
            evolutions: [{to: 40, method: "level_up", minLevel: 32},],
            baseHp: 115,
            baseAtk: 45,
            baseDef: 23,
            baseSpeed: 20,
            moves: [1, 3, 55, 99, 173, 189, 196, 205, 237, 343, 351, 352, 451, 496, 497, 510, 574, 612]
        }, 40: {
            id: 40,
            name: "wigglytuff",
            specie: 40,
            is_default: true,
            type1: "normal",
            type2: "fairy",
            evolutions: [],
            baseHp: 140,
            baseAtk: 78,
            baseDef: 48,
            baseSpeed: 45,
            moves: [3, 55, 99, 173, 189, 196, 205, 237, 343, 351, 352, 451, 496, 497, 510, 612]
        }, 41: {
            id: 41,
            name: "zubat",
            specie: 41,
            is_default: true,
            type1: "poison",
            type2: "flying",
            evolutions: [{to: 42, method: "level_up", minLevel: 22},],
            baseHp: 40,
            baseAtk: 38,
            baseDef: 38,
            baseSpeed: 55,
            moves: [16, 17, 44, 71, 72, 98, 99, 129, 168, 173, 185, 228, 237, 239, 305, 310, 314, 332, 365, 371, 466, 496, 512]
        }, 42: {
            id: 42,
            name: "golbat",
            specie: 42,
            is_default: true,
            type1: "poison",
            type2: "flying",
            evolutions: [{to: 169, method: "level_up", minLevel: 32},],
            baseHp: 75,
            baseAtk: 73,
            baseDef: 73,
            baseSpeed: 90,
            moves: [17, 44, 71, 72, 99, 129, 168, 173, 237, 239, 305, 310, 314, 332, 365, 371, 466, 496, 512]
        }, 43: {
            id: 43,
            name: "oddish",
            specie: 43,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 44, method: "level_up", minLevel: 21},],
            baseHp: 45,
            baseAtk: 63,
            baseDef: 60,
            baseSpeed: 30,
            moves: [15, 51, 71, 72, 75, 99, 173, 237, 331, 496, 611]
        }, 44: {
            id: 44,
            name: "gloom",
            specie: 44,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 45, method: "level_up", minLevel: 32}, {to: 182, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 75,
            baseDef: 73,
            baseSpeed: 40,
            moves: [15, 51, 71, 72, 99, 173, 237, 331, 496, 611]
        }, 45: {
            id: 45, name: "vileplume", specie: 45, is_default: true, type1: "grass", type2: "poison", evolutions: [],
            baseHp: 75, baseAtk: 95, baseDef: 88, baseSpeed: 50, moves: [15, 51, 71, 72, 99, 173, 237, 331, 496, 611]
        }, 46: {
            id: 46,
            name: "paras",
            specie: 46,
            is_default: true,
            type1: "bug",
            type2: "grass",
            evolutions: [{to: 47, method: "level_up", minLevel: 24},],
            baseHp: 35,
            baseAtk: 58,
            baseDef: 55,
            baseSpeed: 25,
            moves: [10, 15, 71, 72, 99, 168, 173, 206, 210, 228, 232, 237, 249, 331, 332, 450, 496, 522, 565]
        }, 47: {
            id: 47,
            name: "parasect",
            specie: 47,
            is_default: true,
            type1: "bug",
            type2: "grass",
            evolutions: [],
            baseHp: 60,
            baseAtk: 78,
            baseDef: 80,
            baseSpeed: 30,
            moves: [10, 15, 71, 72, 99, 168, 173, 206, 210, 237, 249, 331, 332, 450, 496, 522]
        }, 48: {
            id: 48,
            name: "venonat",
            specie: 48,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [{to: 49, method: "level_up", minLevel: 31},],
            baseHp: 60,
            baseAtk: 48,
            baseDef: 53,
            baseSpeed: 45,
            moves: [33, 72, 93, 99, 129, 168, 173, 237, 305, 450, 496, 522, 611]
        }, 49: {
            id: 49,
            name: "venomoth",
            specie: 49,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [],
            baseHp: 70,
            baseAtk: 78,
            baseDef: 68,
            baseSpeed: 90,
            moves: [16, 33, 72, 93, 99, 129, 168, 173, 237, 239, 305, 314, 318, 332, 450, 466, 496, 512, 522, 611]
        }, 50: {
            id: 50,
            name: "diglett",
            specie: 50,
            is_default: true,
            type1: "ground",
            evolutions: [{to: 51, method: "level_up", minLevel: 26},],
            baseHp: 10,
            baseAtk: 45,
            baseDef: 35,
            baseSpeed: 95,
            moves: [10, 15, 99, 154, 168, 173, 185, 189, 228, 237, 246, 249, 310, 317, 332, 496, 497, 523]
        }, 51: {
            id: 51,
            name: "dugtrio",
            specie: 51,
            is_default: true,
            type1: "ground",
            evolutions: [],
            baseHp: 35,
            baseAtk: 75,
            baseDef: 60,
            baseSpeed: 120,
            moves: [10, 15, 99, 154, 168, 173, 189, 237, 249, 310, 317, 328, 332, 496, 497, 523]
        }, 52: {
            id: 52,
            name: "meowth",
            specie: 52,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 53, method: "level_up", minLevel: 28},],
            baseHp: 40,
            baseAtk: 43,
            baseDef: 38,
            baseSpeed: 90,
            moves: [6, 10, 15, 44, 55, 99, 129, 154, 168, 173, 185, 189, 196, 237, 252, 332, 343, 351, 352, 364, 371, 372, 496, 497]
        }, 53: {
            id: 53,
            name: "persian",
            specie: 53,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 65,
            baseAtk: 68,
            baseDef: 63,
            baseSpeed: 115,
            moves: [6, 10, 15, 44, 55, 99, 129, 154, 168, 173, 185, 189, 196, 237, 252, 332, 343, 351, 352, 364, 371, 372, 496, 497]
        }, 54: {
            id: 54,
            name: "psyduck",
            specie: 54,
            is_default: true,
            type1: "water",
            evolutions: [{to: 55, method: "level_up", minLevel: 33},],
            baseHp: 50,
            baseAtk: 59,
            baseDef: 49,
            baseSpeed: 55,
            moves: [6, 10, 55, 93, 99, 129, 154, 173, 189, 196, 237, 249, 250, 332, 352, 496, 499, 612]
        }, 55: {
            id: 55,
            name: "golduck",
            specie: 55,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 80,
            baseAtk: 89,
            baseDef: 79,
            baseSpeed: 85,
            moves: [6, 10, 55, 93, 99, 129, 154, 173, 189, 196, 210, 237, 249, 250, 332, 352, 453, 496, 612]
        }, 56: {
            id: 56,
            name: "mankey",
            specie: 56,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 57, method: "level_up", minLevel: 28},],
            baseHp: 40,
            baseAtk: 58,
            baseDef: 40,
            baseSpeed: 70,
            moves: [2, 6, 10, 99, 129, 154, 168, 173, 189, 228, 237, 249, 279, 317, 332, 343, 371, 372, 410, 479, 496, 512, 523, 530, 612, 681]
        }, 57: {
            id: 57,
            name: "primeape",
            specie: 57,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 65,
            baseAtk: 83,
            baseDef: 65,
            baseSpeed: 95,
            moves: [2, 6, 10, 99, 129, 154, 168, 173, 189, 228, 237, 249, 317, 332, 343, 371, 372, 410, 479, 496, 512, 523, 530, 612]
        }, 58: {
            id: 58,
            name: "growlithe",
            specie: 58,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 59, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 70,
            baseDef: 48,
            baseSpeed: 60,
            moves: [24, 44, 52, 83, 99, 129, 168, 172, 173, 189, 225, 237, 249, 332, 343, 488, 496, 510, 555]
        }, 59: {
            id: 59,
            name: "arcanine",
            specie: 59,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 90,
            baseAtk: 105,
            baseDef: 80,
            baseSpeed: 95,
            moves: [44, 52, 99, 129, 168, 172, 173, 189, 225, 237, 249, 332, 343, 488, 496, 510, 523, 555]
        }, 60: {
            id: 60,
            name: "poliwag",
            specie: 60,
            is_default: true,
            type1: "water",
            evolutions: [{to: 61, method: "level_up", minLevel: 25},],
            baseHp: 40,
            baseAtk: 45,
            baseDef: 40,
            baseSpeed: 90,
            moves: [3, 55, 99, 145, 168, 173, 196, 237, 250, 301, 341, 352, 496]
        }, 61: {
            id: 61,
            name: "poliwhirl",
            specie: 61,
            is_default: true,
            type1: "water",
            evolutions: [{to: 62, method: "level_up", minLevel: 32}, {to: 186, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 58,
            baseDef: 58,
            baseSpeed: 90,
            moves: [3, 55, 99, 145, 168, 173, 189, 196, 237, 249, 250, 341, 352, 496, 523, 612]
        }, 62: {
            id: 62,
            name: "poliwrath",
            specie: 62,
            is_default: true,
            type1: "water",
            type2: "fighting",
            evolutions: [],
            baseHp: 90,
            baseAtk: 83,
            baseDef: 93,
            baseSpeed: 70,
            moves: [3, 55, 99, 168, 173, 189, 196, 237, 249, 250, 317, 352, 371, 410, 496, 509, 523, 612]
        }, 63: {
            id: 63,
            name: "abra",
            specie: 63,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 64, method: "level_up", minLevel: 16},],
            baseHp: 25,
            baseAtk: 63,
            baseDef: 35,
            baseSpeed: 90,
            moves: [99, 168, 173, 237, 351, 451, 496]
        }, 64: {
            id: 64,
            name: "kadabra",
            specie: 64,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 65, method: "level_up", minLevel: 32},],
            baseHp: 40,
            baseAtk: 78,
            baseDef: 50,
            baseSpeed: 105,
            moves: [93, 99, 168, 173, 237, 351, 451, 496]
        }, 65: {
            id: 65, name: "alakazam", specie: 65, is_default: true, type1: "psychic", evolutions: [],
            baseHp: 55, baseAtk: 93, baseDef: 70, baseSpeed: 120, moves: [93, 99, 168, 173, 237, 351, 451, 496]
        }, 66: {
            id: 66,
            name: "machop",
            specie: 66,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 67, method: "level_up", minLevel: 28},],
            baseHp: 70,
            baseAtk: 58,
            baseDef: 43,
            baseSpeed: 35,
            moves: [2, 27, 99, 168, 173, 189, 237, 249, 279, 317, 371, 410, 418, 479, 496, 510, 523, 530, 612]
        }, 67: {
            id: 67,
            name: "machoke",
            specie: 67,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 68, method: "level_up", minLevel: 32},],
            baseHp: 80,
            baseAtk: 75,
            baseDef: 65,
            baseSpeed: 45,
            moves: [2, 99, 168, 173, 189, 237, 249, 279, 317, 371, 410, 479, 496, 510, 523, 530, 612]
        }, 68: {
            id: 68,
            name: "machamp",
            specie: 68,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 90,
            baseAtk: 98,
            baseDef: 83,
            baseSpeed: 55,
            moves: [2, 99, 168, 173, 189, 237, 249, 279, 317, 371, 410, 479, 496, 510, 523, 530, 612]
        }, 69: {
            id: 69,
            name: "bellsprout",
            specie: 69,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 70, method: "level_up", minLevel: 21},],
            baseHp: 50,
            baseAtk: 73,
            baseDef: 33,
            baseSpeed: 40,
            moves: [15, 20, 22, 35, 51, 72, 75, 99, 168, 173, 237, 311, 331, 345, 491, 496, 499, 611]
        }, 70: {
            id: 70,
            name: "weepinbell",
            specie: 70,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 71, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 88,
            baseDef: 48,
            baseSpeed: 55,
            moves: [15, 20, 22, 35, 51, 72, 75, 99, 168, 173, 237, 331, 450, 496, 611]
        }, 71: {
            id: 71,
            name: "victreebel",
            specie: 71,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [],
            baseHp: 80,
            baseAtk: 103,
            baseDef: 68,
            baseSpeed: 70,
            moves: [15, 20, 22, 35, 51, 72, 75, 99, 168, 173, 237, 331, 450, 496, 611]
        }, 72: {
            id: 72,
            name: "tentacool",
            specie: 72,
            is_default: true,
            type1: "water",
            type2: "poison",
            evolutions: [{to: 73, method: "level_up", minLevel: 30},],
            baseHp: 40,
            baseAtk: 45,
            baseDef: 68,
            baseSpeed: 70,
            moves: [15, 20, 35, 40, 51, 55, 72, 99, 132, 145, 168, 173, 196, 229, 237, 250, 352, 371, 491, 496, 611]
        }, 73: {
            id: 73,
            name: "tentacruel",
            specie: 73,
            is_default: true,
            type1: "water",
            type2: "poison",
            evolutions: [],
            baseHp: 80,
            baseAtk: 75,
            baseDef: 93,
            baseSpeed: 100,
            moves: [15, 20, 35, 40, 51, 55, 72, 99, 132, 168, 173, 196, 237, 250, 352, 371, 491, 496, 611]
        }, 74: {
            id: 74,
            name: "geodude",
            specie: 74,
            is_default: true,
            type1: "rock",
            type2: "ground",
            evolutions: [{to: 75, method: "level_up", minLevel: 25},],
            baseHp: 40,
            baseAtk: 55,
            baseDef: 65,
            baseSpeed: 20,
            moves: [33, 88, 99, 173, 189, 205, 237, 246, 249, 317, 350, 479, 496, 510, 523, 612]
        }, 75: {
            id: 75,
            name: "graveler",
            specie: 75,
            is_default: true,
            type1: "rock",
            type2: "ground",
            evolutions: [{to: 76, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 70,
            baseDef: 80,
            baseSpeed: 35,
            moves: [33, 88, 99, 173, 189, 205, 237, 246, 249, 317, 350, 479, 496, 510, 523, 612]
        }, 76: {
            id: 76,
            name: "golem",
            specie: 76,
            is_default: true,
            type1: "rock",
            type2: "ground",
            evolutions: [],
            baseHp: 80,
            baseAtk: 88,
            baseDef: 98,
            baseSpeed: 45,
            moves: [33, 88, 99, 173, 189, 205, 210, 237, 246, 249, 317, 350, 479, 496, 510, 523, 612]
        }, 77: {
            id: 77,
            name: "ponyta",
            specie: 77,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 78, method: "level_up", minLevel: 40},],
            baseHp: 50,
            baseAtk: 75,
            baseDef: 60,
            baseSpeed: 90,
            moves: [24, 33, 52, 83, 98, 99, 129, 172, 173, 237, 488, 496, 497, 510]
        }, 78: {
            id: 78,
            name: "rapidash",
            specie: 78,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 65,
            baseAtk: 90,
            baseDef: 75,
            baseSpeed: 105,
            moves: [31, 33, 52, 83, 98, 99, 129, 172, 173, 237, 488, 496, 497, 510]
        }, 79: {
            id: 79,
            name: "slowpoke",
            specie: 79,
            is_default: true,
            type1: "water",
            type2: "psychic",
            evolutions: [{to: 80, method: "level_up", minLevel: 37}, {to: 199, method: "level_up", minLevel: 32},],
            baseHp: 90,
            baseAtk: 53,
            baseDef: 53,
            baseSpeed: 15,
            moves: [6, 33, 55, 93, 99, 129, 173, 189, 196, 237, 250, 352, 496, 497, 510, 523]
        }, 80: {
            id: 80,
            name: "slowbro",
            specie: 80,
            is_default: true,
            type1: "water",
            type2: "psychic",
            evolutions: [],
            baseHp: 95,
            baseAtk: 88,
            baseDef: 95,
            baseSpeed: 30,
            moves: [6, 33, 55, 93, 99, 129, 173, 189, 196, 210, 237, 249, 250, 332, 352, 419, 496, 497, 510, 523]
        }, 81: {
            id: 81,
            name: "magnemite",
            specie: 81,
            is_default: true,
            type1: "electric",
            type2: "steel",
            evolutions: [{to: 82, method: "level_up", minLevel: 30},],
            baseHp: 25,
            baseAtk: 65,
            baseDef: 63,
            baseSpeed: 45,
            moves: [33, 84, 99, 129, 173, 205, 237, 351, 443, 451, 496, 527]
        }, 82: {
            id: 82,
            name: "magneton",
            specie: 82,
            is_default: true,
            type1: "electric",
            type2: "steel",
            evolutions: [{to: 462, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 90,
            baseDef: 83,
            baseSpeed: 70,
            moves: [33, 84, 99, 129, 173, 205, 237, 351, 443, 451, 496, 527]
        }, 83: {
            id: 83,
            name: "farfetchd",
            specie: 83,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 52,
            baseAtk: 74,
            baseDef: 59,
            baseSpeed: 60,
            moves: [15, 16, 31, 64, 98, 99, 129, 168, 173, 189, 206, 210, 237, 239, 279, 314, 332, 343, 364, 365, 466, 496, 512, 693]
        }, 84: {
            id: 84,
            name: "doduo",
            specie: 84,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 85, method: "level_up", minLevel: 31},],
            baseHp: 35,
            baseAtk: 60,
            baseDef: 40,
            baseSpeed: 75,
            moves: [31, 64, 98, 99, 129, 168, 173, 185, 189, 228, 237, 314, 332, 365, 372, 458, 496, 497]
        }, 85: {
            id: 85,
            name: "dodrio",
            specie: 85,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 60,
            baseAtk: 85,
            baseDef: 65,
            baseSpeed: 110,
            moves: [31, 64, 98, 99, 129, 168, 173, 189, 228, 237, 314, 332, 365, 371, 458, 496, 497]
        }, 86: {
            id: 86,
            name: "seel",
            specie: 86,
            is_default: true,
            type1: "water",
            evolutions: [{to: 87, method: "level_up", minLevel: 34},],
            baseHp: 65,
            baseAtk: 45,
            baseDef: 63,
            baseSpeed: 45,
            moves: [6, 55, 64, 99, 122, 168, 173, 196, 237, 250, 252, 333, 352, 420, 453, 496, 497]
        }, 87: {
            id: 87,
            name: "dewgong",
            specie: 87,
            is_default: true,
            type1: "water",
            type2: "ice",
            evolutions: [],
            baseHp: 90,
            baseAtk: 70,
            baseDef: 88,
            baseSpeed: 70,
            moves: [6, 55, 99, 168, 173, 196, 237, 250, 352, 419, 420, 453, 496, 497, 524]
        }, 88: {
            id: 88,
            name: "grimer",
            specie: 88,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 89, method: "level_up", minLevel: 38},],
            baseHp: 80,
            baseAtk: 60,
            baseDef: 50,
            baseSpeed: 25,
            moves: [1, 72, 99, 122, 168, 173, 189, 237, 317, 325, 351, 371, 425, 491, 496, 510, 611, 612]
        }, 89: {
            id: 89,
            name: "muk",
            specie: 89,
            is_default: true,
            type1: "poison",
            evolutions: [],
            baseHp: 105,
            baseAtk: 85,
            baseDef: 88,
            baseSpeed: 50,
            moves: [1, 72, 99, 168, 173, 189, 237, 249, 317, 351, 371, 496, 510, 611, 612]
        }, 90: {
            id: 90,
            name: "shellder",
            specie: 90,
            is_default: true,
            type1: "water",
            evolutions: [{to: 91, method: "level_up", minLevel: 32},],
            baseHp: 30,
            baseAtk: 55,
            baseDef: 63,
            baseSpeed: 40,
            moves: [33, 41, 55, 99, 128, 129, 173, 196, 229, 237, 250, 333, 341, 350, 352, 371, 419, 420, 496]
        }, 91: {
            id: 91,
            name: "cloyster",
            specie: 91,
            is_default: true,
            type1: "water",
            type2: "ice",
            evolutions: [],
            baseHp: 50,
            baseAtk: 90,
            baseDef: 113,
            baseSpeed: 70,
            moves: [55, 99, 128, 129, 131, 173, 196, 237, 250, 352, 371, 419, 496, 524]
        }, 92: {
            id: 92,
            name: "gastly",
            specie: 92,
            is_default: true,
            type1: "ghost",
            type2: "poison",
            evolutions: [{to: 93, method: "level_up", minLevel: 25},],
            baseHp: 30,
            baseAtk: 68,
            baseDef: 33,
            baseSpeed: 80,
            moves: [72, 99, 122, 123, 168, 173, 196, 237, 310, 371, 466, 496, 499, 611]
        }, 93: {
            id: 93,
            name: "haunter",
            specie: 93,
            is_default: true,
            type1: "ghost",
            type2: "poison",
            evolutions: [{to: 94, method: "level_up", minLevel: 32},],
            baseHp: 45,
            baseAtk: 83,
            baseDef: 50,
            baseSpeed: 95,
            moves: [72, 99, 122, 168, 173, 196, 237, 325, 371, 466, 496, 611]
        }, 94: {
            id: 94,
            name: "gengar",
            specie: 94,
            is_default: true,
            type1: "ghost",
            type2: "poison",
            evolutions: [],
            baseHp: 60,
            baseAtk: 98,
            baseDef: 68,
            baseSpeed: 110,
            moves: [72, 99, 122, 168, 173, 196, 237, 249, 325, 371, 466, 496, 611, 612]
        }, 95: {
            id: 95,
            name: "onix",
            specie: 95,
            is_default: true,
            type1: "rock",
            type2: "ground",
            evolutions: [{to: 208, method: "level_up", minLevel: 32},],
            baseHp: 35,
            baseAtk: 38,
            baseDef: 103,
            baseSpeed: 70,
            moves: [20, 33, 88, 99, 173, 189, 205, 225, 237, 239, 246, 249, 317, 328, 350, 371, 479, 496, 523, 525, 693]
        }, 96: {
            id: 96,
            name: "drowzee",
            specie: 96,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 97, method: "level_up", minLevel: 26},],
            baseHp: 60,
            baseAtk: 46,
            baseDef: 68,
            baseSpeed: 42,
            moves: [1, 93, 99, 168, 173, 237, 496, 612]
        }, 97: {
            id: 97, name: "hypno", specie: 97, is_default: true, type1: "psychic", evolutions: [],
            baseHp: 85, baseAtk: 73, baseDef: 93, baseSpeed: 67, moves: [1, 93, 99, 168, 173, 237, 496, 612]
        }, 98: {
            id: 98,
            name: "krabby",
            specie: 98,
            is_default: true,
            type1: "water",
            evolutions: [{to: 99, method: "level_up", minLevel: 28},],
            baseHp: 30,
            baseAtk: 65,
            baseDef: 58,
            baseSpeed: 50,
            moves: [11, 15, 55, 99, 145, 168, 173, 189, 196, 206, 210, 232, 237, 246, 249, 250, 317, 341, 352, 496]
        }, 99: {
            id: 99,
            name: "kingler",
            specie: 99,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 55,
            baseAtk: 90,
            baseDef: 83,
            baseSpeed: 75,
            moves: [11, 15, 55, 99, 145, 168, 173, 189, 196, 206, 210, 232, 237, 246, 249, 250, 317, 341, 352, 496]
        }, 100: {
            id: 100,
            name: "voltorb",
            specie: 100,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 101, method: "level_up", minLevel: 30},],
            baseHp: 40,
            baseAtk: 43,
            baseDef: 53,
            baseSpeed: 100,
            moves: [33, 99, 129, 168, 173, 205, 237, 351, 451, 496]
        }, 101: {
            id: 101,
            name: "electrode",
            specie: 101,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 60,
            baseAtk: 65,
            baseDef: 75,
            baseSpeed: 150,
            moves: [33, 99, 129, 168, 173, 205, 237, 351, 451, 496]
        }, 102: {
            id: 102,
            name: "exeggcute",
            specie: 102,
            is_default: true,
            type1: "grass",
            type2: "psychic",
            evolutions: [{to: 103, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 50,
            baseDef: 63,
            baseSpeed: 40,
            moves: [72, 93, 99, 140, 168, 173, 205, 237, 246, 331, 496, 611]
        }, 103: {
            id: 103,
            name: "exeggutor",
            specie: 103,
            is_default: true,
            type1: "grass",
            type2: "psychic",
            evolutions: [],
            baseHp: 95,
            baseAtk: 110,
            baseDef: 80,
            baseSpeed: 55,
            moves: [72, 93, 99, 140, 168, 173, 205, 237, 246, 331, 496, 611]
        }, 104: {
            id: 104,
            name: "cubone",
            specie: 104,
            is_default: true,
            type1: "ground",
            evolutions: [{to: 105, method: "level_up", minLevel: 28},],
            baseHp: 50,
            baseAtk: 45,
            baseDef: 73,
            baseSpeed: 35,
            moves: [24, 55, 99, 155, 168, 173, 189, 196, 198, 206, 210, 237, 246, 249, 317, 332, 479, 496, 497, 510, 523, 612, 693]
        }, 105: {
            id: 105,
            name: "marowak",
            specie: 105,
            is_default: true,
            type1: "ground",
            evolutions: [],
            baseHp: 60,
            baseAtk: 65,
            baseDef: 95,
            baseSpeed: 45,
            moves: [55, 99, 155, 168, 173, 189, 196, 198, 206, 210, 237, 249, 317, 332, 479, 496, 497, 510, 523, 612, 693]
        }, 106: {
            id: 106,
            name: "hitmonlee",
            specie: 106,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 50,
            baseAtk: 78,
            baseDef: 82,
            baseSpeed: 87,
            moves: [24, 27, 99, 129, 168, 173, 189, 237, 249, 279, 317, 343, 364, 410, 496, 523, 612]
        }, 107: {
            id: 107,
            name: "hitmonchan",
            specie: 107,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 50,
            baseAtk: 70,
            baseDef: 95,
            baseSpeed: 76,
            moves: [4, 99, 129, 168, 173, 183, 189, 228, 237, 249, 279, 317, 343, 364, 410, 418, 496, 523, 612]
        }, 108: {
            id: 108,
            name: "lickitung",
            specie: 108,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 463, method: "level_up", minLevel: 32},],
            baseHp: 90,
            baseAtk: 58,
            baseDef: 75,
            baseSpeed: 30,
            moves: [15, 20, 35, 55, 99, 122, 168, 173, 189, 196, 205, 237, 249, 250, 317, 351, 352, 496, 510, 523, 525, 612, 693]
        }, 109: {
            id: 109,
            name: "koffing",
            specie: 109,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 110, method: "level_up", minLevel: 35},],
            baseHp: 40,
            baseAtk: 63,
            baseDef: 70,
            baseSpeed: 35,
            moves: [33, 99, 123, 168, 173, 205, 237, 351, 371, 372, 496, 499, 510, 611]
        }, 110: {
            id: 110,
            name: "weezing",
            specie: 110,
            is_default: true,
            type1: "poison",
            evolutions: [],
            baseHp: 65,
            baseAtk: 88,
            baseDef: 95,
            baseSpeed: 60,
            moves: [33, 99, 123, 168, 173, 205, 237, 351, 371, 372, 458, 496, 499, 510, 611]
        }, 111: {
            id: 111,
            name: "rhyhorn",
            specie: 111,
            is_default: true,
            type1: "ground",
            type2: "rock",
            evolutions: [{to: 112, method: "level_up", minLevel: 42},],
            baseHp: 80,
            baseAtk: 58,
            baseDef: 63,
            baseSpeed: 25,
            moves: [31, 99, 168, 173, 189, 196, 205, 228, 237, 246, 249, 317, 350, 351, 371, 479, 496, 510, 523]
        }, 112: {
            id: 112,
            name: "rhydon",
            specie: 112,
            is_default: true,
            type1: "ground",
            type2: "rock",
            evolutions: [{to: 464, method: "level_up", minLevel: 32},],
            baseHp: 105,
            baseAtk: 88,
            baseDef: 83,
            baseSpeed: 40,
            moves: [6, 15, 31, 55, 99, 168, 173, 189, 196, 205, 210, 237, 246, 249, 250, 317, 350, 351, 371, 419, 479, 496, 510, 523, 525, 612, 693]
        }, 113: {
            id: 113,
            name: "chansey",
            specie: 113,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 242, method: "level_up", minLevel: 32},],
            baseHp: 250,
            baseAtk: 20,
            baseDef: 55,
            baseSpeed: 50,
            moves: [1, 3, 55, 99, 173, 189, 196, 205, 237, 249, 317, 343, 351, 352, 451, 496, 497, 510, 523, 612]
        }, 114: {
            id: 114,
            name: "tangela",
            specie: 114,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 465, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 78,
            baseDef: 78,
            baseSpeed: 60,
            moves: [15, 20, 22, 71, 72, 93, 99, 132, 168, 173, 237, 246, 249, 331, 351, 496, 611]
        }, 115: {
            id: 115,
            name: "kangaskhan",
            specie: 115,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 105,
            baseAtk: 68,
            baseDef: 80,
            baseSpeed: 90,
            moves: [4, 15, 44, 55, 99, 168, 173, 189, 196, 210, 237, 249, 250, 252, 317, 332, 343, 351, 352, 419, 458, 496, 509, 510, 523, 612]
        }, 116: {
            id: 116,
            name: "horsea",
            specie: 116,
            is_default: true,
            type1: "water",
            evolutions: [{to: 117, method: "level_up", minLevel: 32},],
            baseHp: 30,
            baseAtk: 55,
            baseDef: 48,
            baseSpeed: 60,
            moves: [55, 99, 129, 145, 173, 196, 225, 237, 239, 250, 352, 496, 499]
        }, 117: {
            id: 117,
            name: "seadra",
            specie: 117,
            is_default: true,
            type1: "water",
            evolutions: [{to: 230, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 80,
            baseDef: 70,
            baseSpeed: 85,
            moves: [55, 99, 129, 145, 173, 196, 225, 237, 239, 250, 352, 496]
        }, 118: {
            id: 118,
            name: "goldeen",
            specie: 118,
            is_default: true,
            type1: "water",
            evolutions: [{to: 119, method: "level_up", minLevel: 33},],
            baseHp: 45,
            baseAtk: 51,
            baseDef: 55,
            baseSpeed: 63,
            moves: [31, 55, 64, 99, 129, 173, 189, 196, 210, 237, 250, 341, 352, 496]
        }, 119: {
            id: 119,
            name: "seaking",
            specie: 119,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 80,
            baseAtk: 79,
            baseDef: 73,
            baseSpeed: 68,
            moves: [31, 55, 64, 99, 129, 173, 189, 196, 210, 237, 250, 352, 496]
        }, 120: {
            id: 120,
            name: "staryu",
            specie: 120,
            is_default: true,
            type1: "water",
            evolutions: [{to: 121, method: "level_up", minLevel: 32},],
            baseHp: 30,
            baseAtk: 58,
            baseDef: 55,
            baseSpeed: 85,
            moves: [33, 55, 99, 129, 173, 196, 205, 229, 237, 239, 250, 352, 496]
        }, 121: {
            id: 121,
            name: "starmie",
            specie: 121,
            is_default: true,
            type1: "water",
            type2: "psychic",
            evolutions: [],
            baseHp: 60,
            baseAtk: 88,
            baseDef: 85,
            baseSpeed: 115,
            moves: [33, 55, 99, 129, 173, 196, 205, 229, 237, 239, 250, 352, 419, 496]
        }, 122: {
            id: 122,
            name: "mr-mime",
            specie: 122,
            is_default: true,
            type1: "psychic",
            type2: "fairy",
            evolutions: [],
            baseHp: 40,
            baseAtk: 73,
            baseDef: 93,
            baseSpeed: 90,
            moves: [1, 3, 93, 99, 168, 173, 189, 196, 237, 252, 332, 343, 345, 351, 371, 451, 496, 611, 612]
        }, 123: {
            id: 123,
            name: "scyther",
            specie: 123,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [{to: 212, method: "level_up", minLevel: 32},],
            baseHp: 70,
            baseAtk: 83,
            baseDef: 80,
            baseSpeed: 105,
            moves: [15, 17, 98, 99, 129, 168, 173, 206, 210, 228, 237, 249, 318, 332, 364, 410, 450, 458, 466, 496, 522, 693]
        }, 124: {
            id: 124,
            name: "jynx",
            specie: 124,
            is_default: true,
            type1: "ice",
            type2: "psychic",
            evolutions: [],
            baseHp: 65,
            baseAtk: 83,
            baseDef: 65,
            baseSpeed: 95,
            moves: [1, 3, 55, 99, 122, 168, 173, 181, 189, 196, 237, 343, 352, 371, 419, 496, 497, 524, 531, 577, 612]
        }, 125: {
            id: 125,
            name: "electabuzz",
            specie: 125,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 466, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 89,
            baseDef: 71,
            baseSpeed: 105,
            moves: [84, 98, 99, 129, 168, 173, 189, 237, 249, 343, 351, 451, 496, 527, 530, 612]
        }, 126: {
            id: 126,
            name: "magmar",
            specie: 126,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 467, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 98,
            baseDef: 71,
            baseSpeed: 93,
            moves: [52, 83, 99, 123, 168, 173, 185, 189, 237, 249, 343, 488, 496, 499, 510, 530, 612]
        }, 127: {
            id: 127,
            name: "pinsir",
            specie: 127,
            is_default: true,
            type1: "bug",
            evolutions: [],
            baseHp: 65,
            baseAtk: 90,
            baseDef: 85,
            baseSpeed: 85,
            moves: [11, 15, 20, 31, 98, 99, 168, 173, 185, 206, 210, 237, 249, 279, 317, 364, 450, 458, 479, 480, 496, 522, 523, 693]
        }, 128: {
            id: 128,
            name: "tauros",
            specie: 128,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 75,
            baseAtk: 70,
            baseDef: 83,
            baseSpeed: 110,
            moves: [33, 99, 173, 196, 228, 237, 249, 250, 317, 351, 352, 371, 496, 510, 523]
        }, 129: {
            id: 129,
            name: "magikarp",
            specie: 129,
            is_default: true,
            type1: "water",
            evolutions: [{to: 130, method: "level_up", minLevel: 20},],
            baseHp: 20,
            baseAtk: 13,
            baseDef: 38,
            baseSpeed: 80,
            moves: [33]
        }, 130: {
            id: 130,
            name: "gyarados",
            specie: 130,
            is_default: true,
            type1: "water",
            type2: "flying",
            evolutions: [],
            baseHp: 95,
            baseAtk: 93,
            baseDef: 90,
            baseSpeed: 81,
            moves: [33, 44, 55, 99, 173, 196, 225, 237, 239, 249, 250, 352, 371, 419, 496, 510, 523, 525, 693]
        }, 131: {
            id: 131,
            name: "lapras",
            specie: 131,
            is_default: true,
            type1: "water",
            type2: "ice",
            evolutions: [],
            baseHp: 130,
            baseAtk: 85,
            baseDef: 88,
            baseSpeed: 60,
            moves: [55, 99, 173, 196, 225, 237, 246, 249, 250, 351, 352, 419, 420, 496, 497, 523, 524]
        }, 132: {
            id: 132, name: "ditto", specie: 132, is_default: true, type1: "normal", evolutions: [],
            baseHp: 48, baseAtk: 48, baseDef: 48, baseSpeed: 48, moves: [33]
        }, 133: {
            id: 133,
            name: "eevee",
            specie: 133,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 134, method: "level_up", minLevel: 32}, {
                to: 135,
                method: "level_up",
                minLevel: 32
            }, {to: 136, method: "level_up", minLevel: 32}, {to: 196, method: "level_up", minLevel: 32}, {
                to: 197,
                method: "level_up",
                minLevel: 32
            }, {to: 470, method: "level_up", minLevel: 32}, {to: 471, method: "level_up", minLevel: 32}, {
                to: 700,
                method: "level_up",
                minLevel: 32
            },],
            baseHp: 55,
            baseAtk: 50,
            baseDef: 58,
            baseSpeed: 55,
            moves: [33, 44, 98, 99, 129, 173, 189, 237, 343, 496, 497, 500]
        }, 134: {
            id: 134,
            name: "vaporeon",
            specie: 134,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 130,
            baseAtk: 88,
            baseDef: 78,
            baseSpeed: 65,
            moves: [33, 44, 55, 98, 99, 129, 173, 189, 196, 237, 249, 250, 343, 352, 496, 497]
        }, 135: {
            id: 135,
            name: "jolteon",
            specie: 135,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 65,
            baseAtk: 88,
            baseDef: 78,
            baseSpeed: 130,
            moves: [24, 33, 42, 84, 98, 99, 129, 173, 189, 237, 249, 343, 351, 451, 496, 497]
        }, 136: {
            id: 136,
            name: "flareon",
            specie: 136,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 65,
            baseAtk: 113,
            baseDef: 85,
            baseSpeed: 65,
            moves: [33, 44, 52, 83, 98, 99, 123, 129, 173, 189, 237, 249, 343, 488, 496, 497, 510]
        }, 137: {
            id: 137,
            name: "porygon",
            specie: 137,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 233, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 73,
            baseDef: 73,
            baseSpeed: 40,
            moves: [33, 99, 129, 168, 173, 196, 237, 332, 351, 451, 496, 527]
        }, 138: {
            id: 138,
            name: "omanyte",
            specie: 138,
            is_default: true,
            type1: "rock",
            type2: "water",
            evolutions: [{to: 139, method: "level_up", minLevel: 40},],
            baseHp: 35,
            baseAtk: 65,
            baseDef: 78,
            baseSpeed: 35,
            moves: [20, 44, 55, 99, 131, 132, 168, 173, 196, 205, 237, 246, 249, 250, 317, 341, 350, 352, 479, 496]
        }, 139: {
            id: 139,
            name: "omastar",
            specie: 139,
            is_default: true,
            type1: "rock",
            type2: "water",
            evolutions: [],
            baseHp: 70,
            baseAtk: 88,
            baseDef: 98,
            baseSpeed: 55,
            moves: [20, 44, 55, 99, 131, 132, 168, 173, 196, 205, 237, 246, 249, 250, 317, 341, 350, 352, 479, 496]
        }, 140: {
            id: 140,
            name: "kabuto",
            specie: 140,
            is_default: true,
            type1: "rock",
            type2: "water",
            evolutions: [{to: 141, method: "level_up", minLevel: 40},],
            baseHp: 30,
            baseAtk: 68,
            baseDef: 68,
            baseSpeed: 55,
            moves: [10, 55, 71, 72, 99, 168, 173, 189, 196, 205, 229, 237, 246, 249, 250, 317, 332, 341, 352, 453, 479, 496]
        }, 141: {
            id: 141,
            name: "kabutops",
            specie: 141,
            is_default: true,
            type1: "rock",
            type2: "water",
            evolutions: [],
            baseHp: 60,
            baseAtk: 90,
            baseDef: 88,
            baseSpeed: 80,
            moves: [10, 15, 55, 71, 72, 99, 168, 173, 189, 196, 205, 210, 237, 246, 249, 250, 317, 332, 341, 352, 364, 453, 479, 496]
        }, 142: {
            id: 142,
            name: "aerodactyl",
            specie: 142,
            is_default: true,
            type1: "rock",
            type2: "flying",
            evolutions: [],
            baseHp: 80,
            baseAtk: 83,
            baseDef: 70,
            baseSpeed: 130,
            moves: [17, 44, 99, 129, 168, 173, 225, 228, 237, 239, 246, 249, 314, 317, 332, 371, 372, 466, 479, 496, 507, 510, 523, 693]
        }, 143: {
            id: 143,
            name: "snorlax",
            specie: 143,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 160,
            baseAtk: 88,
            baseDef: 88,
            baseSpeed: 30,
            moves: [6, 33, 55, 99, 122, 173, 189, 196, 205, 228, 237, 249, 250, 317, 343, 351, 352, 479, 496, 510, 523, 612]
        }, 144: {
            id: 144,
            name: "articuno",
            specie: 144,
            is_default: true,
            type1: "ice",
            type2: "flying",
            evolutions: [],
            baseHp: 90,
            baseAtk: 90,
            baseDef: 113,
            baseSpeed: 85,
            moves: [16, 55, 64, 99, 129, 173, 181, 189, 196, 237, 239, 246, 249, 314, 332, 352, 365, 419, 420, 466, 496, 507, 524]
        }, 145: {
            id: 145,
            name: "zapdos",
            specie: 145,
            is_default: true,
            type1: "electric",
            type2: "flying",
            evolutions: [],
            baseHp: 90,
            baseAtk: 108,
            baseDef: 88,
            baseSpeed: 100,
            moves: [64, 84, 99, 129, 173, 189, 237, 239, 246, 249, 314, 332, 351, 365, 451, 466, 496, 507]
        }, 146: {
            id: 146,
            name: "moltres",
            specie: 146,
            is_default: true,
            type1: "fire",
            type2: "flying",
            evolutions: [],
            baseHp: 90,
            baseAtk: 113,
            baseDef: 88,
            baseSpeed: 90,
            moves: [17, 52, 64, 83, 99, 129, 173, 189, 237, 239, 246, 249, 314, 332, 365, 466, 488, 496, 507, 510]
        }, 147: {
            id: 147,
            name: "dratini",
            specie: 147,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 148, method: "level_up", minLevel: 30},],
            baseHp: 41,
            baseAtk: 57,
            baseDef: 48,
            baseSpeed: 50,
            moves: [20, 35, 55, 99, 129, 173, 196, 225, 237, 239, 250, 351, 352, 453, 496, 510, 525, 693]
        }, 148: {
            id: 148,
            name: "dragonair",
            specie: 148,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 149, method: "level_up", minLevel: 55},],
            baseHp: 61,
            baseAtk: 77,
            baseDef: 68,
            baseSpeed: 70,
            moves: [20, 35, 55, 99, 129, 173, 196, 225, 237, 239, 250, 351, 352, 496, 510, 525, 693]
        }, 149: {
            id: 149,
            name: "dragonite",
            specie: 149,
            is_default: true,
            type1: "dragon",
            type2: "flying",
            evolutions: [],
            baseHp: 91,
            baseAtk: 117,
            baseDef: 98,
            baseSpeed: 80,
            moves: [15, 17, 20, 35, 55, 99, 129, 173, 189, 196, 210, 225, 237, 239, 249, 250, 314, 317, 332, 351, 352, 466, 496, 507, 510, 523, 525, 612, 693]
        }, 150: {
            id: 150,
            name: "mewtwo",
            specie: 150,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 106,
            baseAtk: 132,
            baseDef: 90,
            baseSpeed: 130,
            moves: [6, 55, 93, 99, 129, 173, 189, 196, 237, 249, 317, 332, 351, 352, 419, 451, 496, 510, 523, 612, 693]
        }, 151: {
            id: 151,
            name: "mew",
            specie: 151,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 100,
            baseAtk: 100,
            baseDef: 100,
            baseSpeed: 100,
            moves: [1, 6, 15, 20, 55, 72, 99, 129, 168, 173, 185, 189, 196, 205, 206, 210, 225, 237, 239, 246, 249, 250, 252, 314, 317, 318, 331, 332, 343, 351, 352, 365, 371, 410, 419, 450, 451, 466, 479, 488, 496, 497, 507, 510, 512, 522, 523, 524, 525, 527, 530, 555, 611, 612, 693]
        }, 152: {
            id: 152,
            name: "chikorita",
            specie: 152,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 153, method: "level_up", minLevel: 16},],
            baseHp: 45,
            baseAtk: 49,
            baseDef: 65,
            baseSpeed: 45,
            moves: [15, 22, 33, 75, 173, 189, 210, 237, 246, 331, 345, 496, 497]
        }, 153: {
            id: 153,
            name: "bayleef",
            specie: 153,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 154, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 63,
            baseDef: 80,
            baseSpeed: 60,
            moves: [15, 33, 75, 173, 189, 210, 237, 246, 249, 331, 345, 496, 497]
        }, 154: {
            id: 154,
            name: "meganium",
            specie: 154,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 80,
            baseAtk: 83,
            baseDef: 100,
            baseSpeed: 80,
            moves: [15, 33, 75, 173, 189, 210, 237, 246, 249, 331, 345, 496, 497, 523, 525]
        }, 155: {
            id: 155,
            name: "cyndaquil",
            specie: 155,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 156, method: "level_up", minLevel: 14},],
            baseHp: 39,
            baseAtk: 56,
            baseDef: 47,
            baseSpeed: 65,
            moves: [15, 24, 33, 52, 98, 129, 154, 172, 173, 189, 205, 237, 332, 343, 488, 496, 510]
        }, 156: {
            id: 156,
            name: "quilava",
            specie: 156,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 157, method: "level_up", minLevel: 36},],
            baseHp: 58,
            baseAtk: 72,
            baseDef: 62,
            baseSpeed: 80,
            moves: [15, 33, 52, 98, 129, 172, 173, 189, 205, 210, 237, 249, 332, 343, 488, 496, 510]
        }, 157: {
            id: 157,
            name: "typhlosion",
            specie: 157,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 78,
            baseAtk: 97,
            baseDef: 82,
            baseSpeed: 100,
            moves: [15, 33, 52, 98, 129, 172, 173, 189, 205, 210, 237, 249, 317, 332, 343, 488, 496, 510, 523, 612]
        }, 158: {
            id: 158,
            name: "totodile",
            specie: 158,
            is_default: true,
            type1: "water",
            evolutions: [{to: 159, method: "level_up", minLevel: 18},],
            baseHp: 50,
            baseAtk: 55,
            baseDef: 56,
            baseSpeed: 43,
            moves: [10, 15, 44, 55, 99, 173, 189, 196, 232, 237, 246, 250, 317, 332, 352, 453, 496, 612]
        }, 159: {
            id: 159,
            name: "croconaw",
            specie: 159,
            is_default: true,
            type1: "water",
            evolutions: [{to: 160, method: "level_up", minLevel: 30},],
            baseHp: 65,
            baseAtk: 70,
            baseDef: 72,
            baseSpeed: 58,
            moves: [10, 15, 44, 55, 99, 173, 189, 196, 210, 237, 246, 249, 250, 317, 332, 352, 496, 612]
        }, 160: {
            id: 160,
            name: "feraligatr",
            specie: 160,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 85,
            baseAtk: 92,
            baseDef: 92,
            baseSpeed: 78,
            moves: [10, 15, 44, 55, 99, 173, 189, 196, 210, 237, 246, 249, 250, 317, 332, 352, 419, 496, 523, 525, 612]
        }, 161: {
            id: 161,
            name: "sentret",
            specie: 161,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 162, method: "level_up", minLevel: 15},],
            baseHp: 35,
            baseAtk: 41,
            baseDef: 40,
            baseSpeed: 20,
            moves: [10, 15, 33, 98, 129, 154, 168, 173, 189, 205, 210, 228, 237, 250, 343, 351, 352, 451, 496, 497, 612, 693]
        }, 162: {
            id: 162,
            name: "furret",
            specie: 162,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 85,
            baseAtk: 61,
            baseDef: 60,
            baseSpeed: 90,
            moves: [10, 15, 98, 129, 154, 168, 173, 189, 205, 210, 237, 249, 250, 343, 351, 352, 451, 496, 497, 612, 693]
        }, 163: {
            id: 163,
            name: "hoothoot",
            specie: 163,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 164, method: "level_up", minLevel: 20},],
            baseHp: 60,
            baseAtk: 33,
            baseDef: 43,
            baseSpeed: 50,
            moves: [17, 33, 64, 93, 129, 168, 173, 185, 189, 237, 239, 314, 318, 332, 365, 466, 496, 497]
        }, 164: {
            id: 164,
            name: "noctowl",
            specie: 164,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 100,
            baseAtk: 68,
            baseDef: 73,
            baseSpeed: 70,
            moves: [33, 64, 93, 129, 168, 173, 189, 237, 239, 314, 318, 332, 365, 466, 496, 497]
        }, 165: {
            id: 165,
            name: "ledyba",
            specie: 165,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [{to: 166, method: "level_up", minLevel: 18},],
            baseHp: 40,
            baseAtk: 30,
            baseDef: 55,
            baseSpeed: 55,
            moves: [4, 33, 129, 168, 173, 183, 205, 237, 314, 318, 332, 450, 466, 496, 512, 522, 611, 612]
        }, 166: {
            id: 166,
            name: "ledian",
            specie: 166,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 55,
            baseAtk: 45,
            baseDef: 80,
            baseSpeed: 85,
            moves: [4, 33, 129, 168, 173, 183, 205, 237, 249, 314, 318, 332, 450, 466, 496, 512, 522, 611, 612]
        }, 167: {
            id: 167,
            name: "spinarak",
            specie: 167,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [{to: 168, method: "level_up", minLevel: 22},],
            baseHp: 40,
            baseAtk: 50,
            baseDef: 40,
            baseSpeed: 30,
            moves: [40, 41, 42, 71, 132, 154, 168, 173, 228, 237, 425, 450, 496, 522, 527, 611]
        }, 168: {
            id: 168,
            name: "ariados",
            specie: 168,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [],
            baseHp: 70,
            baseAtk: 75,
            baseDef: 70,
            baseSpeed: 40,
            moves: [40, 42, 71, 132, 154, 168, 173, 237, 425, 450, 496, 522, 527, 565, 611]
        }, 169: {
            id: 169,
            name: "crobat",
            specie: 169,
            is_default: true,
            type1: "poison",
            type2: "flying",
            evolutions: [],
            baseHp: 85,
            baseAtk: 80,
            baseDef: 80,
            baseSpeed: 130,
            moves: [17, 44, 71, 129, 168, 173, 237, 239, 305, 310, 314, 332, 365, 371, 466, 496, 512]
        }, 170: {
            id: 170,
            name: "chinchou",
            specie: 170,
            is_default: true,
            type1: "water",
            type2: "electric",
            evolutions: [{to: 171, method: "level_up", minLevel: 27},],
            baseHp: 75,
            baseAtk: 47,
            baseDef: 47,
            baseSpeed: 67,
            moves: [55, 145, 173, 196, 237, 250, 351, 352, 451, 496]
        }, 171: {
            id: 171,
            name: "lanturn",
            specie: 171,
            is_default: true,
            type1: "water",
            type2: "electric",
            evolutions: [],
            baseHp: 125,
            baseAtk: 67,
            baseDef: 67,
            baseSpeed: 67,
            moves: [55, 145, 173, 196, 237, 250, 351, 352, 451, 496]
        }, 172: {
            id: 172,
            name: "pichu",
            specie: 172,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 25, method: "level_up", minLevel: 32},],
            baseHp: 20,
            baseAtk: 38,
            baseDef: 25,
            baseSpeed: 60,
            moves: [3, 84, 129, 173, 189, 205, 237, 252, 343, 351, 451, 496, 497, 527, 574]
        }, 173: {
            id: 173,
            name: "cleffa",
            specie: 173,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 35, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 35,
            baseDef: 42,
            baseSpeed: 15,
            moves: [1, 173, 189, 196, 205, 237, 343, 345, 351, 352, 496, 497, 500, 510]
        }, 174: {
            id: 174,
            name: "igglybuff",
            specie: 174,
            is_default: true,
            type1: "normal",
            type2: "fairy",
            evolutions: [{to: 39, method: "level_up", minLevel: 32},],
            baseHp: 90,
            baseAtk: 35,
            baseDef: 18,
            baseSpeed: 15,
            moves: [1, 173, 185, 189, 196, 205, 237, 343, 351, 352, 496, 497, 510]
        }, 175: {
            id: 175,
            name: "togepi",
            specie: 175,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 176, method: "level_up", minLevel: 32},],
            baseHp: 35,
            baseAtk: 30,
            baseDef: 65,
            baseSpeed: 20,
            moves: [64, 129, 173, 189, 205, 237, 246, 249, 343, 351, 352, 496, 497, 500, 510]
        }, 176: {
            id: 176,
            name: "togetic",
            specie: 176,
            is_default: true,
            type1: "fairy",
            type2: "flying",
            evolutions: [{to: 468, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 60,
            baseDef: 95,
            baseSpeed: 40,
            moves: [129, 173, 189, 205, 237, 239, 246, 249, 314, 318, 332, 343, 345, 351, 352, 466, 496, 497, 510, 584]
        }, 177: {
            id: 177,
            name: "natu",
            specie: 177,
            is_default: true,
            type1: "psychic",
            type2: "flying",
            evolutions: [{to: 178, method: "level_up", minLevel: 25},],
            baseHp: 40,
            baseAtk: 60,
            baseDef: 45,
            baseSpeed: 70,
            moves: [64, 98, 129, 168, 173, 185, 237, 239, 314, 318, 332, 365, 466, 496, 500]
        }, 178: {
            id: 178,
            name: "xatu",
            specie: 178,
            is_default: true,
            type1: "psychic",
            type2: "flying",
            evolutions: [],
            baseHp: 65,
            baseAtk: 85,
            baseDef: 70,
            baseSpeed: 95,
            moves: [64, 129, 168, 173, 237, 239, 314, 318, 332, 365, 466, 496, 500]
        }, 179: {
            id: 179,
            name: "mareep",
            specie: 179,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 180, method: "level_up", minLevel: 15},],
            baseHp: 55,
            baseAtk: 53,
            baseDef: 43,
            baseSpeed: 35,
            moves: [33, 84, 129, 173, 237, 351, 451, 496, 497, 527]
        }, 180: {
            id: 180,
            name: "flaaffy",
            specie: 180,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 181, method: "level_up", minLevel: 30},],
            baseHp: 70,
            baseAtk: 68,
            baseDef: 58,
            baseSpeed: 45,
            moves: [33, 84, 129, 173, 237, 249, 351, 451, 496, 497, 527, 612]
        }, 181: {
            id: 181,
            name: "ampharos",
            specie: 181,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 90,
            baseAtk: 95,
            baseDef: 88,
            baseSpeed: 55,
            moves: [33, 84, 129, 173, 237, 249, 351, 451, 496, 497, 523, 527, 612, 693]
        }, 182: {
            id: 182, name: "bellossom", specie: 182, is_default: true, type1: "grass", evolutions: [],
            baseHp: 75, baseAtk: 85, baseDef: 98, baseSpeed: 50, moves: [15, 71, 72, 173, 237, 331, 345, 496, 611]
        }, 183: {
            id: 183,
            name: "marill",
            specie: 183,
            is_default: true,
            type1: "water",
            type2: "fairy",
            evolutions: [{to: 184, method: "level_up", minLevel: 18},],
            baseHp: 70,
            baseAtk: 20,
            baseDef: 50,
            baseSpeed: 40,
            moves: [33, 55, 129, 145, 173, 189, 196, 205, 237, 249, 250, 343, 352, 453, 496, 612]
        }, 184: {
            id: 184,
            name: "azumarill",
            specie: 184,
            is_default: true,
            type1: "water",
            type2: "fairy",
            evolutions: [],
            baseHp: 100,
            baseAtk: 55,
            baseDef: 80,
            baseSpeed: 50,
            moves: [33, 55, 129, 145, 173, 189, 196, 205, 237, 249, 250, 343, 352, 496, 523, 612]
        }, 185: {
            id: 185,
            name: "sudowoodo",
            specie: 185,
            is_default: true,
            type1: "rock",
            evolutions: [],
            baseHp: 70,
            baseAtk: 65,
            baseDef: 90,
            baseSpeed: 30,
            moves: [88, 168, 173, 185, 189, 205, 237, 249, 317, 328, 343, 479, 496, 523, 612]
        }, 186: {
            id: 186,
            name: "politoed",
            specie: 186,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 90,
            baseAtk: 83,
            baseDef: 88,
            baseSpeed: 70,
            moves: [3, 55, 168, 173, 189, 196, 237, 249, 250, 352, 371, 496, 497, 523, 612]
        }, 187: {
            id: 187,
            name: "hoppip",
            specie: 187,
            is_default: true,
            type1: "grass",
            type2: "flying",
            evolutions: [{to: 188, method: "level_up", minLevel: 18},],
            baseHp: 35,
            baseAtk: 35,
            baseDef: 48,
            baseSpeed: 50,
            moves: [6, 33, 71, 72, 93, 173, 237, 318, 331, 332, 496, 512, 584, 611]
        }, 188: {
            id: 188,
            name: "skiploom",
            specie: 188,
            is_default: true,
            type1: "grass",
            type2: "flying",
            evolutions: [{to: 189, method: "level_up", minLevel: 27},],
            baseHp: 55,
            baseAtk: 45,
            baseDef: 58,
            baseSpeed: 80,
            moves: [33, 71, 72, 173, 237, 318, 331, 332, 496, 512, 584, 611]
        }, 189: {
            id: 189,
            name: "jumpluff",
            specie: 189,
            is_default: true,
            type1: "grass",
            type2: "flying",
            evolutions: [],
            baseHp: 75,
            baseAtk: 55,
            baseDef: 83,
            baseSpeed: 110,
            moves: [33, 71, 72, 173, 237, 318, 331, 332, 496, 512, 584, 611]
        }, 190: {
            id: 190,
            name: "aipom",
            specie: 190,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 424, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 55,
            baseDef: 55,
            baseSpeed: 85,
            moves: [3, 10, 15, 129, 154, 168, 173, 189, 210, 228, 237, 249, 252, 279, 310, 332, 343, 351, 352, 371, 458, 496, 512, 541, 612]
        }, 191: {
            id: 191,
            name: "sunkern",
            specie: 191,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 192, method: "level_up", minLevel: 32},],
            baseHp: 30,
            baseAtk: 30,
            baseDef: 30,
            baseSpeed: 30,
            moves: [15, 71, 72, 75, 173, 237, 331, 496]
        }, 192: {
            id: 192, name: "sunflora", specie: 192, is_default: true, type1: "grass", evolutions: [],
            baseHp: 75, baseAtk: 90, baseDef: 70, baseSpeed: 30, moves: [1, 15, 71, 72, 75, 173, 237, 331, 496]
        }, 193: {
            id: 193,
            name: "yanma",
            specie: 193,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [{to: 469, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 70,
            baseDef: 45,
            baseSpeed: 95,
            moves: [17, 33, 98, 129, 168, 173, 185, 228, 237, 246, 314, 318, 332, 364, 450, 466, 496]
        }, 194: {
            id: 194,
            name: "wooper",
            specie: 194,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [{to: 195, method: "level_up", minLevel: 20},],
            baseHp: 55,
            baseAtk: 35,
            baseDef: 35,
            baseSpeed: 15,
            moves: [24, 55, 173, 189, 196, 205, 237, 246, 249, 250, 341, 352, 491, 496, 523, 611, 612]
        }, 195: {
            id: 195,
            name: "quagsire",
            specie: 195,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [],
            baseHp: 95,
            baseAtk: 75,
            baseDef: 75,
            baseSpeed: 35,
            moves: [55, 168, 173, 189, 196, 205, 237, 246, 249, 250, 317, 341, 352, 496, 523, 611, 612]
        }, 196: {
            id: 196,
            name: "espeon",
            specie: 196,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 65,
            baseAtk: 98,
            baseDef: 78,
            baseSpeed: 110,
            moves: [15, 33, 93, 98, 129, 173, 189, 237, 343, 496, 497]
        }, 197: {
            id: 197,
            name: "umbreon",
            specie: 197,
            is_default: true,
            type1: "dark",
            evolutions: [],
            baseHp: 95,
            baseAtk: 63,
            baseDef: 120,
            baseSpeed: 65,
            moves: [15, 33, 98, 129, 173, 185, 189, 228, 237, 343, 371, 372, 496, 497, 555]
        }, 198: {
            id: 198,
            name: "murkrow",
            specie: 198,
            is_default: true,
            type1: "dark",
            type2: "flying",
            evolutions: [{to: 430, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 85,
            baseDef: 42,
            baseSpeed: 91,
            moves: [17, 64, 98, 129, 168, 173, 185, 189, 196, 228, 237, 239, 310, 314, 332, 365, 371, 372, 466, 496, 555]
        }, 199: {
            id: 199,
            name: "slowking",
            specie: 199,
            is_default: true,
            type1: "water",
            type2: "psychic",
            evolutions: [],
            baseHp: 95,
            baseAtk: 88,
            baseDef: 95,
            baseSpeed: 30,
            moves: [33, 55, 93, 129, 173, 189, 196, 210, 237, 249, 250, 352, 419, 496, 497, 510, 523, 525, 612]
        }, 200: {
            id: 200,
            name: "misdreavus",
            specie: 200,
            is_default: true,
            type1: "ghost",
            evolutions: [{to: 429, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 73,
            baseDef: 73,
            baseSpeed: 85,
            moves: [129, 168, 173, 196, 237, 310, 332, 351, 371, 425, 451, 466, 496, 497]
        }, 201: {
            id: 201, name: "unown", specie: 201, is_default: true, type1: "psychic", evolutions: [],
            baseHp: 48, baseAtk: 72, baseDef: 48, baseSpeed: 48, moves: [237]
        }, 202: {
            id: 202, name: "wobbuffet", specie: 202, is_default: true, type1: "psychic", evolutions: [],
            baseHp: 190, baseAtk: 33, baseDef: 58, baseSpeed: 33, moves: [500]
        }, 203: {
            id: 203,
            name: "girafarig",
            specie: 203,
            is_default: true,
            type1: "normal",
            type2: "psychic",
            evolutions: [],
            baseHp: 70,
            baseAtk: 85,
            baseDef: 65,
            baseSpeed: 85,
            moves: [24, 33, 93, 129, 168, 173, 189, 237, 249, 310, 351, 372, 451, 458, 496, 497, 523]
        }, 204: {
            id: 204,
            name: "pineco",
            specie: 204,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 205, method: "level_up", minLevel: 31},],
            baseHp: 50,
            baseAtk: 50,
            baseDef: 63,
            baseSpeed: 15,
            moves: [33, 42, 129, 173, 205, 229, 237, 249, 279, 317, 328, 371, 450, 496, 522, 523]
        }, 205: {
            id: 205,
            name: "forretress",
            specie: 205,
            is_default: true,
            type1: "bug",
            type2: "steel",
            evolutions: [],
            baseHp: 75,
            baseAtk: 75,
            baseDef: 100,
            baseSpeed: 40,
            moves: [33, 173, 205, 229, 237, 249, 317, 371, 450, 496, 522, 523]
        }, 206: {
            id: 206,
            name: "dunsparce",
            specie: 206,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 100,
            baseAtk: 68,
            baseDef: 68,
            baseSpeed: 45,
            moves: [20, 44, 99, 168, 173, 189, 205, 228, 237, 246, 249, 310, 317, 351, 352, 451, 496, 510, 523]
        }, 207: {
            id: 207,
            name: "gligar",
            specie: 207,
            is_default: true,
            type1: "ground",
            type2: "flying",
            evolutions: [{to: 472, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 55,
            baseDef: 85,
            baseSpeed: 85,
            moves: [15, 17, 40, 98, 129, 168, 173, 185, 206, 210, 232, 237, 249, 317, 328, 332, 342, 364, 371, 450, 496, 512, 522, 523]
        }, 208: {
            id: 208,
            name: "steelix",
            specie: 208,
            is_default: true,
            type1: "steel",
            type2: "ground",
            evolutions: [],
            baseHp: 75,
            baseAtk: 70,
            baseDef: 133,
            baseSpeed: 30,
            moves: [15, 20, 33, 88, 99, 173, 189, 205, 225, 237, 239, 246, 249, 317, 371, 479, 496, 523, 525, 693]
        }, 209: {
            id: 209,
            name: "snubbull",
            specie: 209,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 210, method: "level_up", minLevel: 23},],
            baseHp: 60,
            baseAtk: 60,
            baseDef: 45,
            baseSpeed: 30,
            moves: [33, 44, 99, 122, 168, 173, 185, 189, 237, 249, 343, 351, 352, 371, 496, 510, 523, 555, 612]
        }, 210: {
            id: 210,
            name: "granbull",
            specie: 210,
            is_default: true,
            type1: "fairy",
            evolutions: [],
            baseHp: 90,
            baseAtk: 90,
            baseDef: 68,
            baseSpeed: 45,
            moves: [33, 44, 99, 122, 168, 173, 189, 237, 249, 317, 343, 351, 352, 371, 496, 510, 523, 555, 612]
        }, 211: {
            id: 211,
            name: "qwilfish",
            specie: 211,
            is_default: true,
            type1: "water",
            type2: "poison",
            evolutions: [],
            baseHp: 65,
            baseAtk: 75,
            baseDef: 70,
            baseSpeed: 85,
            moves: [33, 40, 42, 55, 129, 145, 173, 196, 205, 237, 250, 279, 310, 351, 352, 371, 453, 491, 496, 565]
        }, 212: {
            id: 212,
            name: "scizor",
            specie: 212,
            is_default: true,
            type1: "bug",
            type2: "steel",
            evolutions: [],
            baseHp: 70,
            baseAtk: 93,
            baseDef: 90,
            baseSpeed: 65,
            moves: [15, 98, 129, 168, 173, 206, 210, 228, 232, 237, 249, 318, 332, 364, 418, 450, 458, 466, 496, 512, 522, 693]
        }, 213: {
            id: 213,
            name: "shuckle",
            specie: 213,
            is_default: true,
            type1: "bug",
            type2: "rock",
            evolutions: [],
            baseHp: 20,
            baseAtk: 10,
            baseDef: 230,
            baseSpeed: 5,
            moves: [20, 35, 51, 88, 132, 173, 189, 205, 237, 246, 249, 317, 328, 350, 450, 479, 496, 522, 523, 611]
        }, 214: {
            id: 214,
            name: "heracross",
            specie: 214,
            is_default: true,
            type1: "bug",
            type2: "fighting",
            evolutions: [],
            baseHp: 80,
            baseAtk: 83,
            baseDef: 85,
            baseSpeed: 85,
            moves: [15, 31, 33, 42, 168, 173, 206, 210, 228, 237, 249, 279, 292, 317, 331, 332, 350, 364, 410, 450, 479, 496, 522, 523, 693]
        }, 215: {
            id: 215,
            name: "sneasel",
            specie: 215,
            is_default: true,
            type1: "dark",
            type2: "ice",
            evolutions: [{to: 461, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 65,
            baseDef: 65,
            baseSpeed: 115,
            moves: [10, 15, 44, 98, 129, 154, 168, 173, 185, 189, 196, 206, 210, 228, 232, 237, 249, 250, 252, 332, 364, 371, 419, 420, 458, 496, 555, 612]
        }, 216: {
            id: 216,
            name: "teddiursa",
            specie: 216,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 217, method: "level_up", minLevel: 30},],
            baseHp: 60,
            baseAtk: 65,
            baseDef: 50,
            baseSpeed: 40,
            moves: [10, 15, 122, 129, 154, 168, 173, 185, 189, 205, 210, 232, 237, 249, 317, 332, 343, 371, 496, 523, 612]
        }, 217: {
            id: 217,
            name: "ursaring",
            specie: 217,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 90,
            baseAtk: 103,
            baseDef: 75,
            baseSpeed: 55,
            moves: [10, 15, 122, 129, 154, 168, 173, 185, 189, 205, 210, 237, 249, 317, 332, 343, 371, 419, 479, 496, 523, 612]
        }, 218: {
            id: 218,
            name: "slugma",
            specie: 218,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 219, method: "level_up", minLevel: 38},],
            baseHp: 40,
            baseAtk: 55,
            baseDef: 40,
            baseSpeed: 20,
            moves: [52, 88, 123, 173, 189, 205, 237, 246, 249, 317, 488, 496, 499, 510, 611]
        }, 219: {
            id: 219,
            name: "magcargo",
            specie: 219,
            is_default: true,
            type1: "fire",
            type2: "rock",
            evolutions: [],
            baseHp: 60,
            baseAtk: 70,
            baseDef: 100,
            baseSpeed: 30,
            moves: [52, 88, 123, 173, 189, 205, 237, 246, 249, 317, 479, 488, 496, 499, 510, 523, 611]
        }, 220: {
            id: 220,
            name: "swinub",
            specie: 220,
            is_default: true,
            type1: "ice",
            type2: "ground",
            evolutions: [{to: 221, method: "level_up", minLevel: 33},],
            baseHp: 50,
            baseAtk: 40,
            baseDef: 35,
            baseSpeed: 50,
            moves: [33, 44, 173, 181, 189, 196, 237, 246, 249, 317, 333, 341, 419, 420, 496, 523]
        }, 221: {
            id: 221,
            name: "piloswine",
            specie: 221,
            is_default: true,
            type1: "ice",
            type2: "ground",
            evolutions: [{to: 473, method: "level_up", minLevel: 32},],
            baseHp: 100,
            baseAtk: 80,
            baseDef: 70,
            baseSpeed: 50,
            moves: [31, 64, 173, 181, 189, 196, 237, 246, 249, 317, 419, 496, 523]
        }, 222: {
            id: 222,
            name: "corsola",
            specie: 222,
            is_default: true,
            type1: "water",
            type2: "rock",
            evolutions: [],
            baseHp: 65,
            baseAtk: 60,
            baseDef: 95,
            baseSpeed: 35,
            moves: [33, 131, 145, 173, 189, 196, 205, 237, 246, 249, 250, 317, 333, 350, 352, 496, 523]
        }, 223: {
            id: 223,
            name: "remoraid",
            specie: 223,
            is_default: true,
            type1: "water",
            evolutions: [{to: 224, method: "level_up", minLevel: 25},],
            baseHp: 35,
            baseAtk: 65,
            baseDef: 35,
            baseSpeed: 65,
            moves: [55, 129, 168, 173, 189, 196, 237, 250, 331, 341, 350, 352, 451, 479, 491, 496, 510]
        }, 224: {
            id: 224,
            name: "octillery",
            specie: 224,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 75,
            baseAtk: 105,
            baseDef: 75,
            baseSpeed: 45,
            moves: [20, 55, 129, 132, 168, 173, 189, 196, 237, 250, 331, 350, 352, 371, 451, 479, 496, 510]
        }, 225: {
            id: 225,
            name: "delibird",
            specie: 225,
            is_default: true,
            type1: "ice",
            type2: "flying",
            evolutions: [],
            baseHp: 45,
            baseAtk: 60,
            baseDef: 45,
            baseSpeed: 75,
            moves: [98, 129, 168, 173, 189, 196, 205, 229, 237, 252, 301, 332, 352, 365, 419, 420, 496, 524, 612, 693]
        }, 226: {
            id: 226,
            name: "mantine",
            specie: 226,
            is_default: true,
            type1: "water",
            type2: "flying",
            evolutions: [],
            baseHp: 85,
            baseAtk: 60,
            baseDef: 105,
            baseSpeed: 70,
            moves: [17, 33, 129, 145, 173, 189, 196, 237, 239, 250, 314, 317, 331, 332, 352, 496, 512, 523]
        }, 227: {
            id: 227,
            name: "skarmory",
            specie: 227,
            is_default: true,
            type1: "steel",
            type2: "flying",
            evolutions: [],
            baseHp: 65,
            baseAtk: 60,
            baseDef: 105,
            baseSpeed: 70,
            moves: [15, 31, 64, 129, 168, 173, 189, 196, 210, 228, 232, 237, 239, 249, 314, 317, 332, 364, 365, 371, 372, 466, 496, 507]
        }, 228: {
            id: 228,
            name: "houndour",
            specie: 228,
            is_default: true,
            type1: "dark",
            type2: "fire",
            evolutions: [{to: 229, method: "level_up", minLevel: 24},],
            baseHp: 45,
            baseAtk: 70,
            baseDef: 40,
            baseSpeed: 65,
            moves: [44, 52, 83, 99, 123, 129, 168, 173, 185, 189, 228, 237, 249, 364, 371, 488, 496, 510, 555]
        }, 229: {
            id: 229,
            name: "houndoom",
            specie: 229,
            is_default: true,
            type1: "dark",
            type2: "fire",
            evolutions: [],
            baseHp: 75,
            baseAtk: 100,
            baseDef: 65,
            baseSpeed: 95,
            moves: [44, 52, 123, 129, 168, 173, 185, 189, 237, 249, 371, 488, 496, 510, 555]
        }, 230: {
            id: 230,
            name: "kingdra",
            specie: 230,
            is_default: true,
            type1: "water",
            type2: "dragon",
            evolutions: [],
            baseHp: 75,
            baseAtk: 95,
            baseDef: 95,
            baseSpeed: 85,
            moves: [55, 129, 145, 173, 196, 225, 237, 239, 250, 352, 496]
        }, 231: {
            id: 231,
            name: "phanpy",
            specie: 231,
            is_default: true,
            type1: "ground",
            evolutions: [{to: 232, method: "level_up", minLevel: 25},],
            baseHp: 90,
            baseAtk: 50,
            baseDef: 50,
            baseSpeed: 40,
            moves: [33, 55, 173, 189, 205, 237, 246, 249, 317, 420, 496, 497, 523]
        }, 232: {
            id: 232,
            name: "donphan",
            specie: 232,
            is_default: true,
            type1: "ground",
            evolutions: [],
            baseHp: 90,
            baseAtk: 90,
            baseDef: 90,
            baseSpeed: 50,
            moves: [31, 173, 189, 205, 229, 237, 246, 249, 317, 372, 496, 497, 523, 693]
        }, 233: {
            id: 233,
            name: "porygon2",
            specie: 233,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 474, method: "level_up", minLevel: 32},],
            baseHp: 85,
            baseAtk: 93,
            baseDef: 93,
            baseSpeed: 60,
            moves: [33, 129, 168, 173, 196, 237, 332, 351, 451, 496, 527]
        }, 234: {
            id: 234,
            name: "stantler",
            specie: 234,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 73,
            baseAtk: 90,
            baseDef: 64,
            baseSpeed: 85,
            moves: [24, 33, 44, 99, 129, 168, 173, 189, 237, 310, 351, 451, 496, 523]
        }, 235: {
            id: 235, name: "smeargle", specie: 235, is_default: true, type1: "normal", evolutions: [],
            baseHp: 55, baseAtk: 20, baseDef: 40, baseSpeed: 75, moves: [33]
        }, 236: {
            id: 236,
            name: "tyrogue",
            specie: 236,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 106, method: "level_up", minLevel: 20}, {
                to: 107,
                method: "level_up",
                minLevel: 20
            }, {to: 237, method: "level_up", minLevel: 20},],
            baseHp: 35,
            baseAtk: 35,
            baseDef: 35,
            baseSpeed: 35,
            moves: [33, 129, 168, 173, 183, 189, 228, 229, 237, 249, 252, 343, 364, 410, 418, 496, 523]
        }, 237: {
            id: 237,
            name: "hitmontop",
            specie: 237,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 50,
            baseAtk: 65,
            baseDef: 103,
            baseSpeed: 70,
            moves: [27, 98, 129, 167, 168, 173, 189, 205, 228, 229, 237, 239, 249, 279, 332, 343, 364, 410, 496, 523]
        }, 238: {
            id: 238,
            name: "smoochum",
            specie: 238,
            is_default: true,
            type1: "ice",
            type2: "psychic",
            evolutions: [{to: 124, method: "level_up", minLevel: 30},],
            baseHp: 45,
            baseAtk: 58,
            baseDef: 40,
            baseSpeed: 65,
            moves: [1, 93, 122, 168, 173, 181, 189, 196, 237, 252, 343, 352, 371, 419, 496, 497, 524, 531]
        }, 239: {
            id: 239,
            name: "elekid",
            specie: 239,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 125, method: "level_up", minLevel: 30},],
            baseHp: 45,
            baseAtk: 64,
            baseDef: 46,
            baseSpeed: 95,
            moves: [2, 27, 84, 98, 129, 168, 173, 189, 237, 249, 343, 351, 364, 451, 496, 527, 530, 612]
        }, 240: {
            id: 240,
            name: "magby",
            specie: 240,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 126, method: "level_up", minLevel: 30},],
            baseHp: 45,
            baseAtk: 73,
            baseDef: 46,
            baseSpeed: 83,
            moves: [2, 52, 83, 123, 168, 173, 183, 185, 189, 237, 249, 343, 488, 496, 499, 510, 530, 612]
        }, 241: {
            id: 241,
            name: "miltank",
            specie: 241,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 95,
            baseAtk: 60,
            baseDef: 88,
            baseSpeed: 100,
            moves: [33, 173, 189, 196, 205, 237, 249, 250, 317, 351, 352, 496, 497, 523, 531, 612]
        }, 242: {
            id: 242,
            name: "blissey",
            specie: 242,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 255,
            baseAtk: 43,
            baseDef: 73,
            baseSpeed: 55,
            moves: [1, 3, 173, 189, 196, 205, 237, 249, 317, 343, 351, 352, 419, 451, 496, 497, 510, 523, 612]
        }, 243: {
            id: 243,
            name: "raikou",
            specie: 243,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 90,
            baseAtk: 100,
            baseDef: 88,
            baseSpeed: 115,
            moves: [15, 44, 84, 98, 129, 173, 189, 237, 249, 351, 451, 496, 523, 555]
        }, 244: {
            id: 244,
            name: "entei",
            specie: 244,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 115,
            baseAtk: 103,
            baseDef: 80,
            baseSpeed: 100,
            moves: [15, 44, 52, 83, 129, 173, 189, 237, 249, 488, 496, 510, 523, 555]
        }, 245: {
            id: 245,
            name: "suicune",
            specie: 245,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 100,
            baseAtk: 83,
            baseDef: 115,
            baseSpeed: 85,
            moves: [15, 16, 44, 55, 129, 173, 189, 196, 237, 249, 250, 352, 419, 466, 496, 523, 555]
        }, 246: {
            id: 246,
            name: "larvitar",
            specie: 246,
            is_default: true,
            type1: "rock",
            type2: "ground",
            evolutions: [{to: 247, method: "level_up", minLevel: 30},],
            baseHp: 50,
            baseAtk: 55,
            baseDef: 50,
            baseSpeed: 41,
            moves: [44, 173, 189, 228, 237, 246, 249, 317, 371, 372, 479, 496, 523, 555, 612]
        }, 247: {
            id: 247,
            name: "pupitar",
            specie: 247,
            is_default: true,
            type1: "rock",
            type2: "ground",
            evolutions: [{to: 248, method: "level_up", minLevel: 55},],
            baseHp: 70,
            baseAtk: 75,
            baseDef: 70,
            baseSpeed: 51,
            moves: [44, 173, 189, 237, 246, 249, 317, 371, 479, 496, 523, 555, 612]
        }, 248: {
            id: 248,
            name: "tyranitar",
            specie: 248,
            is_default: true,
            type1: "rock",
            type2: "dark",
            evolutions: [],
            baseHp: 100,
            baseAtk: 115,
            baseDef: 105,
            baseSpeed: 61,
            moves: [15, 44, 173, 189, 210, 225, 237, 246, 249, 250, 317, 332, 351, 352, 371, 419, 479, 496, 510, 523, 525, 555, 612, 693]
        }, 249: {
            id: 249,
            name: "lugia",
            specie: 249,
            is_default: true,
            type1: "psychic",
            type2: "flying",
            evolutions: [],
            baseHp: 106,
            baseAtk: 90,
            baseDef: 142,
            baseSpeed: 110,
            moves: [16, 129, 173, 189, 196, 225, 237, 239, 246, 249, 250, 311, 314, 332, 351, 352, 419, 451, 466, 496, 497, 507, 523, 525]
        }, 250: {
            id: 250,
            name: "ho-oh",
            specie: 250,
            is_default: true,
            type1: "fire",
            type2: "flying",
            evolutions: [],
            baseHp: 106,
            baseAtk: 120,
            baseDef: 122,
            baseSpeed: 90,
            moves: [16, 129, 173, 189, 225, 237, 239, 246, 249, 311, 314, 332, 351, 365, 451, 466, 488, 496, 497, 507, 510, 523]
        }, 251: {
            id: 251,
            name: "celebi",
            specie: 251,
            is_default: true,
            type1: "psychic",
            type2: "grass",
            evolutions: [],
            baseHp: 100,
            baseAtk: 100,
            baseDef: 100,
            baseSpeed: 100,
            moves: [15, 93, 129, 173, 189, 237, 246, 318, 332, 345, 351, 352, 451, 496, 497]
        },
        252: {
            id: 252,
            name: "treecko",
            specie: 252,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 253, method: "level_up", minLevel: 16},],
            baseHp: 40,
            baseAtk: 55,
            baseDef: 45,
            baseSpeed: 70,
            moves: [0, 1, 15, 24, 71, 72, 98, 129, 173, 189, 210, 225, 228, 237, 249, 317, 331, 332, 345, 496, 512, 612]
        }, 253: {
            id: 253,
            name: "grovyle",
            specie: 253,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 254, method: "level_up", minLevel: 36},],
            baseHp: 50,
            baseAtk: 75,
            baseDef: 55,
            baseSpeed: 95,
            moves: [0, 1, 15, 71, 72, 98, 129, 173, 189, 206, 210, 228, 237, 249, 317, 331, 332, 496, 512, 612]
        }, 254: {
            id: 254,
            name: "sceptile",
            specie: 254,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 70,
            baseAtk: 95,
            baseDef: 75,
            baseSpeed: 120,
            moves: [0, 1, 15, 71, 72, 98, 129, 173, 189, 206, 210, 228, 237, 249, 317, 331, 332, 496, 512, 523, 530, 612, 693]
        }, 255: {
            id: 255,
            name: "torchic",
            specie: 255,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 256, method: "level_up", minLevel: 16},],
            baseHp: 45,
            baseAtk: 65,
            baseDef: 45,
            baseSpeed: 45,
            moves: [0, 10, 15, 52, 64, 83, 98, 129, 173, 189, 237, 249, 317, 332, 364, 488, 496, 497, 510]
        }, 256: {
            id: 256,
            name: "combusken",
            specie: 256,
            is_default: true,
            type1: "fire",
            type2: "fighting",
            evolutions: [{to: 257, method: "level_up", minLevel: 36},],
            baseHp: 60,
            baseAtk: 85,
            baseDef: 60,
            baseSpeed: 55,
            moves: [0, 10, 15, 24, 52, 64, 98, 129, 173, 189, 210, 237, 249, 317, 332, 410, 488, 496, 497, 510, 530, 612]
        }, 257: {
            id: 257,
            name: "blaziken",
            specie: 257,
            is_default: true,
            type1: "fire",
            type2: "fighting",
            evolutions: [],
            baseHp: 80,
            baseAtk: 115,
            baseDef: 70,
            baseSpeed: 80,
            moves: [0, 10, 15, 24, 52, 64, 98, 129, 173, 189, 210, 237, 249, 317, 332, 410, 488, 496, 497, 510, 512, 523, 530, 612]
        }, 258: {
            id: 258,
            name: "mudkip",
            specie: 258,
            is_default: true,
            type1: "water",
            evolutions: [{to: 259, method: "level_up", minLevel: 16},],
            baseHp: 50,
            baseAtk: 60,
            baseDef: 50,
            baseSpeed: 40,
            moves: [0, 33, 44, 55, 88, 173, 189, 196, 205, 237, 246, 249, 250, 301, 317, 352, 419, 496, 497]
        }, 259: {
            id: 259,
            name: "marshtomp",
            specie: 259,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [{to: 260, method: "level_up", minLevel: 36},],
            baseHp: 70,
            baseAtk: 73,
            baseDef: 70,
            baseSpeed: 50,
            moves: [0, 33, 55, 173, 189, 196, 205, 237, 246, 249, 250, 317, 341, 352, 496, 497, 523, 612]
        }, 260: {
            id: 260,
            name: "swampert",
            specie: 260,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [],
            baseHp: 100,
            baseAtk: 98,
            baseDef: 90,
            baseSpeed: 60,
            moves: [0, 33, 55, 173, 189, 196, 205, 237, 246, 249, 250, 317, 341, 352, 419, 496, 497, 523, 612]
        }, 261: {
            id: 261,
            name: "poochyena",
            specie: 261,
            is_default: true,
            type1: "dark",
            evolutions: [{to: 262, method: "level_up", minLevel: 18},],
            baseHp: 35,
            baseAtk: 43,
            baseDef: 33,
            baseSpeed: 35,
            moves: [0, 33, 44, 168, 173, 189, 237, 249, 305, 310, 343, 371, 372, 496, 510, 555]
        }, 262: {
            id: 262,
            name: "mightyena",
            specie: 262,
            is_default: true,
            type1: "dark",
            evolutions: [],
            baseHp: 70,
            baseAtk: 75,
            baseDef: 65,
            baseSpeed: 70,
            moves: [0, 33, 44, 168, 173, 189, 237, 249, 343, 371, 372, 496, 510, 555]
        }, 263: {
            id: 263,
            name: "zigzagoon",
            specie: 263,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 264, method: "level_up", minLevel: 20},],
            baseHp: 38,
            baseAtk: 30,
            baseDef: 41,
            baseSpeed: 60,
            moves: [0, 15, 33, 42, 129, 168, 173, 189, 196, 205, 210, 228, 237, 249, 250, 343, 351, 352, 451, 496, 497]
        }, 264: {
            id: 264,
            name: "linoone",
            specie: 264,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 78,
            baseAtk: 60,
            baseDef: 61,
            baseSpeed: 100,
            moves: [0, 15, 33, 129, 154, 168, 173, 189, 196, 205, 210, 237, 249, 250, 343, 351, 352, 451, 496, 497]
        }, 265: {
            id: 265,
            name: "wurmple",
            specie: 265,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 266, method: "level_up", minLevel: 7},],
            baseHp: 45,
            baseAtk: 33,
            baseDef: 33,
            baseSpeed: 20,
            moves: [0, 33, 40, 173, 450, 527]
        }, 266: {
            id: 266,
            name: "silcoon",
            specie: 266,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 267, method: "level_up", minLevel: 10}, {to: 268, method: "level_up", minLevel: 7},],
            baseHp: 50,
            baseAtk: 30,
            baseDef: 40,
            baseSpeed: 15,
            moves: [0, 450, 527]
        }, 267: {
            id: 267,
            name: "beautifly",
            specie: 267,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 60,
            baseAtk: 85,
            baseDef: 50,
            baseSpeed: 65,
            moves: [0, 16, 71, 72, 99, 129, 168, 173, 237, 239, 314, 318, 332, 450, 466, 496, 512, 522, 527, 611]
        }, 268: {
            id: 268, name: "cascoon", specie: 268, is_default: true, type1: "bug", evolutions: [],
            baseHp: 50, baseAtk: 30, baseDef: 40, baseSpeed: 15, moves: [0, 450, 527]
        }, 269: {
            id: 269,
            name: "dustox",
            specie: 269,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [],
            baseHp: 60,
            baseAtk: 50,
            baseDef: 80,
            baseSpeed: 65,
            moves: [0, 16, 93, 129, 168, 173, 237, 239, 314, 318, 332, 450, 466, 496, 512, 522, 527, 611]
        }, 270: {
            id: 270,
            name: "lotad",
            specie: 270,
            is_default: true,
            type1: "water",
            type2: "grass",
            evolutions: [{to: 271, method: "level_up", minLevel: 14},],
            baseHp: 40,
            baseAtk: 35,
            baseDef: 40,
            baseSpeed: 30,
            moves: [0, 55, 71, 72, 75, 145, 168, 173, 196, 237, 250, 310, 331, 352, 496, 497]
        }, 271: {
            id: 271,
            name: "lombre",
            specie: 271,
            is_default: true,
            type1: "water",
            type2: "grass",
            evolutions: [{to: 272, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 55,
            baseDef: 60,
            baseSpeed: 50,
            moves: [0, 71, 145, 154, 168, 173, 189, 196, 237, 249, 250, 252, 310, 331, 352, 496, 497, 612]
        }, 272: {
            id: 272,
            name: "ludicolo",
            specie: 272,
            is_default: true,
            type1: "water",
            type2: "grass",
            evolutions: [],
            baseHp: 80,
            baseAtk: 80,
            baseDef: 85,
            baseSpeed: 70,
            moves: [0, 71, 72, 168, 173, 189, 196, 237, 249, 250, 310, 331, 352, 496, 497, 612]
        }, 273: {
            id: 273,
            name: "seedot",
            specie: 273,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 274, method: "level_up", minLevel: 14},],
            baseHp: 40,
            baseAtk: 35,
            baseDef: 40,
            baseSpeed: 30,
            moves: [0, 98, 173, 205, 206, 237, 249, 331, 496]
        }, 274: {
            id: 274,
            name: "nuzleaf",
            specie: 274,
            is_default: true,
            type1: "grass",
            type2: "dark",
            evolutions: [{to: 275, method: "level_up", minLevel: 32},],
            baseHp: 70,
            baseAtk: 65,
            baseDef: 40,
            baseSpeed: 60,
            moves: [0, 1, 15, 75, 129, 168, 173, 185, 189, 205, 206, 210, 237, 249, 252, 317, 331, 371, 496, 555, 612]
        }, 275: {
            id: 275,
            name: "shiftry",
            specie: 275,
            is_default: true,
            type1: "grass",
            type2: "dark",
            evolutions: [],
            baseHp: 90,
            baseAtk: 95,
            baseDef: 60,
            baseSpeed: 80,
            moves: [0, 1, 15, 75, 129, 168, 173, 185, 189, 196, 205, 206, 210, 237, 239, 249, 314, 317, 318, 331, 332, 371, 466, 496, 555, 612, 693]
        }, 276: {
            id: 276,
            name: "taillow",
            specie: 276,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 277, method: "level_up", minLevel: 22},],
            baseHp: 40,
            baseAtk: 43,
            baseDef: 30,
            baseSpeed: 85,
            moves: [0, 17, 64, 98, 99, 129, 168, 173, 189, 228, 237, 239, 314, 332, 365, 466, 496, 497]
        }, 277: {
            id: 277,
            name: "swellow",
            specie: 277,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 60,
            baseAtk: 80,
            baseDef: 55,
            baseSpeed: 125,
            moves: [0, 17, 64, 98, 129, 168, 173, 189, 237, 239, 314, 332, 365, 466, 496, 497]
        }, 278: {
            id: 278,
            name: "wingull",
            specie: 278,
            is_default: true,
            type1: "water",
            type2: "flying",
            evolutions: [{to: 279, method: "level_up", minLevel: 25},],
            baseHp: 40,
            baseAtk: 43,
            baseDef: 30,
            baseSpeed: 85,
            moves: [0, 16, 17, 55, 98, 129, 168, 173, 189, 196, 228, 237, 239, 314, 332, 351, 352, 365, 466, 496, 497]
        }, 279: {
            id: 279,
            name: "pelipper",
            specie: 279,
            is_default: true,
            type1: "water",
            type2: "flying",
            evolutions: [],
            baseHp: 60,
            baseAtk: 73,
            baseDef: 85,
            baseSpeed: 65,
            moves: [0, 17, 55, 129, 168, 173, 189, 196, 237, 239, 250, 314, 332, 351, 352, 365, 371, 466, 496, 497, 507]
        }, 280: {
            id: 280,
            name: "ralts",
            specie: 280,
            is_default: true,
            type1: "psychic",
            type2: "fairy",
            evolutions: [{to: 281, method: "level_up", minLevel: 20},],
            baseHp: 28,
            baseAtk: 35,
            baseDef: 30,
            baseSpeed: 40,
            moves: [0, 93, 129, 168, 173, 189, 196, 237, 345, 351, 425, 451, 496, 497, 500, 574, 577]
        }, 281: {
            id: 281,
            name: "kirlia",
            specie: 281,
            is_default: true,
            type1: "psychic",
            type2: "fairy",
            evolutions: [{to: 282, method: "level_up", minLevel: 30}, {to: 475, method: "level_up", minLevel: 32},],
            baseHp: 38,
            baseAtk: 50,
            baseDef: 45,
            baseSpeed: 50,
            moves: [0, 93, 129, 168, 173, 189, 196, 237, 345, 351, 451, 496, 497, 500, 574, 577]
        }, 282: {
            id: 282,
            name: "gardevoir",
            specie: 282,
            is_default: true,
            type1: "psychic",
            type2: "fairy",
            evolutions: [],
            baseHp: 68,
            baseAtk: 95,
            baseDef: 90,
            baseSpeed: 80,
            moves: [0, 93, 129, 168, 173, 189, 196, 237, 345, 351, 451, 496, 497, 500, 574, 577]
        }, 283: {
            id: 283,
            name: "surskit",
            specie: 283,
            is_default: true,
            type1: "bug",
            type2: "water",
            evolutions: [{to: 284, method: "level_up", minLevel: 22},],
            baseHp: 40,
            baseAtk: 40,
            baseDef: 42,
            baseSpeed: 65,
            moves: [0, 98, 129, 145, 168, 173, 189, 196, 237, 341, 352, 450, 453, 496, 522, 565, 611]
        }, 284: {
            id: 284,
            name: "masquerain",
            specie: 284,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 70,
            baseAtk: 80,
            baseDef: 72,
            baseSpeed: 80,
            moves: [0, 16, 98, 129, 145, 168, 173, 189, 196, 237, 239, 314, 318, 332, 352, 450, 466, 496, 522, 611]
        }, 285: {
            id: 285,
            name: "shroomish",
            specie: 285,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 286, method: "level_up", minLevel: 23},],
            baseHp: 60,
            baseAtk: 40,
            baseDef: 60,
            baseSpeed: 35,
            moves: [0, 33, 71, 72, 173, 206, 237, 331, 496]
        }, 286: {
            id: 286,
            name: "breloom",
            specie: 286,
            is_default: true,
            type1: "grass",
            type2: "fighting",
            evolutions: [],
            baseHp: 60,
            baseAtk: 95,
            baseDef: 70,
            baseSpeed: 70,
            moves: [0, 15, 33, 71, 72, 173, 183, 189, 206, 210, 237, 249, 317, 331, 364, 395, 410, 496, 612]
        }, 287: {
            id: 287,
            name: "slakoth",
            specie: 287,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 288, method: "level_up", minLevel: 18},],
            baseHp: 60,
            baseAtk: 48,
            baseDef: 48,
            baseSpeed: 30,
            moves: [0, 10, 15, 173, 185, 189, 196, 210, 228, 237, 249, 317, 332, 343, 351, 352, 496, 510, 612]
        }, 288: {
            id: 288,
            name: "vigoroth",
            specie: 288,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 289, method: "level_up", minLevel: 36},],
            baseHp: 80,
            baseAtk: 68,
            baseDef: 68,
            baseSpeed: 90,
            moves: [0, 10, 15, 154, 173, 189, 196, 210, 237, 249, 317, 332, 343, 351, 352, 496, 510, 523, 612]
        }, 289: {
            id: 289,
            name: "slaking",
            specie: 289,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 150,
            baseAtk: 128,
            baseDef: 83,
            baseSpeed: 100,
            moves: [0, 10, 15, 173, 185, 189, 196, 210, 237, 249, 317, 332, 343, 351, 352, 479, 496, 510, 523, 612]
        }, 290: {
            id: 290,
            name: "nincada",
            specie: 290,
            is_default: true,
            type1: "bug",
            type2: "ground",
            evolutions: [{to: 291, method: "level_up", minLevel: 20}, {to: 292, method: "level_up", minLevel: 32},],
            baseHp: 31,
            baseAtk: 38,
            baseDef: 60,
            baseSpeed: 40,
            moves: [0, 10, 15, 16, 71, 154, 173, 185, 189, 206, 210, 232, 237, 318, 332, 450, 496, 522]
        }, 291: {
            id: 291,
            name: "ninjask",
            specie: 291,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 61,
            baseAtk: 70,
            baseDef: 48,
            baseSpeed: 160,
            moves: [0, 10, 15, 71, 129, 154, 168, 173, 189, 206, 210, 237, 314, 318, 332, 450, 466, 496, 522]
        }, 292: {
            id: 292,
            name: "shedinja",
            specie: 292,
            is_default: true,
            type1: "bug",
            type2: "ghost",
            evolutions: [],
            baseHp: 1,
            baseAtk: 60,
            baseDef: 38,
            baseSpeed: 40,
            moves: [0, 10, 15, 71, 154, 168, 173, 189, 206, 210, 237, 332, 425, 450, 496, 522]
        }, 293: {
            id: 293,
            name: "whismur",
            specie: 293,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 294, method: "level_up", minLevel: 20},],
            baseHp: 64,
            baseAtk: 51,
            baseDef: 23,
            baseSpeed: 28,
            moves: [0, 1, 173, 189, 196, 205, 237, 310, 351, 352, 496, 497, 509, 510, 574]
        }, 294: {
            id: 294,
            name: "loudred",
            specie: 294,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 295, method: "level_up", minLevel: 40},],
            baseHp: 84,
            baseAtk: 71,
            baseDef: 43,
            baseSpeed: 48,
            moves: [0, 1, 44, 173, 189, 196, 205, 237, 249, 310, 317, 351, 352, 479, 496, 497, 510, 523, 612]
        }, 295: {
            id: 295,
            name: "exploud",
            specie: 295,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 104,
            baseAtk: 91,
            baseDef: 68,
            baseSpeed: 68,
            moves: [0, 1, 44, 173, 189, 196, 205, 237, 249, 250, 310, 317, 351, 352, 419, 479, 496, 497, 510, 523, 612]
        }, 296: {
            id: 296,
            name: "makuhita",
            specie: 296,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 297, method: "level_up", minLevel: 24},],
            baseHp: 72,
            baseAtk: 40,
            baseDef: 30,
            baseSpeed: 25,
            moves: [0, 33, 173, 185, 189, 237, 249, 250, 252, 279, 292, 317, 364, 395, 410, 418, 479, 496, 523, 612]
        }, 297: {
            id: 297,
            name: "hariyama",
            specie: 297,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 144,
            baseAtk: 80,
            baseDef: 60,
            baseSpeed: 50,
            moves: [0, 33, 173, 189, 237, 249, 250, 252, 292, 317, 371, 395, 410, 479, 496, 523, 612]
        }, 298: {
            id: 298,
            name: "azurill",
            specie: 298,
            is_default: true,
            type1: "normal",
            type2: "fairy",
            evolutions: [{to: 183, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 20,
            baseDef: 40,
            baseSpeed: 20,
            moves: [0, 55, 129, 145, 173, 189, 196, 205, 237, 250, 343, 352, 496]
        }, 299: {
            id: 299,
            name: "nosepass",
            specie: 299,
            is_default: true,
            type1: "rock",
            evolutions: [{to: 476, method: "level_up", minLevel: 32},],
            baseHp: 30,
            baseAtk: 45,
            baseDef: 113,
            baseSpeed: 30,
            moves: [0, 33, 88, 173, 189, 205, 237, 246, 249, 317, 350, 351, 479, 496, 523]
        }, 300: {
            id: 300,
            name: "skitty",
            specie: 300,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 301, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 40,
            baseDef: 40,
            baseSpeed: 50,
            moves: [0, 3, 33, 129, 173, 185, 189, 196, 205, 237, 252, 343, 351, 352, 371, 451, 496, 497, 574]
        }, 301: {
            id: 301,
            name: "delcatty",
            specie: 301,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 70,
            baseAtk: 60,
            baseDef: 60,
            baseSpeed: 90,
            moves: [0, 3, 129, 173, 189, 196, 205, 237, 249, 252, 343, 351, 352, 371, 451, 496, 497]
        }, 302: {
            id: 302,
            name: "sableye",
            specie: 302,
            is_default: true,
            type1: "dark",
            type2: "ghost",
            evolutions: [],
            baseHp: 50,
            baseAtk: 70,
            baseDef: 70,
            baseSpeed: 50,
            moves: [0, 10, 15, 154, 168, 173, 185, 189, 196, 210, 237, 249, 252, 310, 317, 332, 351, 352, 364, 371, 425, 466, 496, 510, 555, 612]
        }, 303: {
            id: 303,
            name: "mawile",
            specie: 303,
            is_default: true,
            type1: "steel",
            type2: "fairy",
            evolutions: [],
            baseHp: 50,
            baseAtk: 70,
            baseDef: 70,
            baseSpeed: 50,
            moves: [0, 11, 44, 173, 185, 189, 196, 206, 237, 246, 249, 305, 310, 317, 371, 451, 496, 510, 584, 612]
        }, 304: {
            id: 304,
            name: "aron",
            specie: 304,
            is_default: true,
            type1: "steel",
            type2: "rock",
            evolutions: [{to: 305, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 55,
            baseDef: 70,
            baseSpeed: 30,
            moves: [0, 15, 33, 173, 189, 205, 210, 232, 237, 246, 249, 317, 332, 351, 352, 496, 523]
        }, 305: {
            id: 305,
            name: "lairon",
            specie: 305,
            is_default: true,
            type1: "steel",
            type2: "rock",
            evolutions: [{to: 306, method: "level_up", minLevel: 42},],
            baseHp: 60,
            baseAtk: 70,
            baseDef: 95,
            baseSpeed: 40,
            moves: [0, 15, 33, 173, 189, 205, 210, 232, 237, 246, 249, 317, 332, 351, 352, 496, 523]
        }, 306: {
            id: 306,
            name: "aggron",
            specie: 306,
            is_default: true,
            type1: "steel",
            type2: "rock",
            evolutions: [],
            baseHp: 70,
            baseAtk: 85,
            baseDef: 120,
            baseSpeed: 50,
            moves: [0, 15, 33, 173, 189, 196, 205, 210, 232, 237, 246, 249, 250, 317, 332, 351, 352, 371, 419, 479, 496, 510, 523, 525, 612, 693]
        }, 307: {
            id: 307,
            name: "meditite",
            specie: 307,
            is_default: true,
            type1: "fighting",
            type2: "psychic",
            evolutions: [{to: 308, method: "level_up", minLevel: 37},],
            baseHp: 30,
            baseAtk: 40,
            baseDef: 55,
            baseSpeed: 60,
            moves: [0, 93, 129, 173, 189, 237, 249, 252, 317, 364, 395, 410, 418, 496, 612]
        }, 308: {
            id: 308,
            name: "medicham",
            specie: 308,
            is_default: true,
            type1: "fighting",
            type2: "psychic",
            evolutions: [],
            baseHp: 60,
            baseAtk: 60,
            baseDef: 75,
            baseSpeed: 80,
            moves: [0, 93, 129, 173, 189, 237, 249, 317, 364, 395, 410, 496, 612]
        }, 309: {
            id: 309,
            name: "electrike",
            specie: 309,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 310, method: "level_up", minLevel: 26},],
            baseHp: 40,
            baseAtk: 55,
            baseDef: 40,
            baseSpeed: 65,
            moves: [0, 33, 44, 98, 129, 168, 173, 189, 237, 351, 451, 496, 555]
        }, 310: {
            id: 310,
            name: "manectric",
            specie: 310,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 70,
            baseAtk: 90,
            baseDef: 60,
            baseSpeed: 105,
            moves: [0, 33, 44, 98, 129, 168, 173, 189, 237, 351, 451, 496, 555]
        }, 311: {
            id: 311,
            name: "plusle",
            specie: 311,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 60,
            baseAtk: 68,
            baseDef: 58,
            baseSpeed: 95,
            moves: [0, 98, 129, 173, 189, 205, 237, 351, 451, 496, 497, 527, 609]
        }, 312: {
            id: 312,
            name: "minun",
            specie: 312,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 60,
            baseAtk: 58,
            baseDef: 68,
            baseSpeed: 95,
            moves: [0, 98, 129, 173, 189, 205, 237, 351, 451, 496, 497, 527, 609]
        }, 313: {
            id: 313,
            name: "volbeat",
            specie: 313,
            is_default: true,
            type1: "bug",
            evolutions: [],
            baseHp: 65,
            baseAtk: 60,
            baseDef: 80,
            baseSpeed: 85,
            moves: [0, 33, 98, 129, 168, 173, 189, 237, 314, 318, 332, 351, 352, 450, 451, 466, 496, 512, 522, 611, 612]
        }, 314: {
            id: 314,
            name: "illumise",
            specie: 314,
            is_default: true,
            type1: "bug",
            evolutions: [],
            baseHp: 65,
            baseAtk: 60,
            baseDef: 80,
            baseSpeed: 85,
            moves: [0, 33, 98, 129, 168, 173, 189, 237, 314, 318, 332, 343, 351, 352, 450, 451, 466, 496, 512, 522, 611, 612]
        }, 315: {
            id: 315,
            name: "roselia",
            specie: 315,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 407, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 80,
            baseDef: 63,
            baseSpeed: 65,
            moves: [0, 15, 40, 42, 71, 72, 75, 129, 173, 189, 210, 237, 331, 343, 345, 496]
        }, 316: {
            id: 316,
            name: "gulpin",
            specie: 316,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 317, method: "level_up", minLevel: 26},],
            baseHp: 70,
            baseAtk: 43,
            baseDef: 53,
            baseSpeed: 40,
            moves: [0, 1, 123, 173, 189, 205, 237, 249, 331, 351, 352, 491, 496, 611, 612]
        }, 317: {
            id: 317,
            name: "swalot",
            specie: 317,
            is_default: true,
            type1: "poison",
            evolutions: [],
            baseHp: 100,
            baseAtk: 73,
            baseDef: 83,
            baseSpeed: 55,
            moves: [0, 1, 173, 189, 205, 237, 249, 331, 351, 352, 491, 496, 523, 611, 612]
        }, 318: {
            id: 318,
            name: "carvanha",
            specie: 318,
            is_default: true,
            type1: "water",
            type2: "dark",
            evolutions: [{to: 319, method: "level_up", minLevel: 30},],
            baseHp: 45,
            baseAtk: 78,
            baseDef: 20,
            baseSpeed: 65,
            moves: [0, 44, 99, 129, 168, 173, 189, 196, 210, 237, 246, 250, 305, 352, 371, 372, 453, 496, 555]
        }, 319: {
            id: 319,
            name: "sharpedo",
            specie: 319,
            is_default: true,
            type1: "water",
            type2: "dark",
            evolutions: [],
            baseHp: 70,
            baseAtk: 108,
            baseDef: 40,
            baseSpeed: 95,
            moves: [0, 44, 99, 129, 168, 173, 189, 196, 210, 237, 246, 249, 250, 305, 317, 352, 364, 371, 372, 419, 453, 496, 523, 555]
        }, 320: {
            id: 320,
            name: "wailmer",
            specie: 320,
            is_default: true,
            type1: "water",
            evolutions: [{to: 321, method: "level_up", minLevel: 40},],
            baseHp: 130,
            baseAtk: 70,
            baseDef: 35,
            baseSpeed: 60,
            moves: [0, 55, 173, 196, 205, 237, 249, 250, 310, 317, 352, 419, 496, 497, 499, 523]
        }, 321: {
            id: 321,
            name: "wailord",
            specie: 321,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 170,
            baseAtk: 90,
            baseDef: 45,
            baseSpeed: 60,
            moves: [0, 55, 173, 196, 205, 237, 249, 250, 310, 317, 352, 419, 496, 497, 523]
        }, 322: {
            id: 322,
            name: "numel",
            specie: 322,
            is_default: true,
            type1: "fire",
            type2: "ground",
            evolutions: [{to: 323, method: "level_up", minLevel: 33},],
            baseHp: 60,
            baseAtk: 63,
            baseDef: 43,
            baseSpeed: 35,
            moves: [0, 33, 52, 173, 189, 205, 237, 246, 249, 317, 488, 496, 497, 510, 523]
        }, 323: {
            id: 323,
            name: "camerupt",
            specie: 323,
            is_default: true,
            type1: "fire",
            type2: "ground",
            evolutions: [],
            baseHp: 70,
            baseAtk: 103,
            baseDef: 73,
            baseSpeed: 40,
            moves: [0, 33, 52, 173, 189, 205, 237, 249, 317, 488, 496, 497, 510, 523]
        }, 324: {
            id: 324,
            name: "torkoal",
            specie: 324,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 70,
            baseAtk: 85,
            baseDef: 105,
            baseSpeed: 20,
            moves: [0, 52, 83, 123, 172, 173, 189, 205, 229, 237, 249, 317, 488, 496, 499, 510, 523]
        }, 325: {
            id: 325,
            name: "spoink",
            specie: 325,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 326, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 48,
            baseDef: 58,
            baseSpeed: 60,
            moves: [0, 129, 168, 173, 196, 237, 343, 351, 371, 451, 496]
        }, 326: {
            id: 326,
            name: "grumpig",
            specie: 326,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 80,
            baseAtk: 68,
            baseDef: 88,
            baseSpeed: 80,
            moves: [0, 129, 168, 173, 189, 196, 237, 343, 351, 371, 451, 496, 523, 612]
        }, 327: {
            id: 327,
            name: "spinda",
            specie: 327,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 60,
            baseAtk: 60,
            baseDef: 60,
            baseSpeed: 60,
            moves: [0, 33, 129, 168, 173, 185, 189, 196, 205, 229, 237, 249, 252, 317, 343, 351, 352, 496, 612]
        }, 328: {
            id: 328,
            name: "trapinch",
            specie: 328,
            is_default: true,
            type1: "ground",
            evolutions: [{to: 329, method: "level_up", minLevel: 35},],
            baseHp: 45,
            baseAtk: 73,
            baseDef: 45,
            baseSpeed: 10,
            moves: [0, 16, 44, 98, 173, 185, 189, 210, 237, 249, 317, 328, 341, 364, 450, 496, 522, 523]
        }, 329: {
            id: 329,
            name: "vibrava",
            specie: 329,
            is_default: true,
            type1: "ground",
            type2: "dragon",
            evolutions: [{to: 330, method: "level_up", minLevel: 45},],
            baseHp: 50,
            baseAtk: 60,
            baseDef: 50,
            baseSpeed: 70,
            moves: [0, 44, 129, 173, 185, 189, 210, 225, 237, 239, 249, 314, 317, 318, 328, 450, 466, 496, 522, 523]
        }, 330: {
            id: 330,
            name: "flygon",
            specie: 330,
            is_default: true,
            type1: "ground",
            type2: "dragon",
            evolutions: [],
            baseHp: 80,
            baseAtk: 90,
            baseDef: 80,
            baseSpeed: 100,
            moves: [0, 44, 129, 173, 185, 189, 210, 225, 237, 239, 249, 314, 317, 318, 328, 332, 450, 466, 496, 510, 522, 523, 525, 612, 693]
        }, 331: {
            id: 331,
            name: "cacnea",
            specie: 331,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 332, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 85,
            baseDef: 40,
            baseSpeed: 35,
            moves: [0, 15, 40, 42, 51, 71, 173, 185, 189, 210, 237, 302, 331, 345, 371, 496, 565, 612]
        }, 332: {
            id: 332,
            name: "cacturne",
            specie: 332,
            is_default: true,
            type1: "grass",
            type2: "dark",
            evolutions: [],
            baseHp: 70,
            baseAtk: 115,
            baseDef: 60,
            baseSpeed: 55,
            moves: [0, 15, 40, 42, 71, 173, 185, 189, 210, 237, 279, 302, 331, 371, 496, 612]
        }, 333: {
            id: 333,
            name: "swablu",
            specie: 333,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 334, method: "level_up", minLevel: 35},],
            baseHp: 45,
            baseAtk: 40,
            baseDef: 68,
            baseSpeed: 50,
            moves: [0, 31, 64, 99, 129, 168, 173, 189, 228, 237, 239, 310, 314, 332, 365, 466, 496, 497, 574]
        }, 334: {
            id: 334,
            name: "altaria",
            specie: 334,
            is_default: true,
            type1: "dragon",
            type2: "flying",
            evolutions: [],
            baseHp: 75,
            baseAtk: 70,
            baseDef: 98,
            baseSpeed: 80,
            moves: [0, 31, 64, 129, 168, 173, 189, 225, 237, 239, 249, 310, 314, 332, 365, 466, 496, 497, 510, 523, 574]
        }, 335: {
            id: 335,
            name: "zangoose",
            specie: 335,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 73,
            baseAtk: 88,
            baseDef: 60,
            baseSpeed: 90,
            moves: [0, 10, 24, 98, 129, 154, 168, 173, 189, 196, 205, 206, 210, 228, 232, 237, 249, 279, 317, 332, 351, 352, 364, 371, 458, 496, 510, 612]
        }, 336: {
            id: 336,
            name: "seviper",
            specie: 336,
            is_default: true,
            type1: "poison",
            evolutions: [],
            baseHp: 73,
            baseAtk: 100,
            baseDef: 60,
            baseSpeed: 65,
            moves: [0, 20, 35, 44, 122, 129, 168, 173, 189, 210, 237, 249, 305, 342, 364, 371, 372, 496, 523, 525, 611, 693]
        }, 337: {
            id: 337,
            name: "lunatone",
            specie: 337,
            is_default: true,
            type1: "rock",
            type2: "psychic",
            evolutions: [],
            baseHp: 90,
            baseAtk: 75,
            baseDef: 75,
            baseSpeed: 70,
            moves: [0, 33, 88, 93, 129, 173, 196, 205, 237, 246, 317, 451, 479, 496, 512, 523]
        }, 338: {
            id: 338,
            name: "solrock",
            specie: 338,
            is_default: true,
            type1: "rock",
            type2: "psychic",
            evolutions: [],
            baseHp: 90,
            baseAtk: 75,
            baseDef: 75,
            baseSpeed: 70,
            moves: [0, 33, 83, 88, 93, 129, 173, 205, 237, 246, 317, 451, 479, 496, 510, 512, 523]
        }, 339: {
            id: 339,
            name: "barboach",
            specie: 339,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [{to: 340, method: "level_up", minLevel: 30},],
            baseHp: 50,
            baseAtk: 47,
            baseDef: 42,
            baseSpeed: 60,
            moves: [0, 55, 173, 189, 196, 237, 250, 317, 341, 352, 496, 523]
        }, 340: {
            id: 340,
            name: "whiscash",
            specie: 340,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [],
            baseHp: 110,
            baseAtk: 77,
            baseDef: 72,
            baseSpeed: 60,
            moves: [0, 55, 173, 189, 196, 237, 249, 250, 317, 352, 496, 523]
        }, 341: {
            id: 341,
            name: "corphish",
            specie: 341,
            is_default: true,
            type1: "water",
            evolutions: [{to: 342, method: "level_up", minLevel: 30},],
            baseHp: 43,
            baseAtk: 65,
            baseDef: 50,
            baseSpeed: 35,
            moves: [0, 11, 15, 145, 173, 189, 196, 206, 210, 232, 237, 246, 249, 250, 317, 332, 352, 371, 453, 458, 496]
        }, 342: {
            id: 342,
            name: "crawdaunt",
            specie: 342,
            is_default: true,
            type1: "water",
            type2: "dark",
            evolutions: [],
            baseHp: 63,
            baseAtk: 105,
            baseDef: 70,
            baseSpeed: 55,
            moves: [0, 11, 15, 129, 145, 173, 189, 196, 206, 210, 237, 246, 249, 250, 317, 332, 352, 371, 419, 458, 496, 555]
        }, 343: {
            id: 343,
            name: "baltoy",
            specie: 343,
            is_default: true,
            type1: "ground",
            type2: "psychic",
            evolutions: [{to: 344, method: "level_up", minLevel: 36},],
            baseHp: 40,
            baseAtk: 40,
            baseDef: 63,
            baseSpeed: 55,
            moves: [0, 93, 173, 189, 229, 237, 246, 317, 451, 479, 496, 523]
        }, 344: {
            id: 344,
            name: "claydol",
            specie: 344,
            is_default: true,
            type1: "ground",
            type2: "psychic",
            evolutions: [],
            baseHp: 60,
            baseAtk: 70,
            baseDef: 113,
            baseSpeed: 75,
            moves: [0, 93, 173, 189, 229, 237, 246, 249, 317, 451, 479, 496, 523]
        }, 345: {
            id: 345,
            name: "lileep",
            specie: 345,
            is_default: true,
            type1: "rock",
            type2: "grass",
            evolutions: [{to: 346, method: "level_up", minLevel: 40},],
            baseHp: 66,
            baseAtk: 51,
            baseDef: 82,
            baseSpeed: 23,
            moves: [0, 20, 51, 72, 132, 173, 189, 237, 246, 310, 317, 331, 479, 496, 611]
        }, 346: {
            id: 346,
            name: "cradily",
            specie: 346,
            is_default: true,
            type1: "rock",
            type2: "grass",
            evolutions: [],
            baseHp: 86,
            baseAtk: 81,
            baseDef: 102,
            baseSpeed: 43,
            moves: [0, 20, 51, 132, 173, 189, 237, 246, 249, 310, 317, 331, 479, 496, 523, 611]
        }, 347: {
            id: 347,
            name: "anorith",
            specie: 347,
            is_default: true,
            type1: "rock",
            type2: "bug",
            evolutions: [{to: 348, method: "level_up", minLevel: 40},],
            baseHp: 45,
            baseAtk: 68,
            baseDef: 50,
            baseSpeed: 75,
            moves: [0, 10, 15, 55, 173, 189, 206, 210, 229, 232, 237, 246, 249, 317, 332, 350, 352, 450, 453, 479, 496, 522]
        }, 348: {
            id: 348,
            name: "armaldo",
            specie: 348,
            is_default: true,
            type1: "rock",
            type2: "bug",
            evolutions: [],
            baseHp: 75,
            baseAtk: 98,
            baseDef: 90,
            baseSpeed: 45,
            moves: [0, 10, 15, 55, 173, 189, 206, 210, 232, 237, 246, 249, 317, 332, 350, 352, 450, 479, 496, 522, 523, 693]
        }, 349: {
            id: 349,
            name: "feebas",
            specie: 349,
            is_default: true,
            type1: "water",
            evolutions: [{to: 350, method: "level_up", minLevel: 32},],
            baseHp: 20,
            baseAtk: 13,
            baseDef: 38,
            baseSpeed: 80,
            moves: [0, 33, 129, 173, 196, 225, 237, 250, 352, 496]
        }, 350: {
            id: 350,
            name: "milotic",
            specie: 350,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 95,
            baseAtk: 80,
            baseDef: 102,
            baseSpeed: 81,
            moves: [0, 20, 35, 55, 129, 173, 189, 196, 237, 239, 250, 352, 419, 496, 523, 525, 574, 693]
        }, 351: {
            id: 351,
            name: "castform",
            specie: 351,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 70,
            baseAtk: 70,
            baseDef: 70,
            baseSpeed: 70,
            moves: [0, 33, 52, 55, 129, 168, 173, 181, 196, 237, 311, 351, 352, 419, 466, 496, 499, 510]
        }, 352: {
            id: 352,
            name: "kecleon",
            specie: 352,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 60,
            baseAtk: 75,
            baseDef: 95,
            baseSpeed: 40,
            moves: [0, 10, 15, 20, 122, 129, 154, 168, 173, 185, 189, 196, 205, 210, 237, 246, 249, 252, 310, 317, 332, 351, 352, 364, 425, 451, 496, 510, 612]
        }, 353: {
            id: 353,
            name: "shuppet",
            specie: 353,
            is_default: true,
            type1: "ghost",
            evolutions: [{to: 354, method: "level_up", minLevel: 37},],
            baseHp: 44,
            baseAtk: 69,
            baseDef: 34,
            baseSpeed: 45,
            moves: [0, 168, 173, 185, 196, 228, 237, 310, 351, 371, 425, 451, 466, 496]
        }, 354: {
            id: 354,
            name: "banette",
            specie: 354,
            is_default: true,
            type1: "ghost",
            evolutions: [],
            baseHp: 64,
            baseAtk: 99,
            baseDef: 64,
            baseSpeed: 65,
            moves: [0, 168, 173, 185, 189, 196, 237, 351, 371, 425, 451, 466, 496, 611]
        }, 355: {
            id: 355,
            name: "duskull",
            specie: 355,
            is_default: true,
            type1: "ghost",
            evolutions: [{to: 356, method: "level_up", minLevel: 37},],
            baseHp: 20,
            baseAtk: 35,
            baseDef: 90,
            baseSpeed: 25,
            moves: [0, 168, 173, 185, 196, 228, 237, 310, 371, 425, 451, 466, 496, 611]
        }, 356: {
            id: 356,
            name: "dusclops",
            specie: 356,
            is_default: true,
            type1: "ghost",
            evolutions: [{to: 477, method: "level_up", minLevel: 32},],
            baseHp: 40,
            baseAtk: 65,
            baseDef: 130,
            baseSpeed: 25,
            moves: [0, 20, 168, 173, 189, 196, 228, 237, 249, 310, 317, 325, 371, 425, 451, 466, 496, 523, 611, 612]
        }, 357: {
            id: 357,
            name: "tropius",
            specie: 357,
            is_default: true,
            type1: "grass",
            type2: "flying",
            evolutions: [],
            baseHp: 99,
            baseAtk: 70,
            baseDef: 85,
            baseSpeed: 51,
            moves: [0, 15, 16, 75, 173, 189, 210, 237, 239, 249, 314, 318, 331, 332, 345, 466, 496, 523, 693]
        }, 358: {
            id: 358,
            name: "chimecho",
            specie: 358,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 75,
            baseAtk: 73,
            baseDef: 85,
            baseSpeed: 65,
            moves: [0, 20, 35, 93, 173, 196, 205, 237, 310, 351, 451, 496, 497, 500]
        }, 359: {
            id: 359,
            name: "absol",
            specie: 359,
            is_default: true,
            type1: "dark",
            evolutions: [],
            baseHp: 65,
            baseAtk: 103,
            baseDef: 60,
            baseSpeed: 75,
            moves: [0, 10, 15, 44, 98, 129, 168, 173, 185, 189, 196, 206, 210, 228, 237, 249, 317, 332, 351, 352, 364, 371, 372, 451, 496, 497, 510, 555, 693]
        }, 360: {
            id: 360,
            name: "wynaut",
            specie: 360,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 202, method: "level_up", minLevel: 15},],
            baseHp: 95,
            baseAtk: 23,
            baseDef: 48,
            baseSpeed: 23,
            moves: [0]
        }, 361: {
            id: 361,
            name: "snorunt",
            specie: 361,
            is_default: true,
            type1: "ice",
            evolutions: [{to: 362, method: "level_up", minLevel: 42}, {to: 478, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 50,
            baseDef: 50,
            baseSpeed: 50,
            moves: [0, 44, 173, 181, 196, 205, 237, 311, 352, 419, 420, 496, 524]
        }, 362: {
            id: 362,
            name: "glalie",
            specie: 362,
            is_default: true,
            type1: "ice",
            evolutions: [],
            baseHp: 80,
            baseAtk: 80,
            baseDef: 80,
            baseSpeed: 80,
            moves: [0, 44, 173, 181, 196, 205, 237, 352, 371, 419, 420, 496, 523, 524]
        }, 363: {
            id: 363,
            name: "spheal",
            specie: 363,
            is_default: true,
            type1: "ice",
            type2: "water",
            evolutions: [{to: 364, method: "level_up", minLevel: 32},],
            baseHp: 70,
            baseAtk: 48,
            baseDef: 50,
            baseSpeed: 25,
            moves: [0, 55, 173, 181, 189, 196, 205, 237, 249, 250, 301, 317, 352, 496, 497, 523, 524]
        }, 364: {
            id: 364,
            name: "sealeo",
            specie: 364,
            is_default: true,
            type1: "ice",
            type2: "water",
            evolutions: [{to: 365, method: "level_up", minLevel: 44},],
            baseHp: 90,
            baseAtk: 68,
            baseDef: 70,
            baseSpeed: 45,
            moves: [0, 55, 173, 181, 189, 196, 205, 237, 249, 250, 301, 317, 352, 496, 497, 523, 524]
        }, 365: {
            id: 365,
            name: "walrein",
            specie: 365,
            is_default: true,
            type1: "ice",
            type2: "water",
            evolutions: [],
            baseHp: 110,
            baseAtk: 88,
            baseDef: 90,
            baseSpeed: 65,
            moves: [0, 55, 173, 181, 189, 196, 205, 210, 237, 249, 250, 301, 317, 352, 419, 496, 497, 523, 524]
        }, 366: {
            id: 366,
            name: "clamperl",
            specie: 366,
            is_default: true,
            type1: "water",
            evolutions: [{to: 367, method: "level_up", minLevel: 32}, {to: 368, method: "level_up", minLevel: 32},],
            baseHp: 35,
            baseAtk: 69,
            baseDef: 70,
            baseSpeed: 32,
            moves: [0, 55, 128, 173, 196, 237, 250, 352, 496]
        }, 367: {
            id: 367,
            name: "huntail",
            specie: 367,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 55,
            baseAtk: 99,
            baseDef: 90,
            baseSpeed: 52,
            moves: [0, 20, 44, 129, 173, 185, 189, 196, 237, 250, 317, 352, 496, 611]
        }, 368: {
            id: 368,
            name: "gorebyss",
            specie: 368,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 55,
            baseAtk: 99,
            baseDef: 90,
            baseSpeed: 52,
            moves: [0, 20, 93, 129, 173, 189, 196, 237, 250, 352, 496, 577, 611]
        }, 369: {
            id: 369,
            name: "relicanth",
            specie: 369,
            is_default: true,
            type1: "water",
            type2: "rock",
            evolutions: [],
            baseHp: 100,
            baseAtk: 68,
            baseDef: 98,
            baseSpeed: 55,
            moves: [0, 33, 55, 173, 189, 196, 237, 246, 249, 250, 317, 341, 352, 479, 496, 523]
        }, 370: {
            id: 370,
            name: "luvdisc",
            specie: 370,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 43,
            baseAtk: 35,
            baseDef: 60,
            baseSpeed: 97,
            moves: [0, 33, 55, 129, 173, 196, 237, 250, 352, 453, 496, 531, 577]
        }, 371: {
            id: 371,
            name: "bagon",
            specie: 371,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 372, method: "level_up", minLevel: 30},],
            baseHp: 45,
            baseAtk: 58,
            baseDef: 45,
            baseSpeed: 50,
            moves: [0, 15, 44, 52, 99, 173, 189, 210, 225, 237, 239, 249, 317, 332, 496, 510]
        }, 372: {
            id: 372,
            name: "shelgon",
            specie: 372,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 373, method: "level_up", minLevel: 50},],
            baseHp: 65,
            baseAtk: 78,
            baseDef: 75,
            baseSpeed: 50,
            moves: [0, 15, 44, 52, 99, 173, 189, 205, 210, 225, 237, 239, 249, 317, 332, 496, 510]
        }, 373: {
            id: 373,
            name: "salamence",
            specie: 373,
            is_default: true,
            type1: "dragon",
            type2: "flying",
            evolutions: [],
            baseHp: 95,
            baseAtk: 123,
            baseDef: 80,
            baseSpeed: 100,
            moves: [0, 15, 44, 52, 99, 129, 173, 189, 205, 210, 225, 237, 239, 249, 314, 317, 332, 466, 496, 510, 523, 525, 693]
        }, 374: {
            id: 374,
            name: "beldum",
            specie: 374,
            is_default: true,
            type1: "steel",
            type2: "psychic",
            evolutions: [{to: 375, method: "level_up", minLevel: 20},],
            baseHp: 40,
            baseAtk: 45,
            baseDef: 70,
            baseSpeed: 30,
            moves: [0]
        }, 375: {
            id: 375,
            name: "metang",
            specie: 375,
            is_default: true,
            type1: "steel",
            type2: "psychic",
            evolutions: [{to: 376, method: "level_up", minLevel: 45},],
            baseHp: 60,
            baseAtk: 65,
            baseDef: 90,
            baseSpeed: 50,
            moves: [0, 15, 93, 129, 173, 189, 196, 205, 210, 228, 232, 237, 249, 317, 332, 418, 496, 523, 612]
        }, 376: {
            id: 376,
            name: "metagross",
            specie: 376,
            is_default: true,
            type1: "steel",
            type2: "psychic",
            evolutions: [],
            baseHp: 80,
            baseAtk: 115,
            baseDef: 110,
            baseSpeed: 70,
            moves: [0, 15, 93, 129, 173, 189, 196, 205, 210, 228, 232, 237, 249, 317, 332, 418, 496, 523, 612]
        }, 377: {
            id: 377,
            name: "regirock",
            specie: 377,
            is_default: true,
            type1: "rock",
            evolutions: [],
            baseHp: 80,
            baseAtk: 75,
            baseDef: 150,
            baseSpeed: 50,
            moves: [0, 88, 173, 189, 205, 237, 246, 249, 317, 351, 451, 479, 496, 523, 612]
        }, 378: {
            id: 378,
            name: "regice",
            specie: 378,
            is_default: true,
            type1: "ice",
            evolutions: [],
            baseHp: 80,
            baseAtk: 75,
            baseDef: 150,
            baseSpeed: 50,
            moves: [0, 173, 189, 196, 205, 237, 246, 249, 317, 351, 419, 451, 496, 523, 524, 612]
        }, 379: {
            id: 379,
            name: "registeel",
            specie: 379,
            is_default: true,
            type1: "steel",
            evolutions: [],
            baseHp: 80,
            baseAtk: 75,
            baseDef: 150,
            baseSpeed: 50,
            moves: [0, 173, 189, 205, 232, 237, 246, 249, 317, 332, 351, 451, 496, 523, 612]
        }, 380: {
            id: 380,
            name: "latias",
            specie: 380,
            is_default: true,
            type1: "dragon",
            type2: "psychic",
            evolutions: [],
            baseHp: 80,
            baseAtk: 95,
            baseDef: 110,
            baseSpeed: 110,
            moves: [0, 15, 129, 173, 189, 196, 210, 225, 237, 239, 250, 332, 343, 351, 352, 451, 496, 500, 523]
        }, 381: {
            id: 381,
            name: "latios",
            specie: 381,
            is_default: true,
            type1: "dragon",
            type2: "psychic",
            evolutions: [],
            baseHp: 80,
            baseAtk: 110,
            baseDef: 95,
            baseSpeed: 110,
            moves: [0, 15, 129, 173, 189, 196, 210, 225, 237, 239, 250, 332, 351, 352, 451, 496, 500, 523]
        }, 382: {
            id: 382,
            name: "kyogre",
            specie: 382,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 100,
            baseAtk: 125,
            baseDef: 115,
            baseSpeed: 90,
            moves: [0, 129, 173, 189, 196, 237, 246, 249, 250, 317, 351, 352, 419, 496, 523]
        }, 383: {
            id: 383,
            name: "groudon",
            specie: 383,
            is_default: true,
            type1: "ground",
            evolutions: [],
            baseHp: 100,
            baseAtk: 125,
            baseDef: 115,
            baseSpeed: 90,
            moves: [0, 15, 129, 173, 189, 205, 210, 237, 246, 249, 317, 332, 341, 351, 479, 496, 510, 523, 525, 612, 693]
        }, 384: {
            id: 384,
            name: "rayquaza",
            specie: 384,
            is_default: true,
            type1: "dragon",
            type2: "flying",
            evolutions: [],
            baseHp: 105,
            baseAtk: 150,
            baseDef: 90,
            baseSpeed: 95,
            moves: [0, 20, 129, 173, 189, 196, 210, 237, 239, 246, 249, 250, 317, 332, 351, 352, 419, 496, 497, 507, 510, 523, 525, 693]
        }, 385: {
            id: 385,
            name: "jirachi",
            specie: 385,
            is_default: true,
            type1: "steel",
            type2: "psychic",
            evolutions: [],
            baseHp: 100,
            baseAtk: 100,
            baseDef: 100,
            baseSpeed: 100,
            moves: [0, 93, 129, 173, 189, 196, 237, 246, 332, 351, 352, 451, 496, 612]
        }, 386: {
            id: 386,
            name: "deoxys-normal",
            specie: 386,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 50,
            baseAtk: 150,
            baseDef: 50,
            baseSpeed: 150,
            moves: [0, 15, 20, 35, 129, 173, 189, 196, 228, 237, 249, 317, 332, 351, 352, 419, 451, 496, 612, 693]
        },
        387: {
            id: 387,
            name: "turtwig",
            specie: 387,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 388, method: "level_up", minLevel: 18},],
            baseHp: 55,
            baseAtk: 57,
            baseDef: 60,
            baseSpeed: 31,
            moves: [0, 15, 33, 44, 71, 72, 75, 173, 189, 237, 249, 328, 331, 496]
        }, 388: {
            id: 388,
            name: "grotle",
            specie: 388,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 389, method: "level_up", minLevel: 32},],
            baseHp: 75,
            baseAtk: 72,
            baseDef: 75,
            baseSpeed: 36,
            moves: [0, 15, 33, 44, 71, 72, 75, 173, 189, 237, 249, 331, 496]
        }, 389: {
            id: 389,
            name: "torterra",
            specie: 389,
            is_default: true,
            type1: "grass",
            type2: "ground",
            evolutions: [],
            baseHp: 95,
            baseAtk: 92,
            baseDef: 95,
            baseSpeed: 56,
            moves: [0, 15, 33, 44, 71, 72, 75, 173, 189, 237, 249, 317, 331, 496, 523]
        }, 390: {
            id: 390,
            name: "chimchar",
            specie: 390,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 391, method: "level_up", minLevel: 14},],
            baseHp: 44,
            baseAtk: 58,
            baseDef: 44,
            baseSpeed: 61,
            moves: [0, 10, 15, 24, 52, 83, 129, 154, 172, 173, 189, 205, 237, 249, 252, 332, 343, 410, 488, 496, 510, 512, 612]
        }, 391: {
            id: 391,
            name: "monferno",
            specie: 391,
            is_default: true,
            type1: "fire",
            type2: "fighting",
            evolutions: [{to: 392, method: "level_up", minLevel: 36},],
            baseHp: 64,
            baseAtk: 78,
            baseDef: 52,
            baseSpeed: 81,
            moves: [0, 10, 15, 52, 83, 129, 154, 172, 173, 183, 189, 205, 237, 249, 317, 332, 343, 364, 410, 488, 496, 510, 512, 530, 612]
        }, 392: {
            id: 392,
            name: "infernape",
            specie: 392,
            is_default: true,
            type1: "fire",
            type2: "fighting",
            evolutions: [],
            baseHp: 76,
            baseAtk: 104,
            baseDef: 71,
            baseSpeed: 108,
            moves: [0, 10, 15, 52, 83, 129, 154, 172, 173, 183, 189, 205, 237, 249, 317, 332, 343, 364, 410, 488, 496, 510, 512, 523, 530, 612]
        }, 393: {
            id: 393,
            name: "piplup",
            specie: 393,
            is_default: true,
            type1: "water",
            evolutions: [{to: 394, method: "level_up", minLevel: 16},],
            baseHp: 53,
            baseAtk: 56,
            baseDef: 55,
            baseSpeed: 40,
            moves: [0, 1, 15, 31, 64, 145, 173, 189, 196, 237, 250, 317, 332, 343, 352, 365, 458, 496, 497, 681]
        }, 394: {
            id: 394,
            name: "prinplup",
            specie: 394,
            is_default: true,
            type1: "water",
            evolutions: [{to: 395, method: "level_up", minLevel: 36},],
            baseHp: 64,
            baseAtk: 74,
            baseDef: 72,
            baseSpeed: 50,
            moves: [0, 15, 31, 33, 64, 145, 173, 189, 196, 232, 237, 249, 250, 317, 332, 343, 352, 365, 496, 497]
        }, 395: {
            id: 395,
            name: "empoleon",
            specie: 395,
            is_default: true,
            type1: "water",
            type2: "steel",
            evolutions: [],
            baseHp: 84,
            baseAtk: 99,
            baseDef: 95,
            baseSpeed: 60,
            moves: [0, 15, 31, 33, 64, 145, 173, 189, 196, 210, 232, 237, 249, 250, 317, 332, 343, 352, 365, 419, 453, 496, 497, 523]
        }, 396: {
            id: 396,
            name: "starly",
            specie: 396,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 397, method: "level_up", minLevel: 14},],
            baseHp: 40,
            baseAtk: 43,
            baseDef: 30,
            baseSpeed: 60,
            moves: [0, 17, 31, 33, 98, 129, 168, 173, 189, 228, 237, 239, 279, 310, 314, 332, 365, 466, 496, 497]
        }, 397: {
            id: 397,
            name: "staravia",
            specie: 397,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 398, method: "level_up", minLevel: 34},],
            baseHp: 55,
            baseAtk: 58,
            baseDef: 45,
            baseSpeed: 80,
            moves: [0, 17, 33, 98, 129, 168, 173, 189, 237, 239, 314, 332, 365, 466, 496, 497]
        }, 398: {
            id: 398,
            name: "staraptor",
            specie: 398,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 85,
            baseAtk: 85,
            baseDef: 65,
            baseSpeed: 100,
            moves: [0, 17, 33, 98, 129, 168, 173, 189, 237, 239, 314, 332, 365, 466, 496, 497]
        }, 399: {
            id: 399,
            name: "bidoof",
            specie: 399,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 400, method: "level_up", minLevel: 15},],
            baseHp: 59,
            baseAtk: 40,
            baseDef: 40,
            baseSpeed: 31,
            moves: [0, 15, 33, 98, 129, 154, 168, 173, 189, 196, 205, 210, 237, 249, 343, 351, 365, 451, 496, 497]
        }, 400: {
            id: 400,
            name: "bibarel",
            specie: 400,
            is_default: true,
            type1: "normal",
            type2: "water",
            evolutions: [],
            baseHp: 79,
            baseAtk: 70,
            baseDef: 60,
            baseSpeed: 71,
            moves: [0, 15, 33, 55, 129, 168, 173, 189, 196, 205, 210, 237, 249, 250, 343, 351, 352, 365, 451, 453, 496, 497, 523]
        }, 401: {
            id: 401,
            name: "kricketot",
            specie: 401,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 402, method: "level_up", minLevel: 10},],
            baseHp: 37,
            baseAtk: 25,
            baseDef: 41,
            baseSpeed: 25,
            moves: [0, 173, 189, 450, 522]
        }, 402: {
            id: 402,
            name: "kricketune",
            specie: 402,
            is_default: true,
            type1: "bug",
            evolutions: [],
            baseHp: 77,
            baseAtk: 70,
            baseDef: 51,
            baseSpeed: 65,
            moves: [0, 15, 71, 173, 189, 206, 210, 237, 249, 318, 332, 450, 496, 497, 522, 565, 611, 612]
        }, 403: {
            id: 403,
            name: "shinx",
            specie: 403,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 404, method: "level_up", minLevel: 15},],
            baseHp: 45,
            baseAtk: 53,
            baseDef: 34,
            baseSpeed: 45,
            moves: [0, 24, 33, 44, 98, 129, 168, 173, 189, 210, 237, 351, 451, 496, 555]
        }, 404: {
            id: 404,
            name: "luxio",
            specie: 404,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 405, method: "level_up", minLevel: 30},],
            baseHp: 60,
            baseAtk: 73,
            baseDef: 49,
            baseSpeed: 60,
            moves: [0, 33, 44, 129, 168, 173, 189, 210, 237, 351, 451, 496, 555]
        }, 405: {
            id: 405,
            name: "luxray",
            specie: 405,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 80,
            baseAtk: 108,
            baseDef: 79,
            baseSpeed: 70,
            moves: [0, 33, 44, 129, 168, 173, 189, 210, 237, 351, 451, 496, 555]
        }, 406: {
            id: 406,
            name: "budew",
            specie: 406,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 315, method: "level_up", minLevel: 32},],
            baseHp: 40,
            baseAtk: 40,
            baseDef: 53,
            baseSpeed: 55,
            moves: [0, 15, 42, 71, 72, 75, 129, 173, 189, 237, 331, 343, 496]
        }, 407: {
            id: 407,
            name: "roserade",
            specie: 407,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [],
            baseHp: 60,
            baseAtk: 98,
            baseDef: 85,
            baseSpeed: 90,
            moves: [0, 15, 40, 72, 129, 173, 189, 210, 237, 311, 331, 343, 345, 496]
        }, 408: {
            id: 408,
            name: "cranidos",
            specie: 408,
            is_default: true,
            type1: "rock",
            evolutions: [{to: 409, method: "level_up", minLevel: 30},],
            baseHp: 67,
            baseAtk: 78,
            baseDef: 35,
            baseSpeed: 58,
            moves: [0, 168, 173, 189, 228, 237, 246, 249, 317, 351, 371, 372, 479, 496, 510, 523, 612]
        }, 409: {
            id: 409,
            name: "rampardos",
            specie: 409,
            is_default: true,
            type1: "rock",
            evolutions: [],
            baseHp: 97,
            baseAtk: 115,
            baseDef: 55,
            baseSpeed: 58,
            moves: [0, 15, 168, 173, 189, 228, 237, 246, 249, 250, 317, 351, 371, 372, 419, 479, 496, 510, 523, 525, 612]
        }, 410: {
            id: 410,
            name: "shieldon",
            specie: 410,
            is_default: true,
            type1: "rock",
            type2: "steel",
            evolutions: [{to: 411, method: "level_up", minLevel: 30},],
            baseHp: 30,
            baseAtk: 42,
            baseDef: 103,
            baseSpeed: 30,
            moves: [0, 33, 173, 189, 237, 246, 249, 317, 350, 351, 479, 496, 510, 523]
        }, 411: {
            id: 411,
            name: "bastiodon",
            specie: 411,
            is_default: true,
            type1: "rock",
            type2: "steel",
            evolutions: [],
            baseHp: 60,
            baseAtk: 50,
            baseDef: 153,
            baseSpeed: 30,
            moves: [0, 33, 173, 189, 237, 246, 249, 317, 351, 419, 479, 496, 510, 523]
        }, 412: {
            id: 412,
            name: "burmy",
            specie: 412,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 413, method: "level_up", minLevel: 20}, {to: 414, method: "level_up", minLevel: 20},],
            baseHp: 40,
            baseAtk: 29,
            baseDef: 45,
            baseSpeed: 36,
            moves: [0, 33, 173, 237, 450, 527]
        }, 413: {
            id: 413,
            name: "wormadam-plant",
            specie: 413,
            is_default: true,
            type1: "bug",
            type2: "grass",
            evolutions: [],
            baseHp: 60,
            baseAtk: 69,
            baseDef: 95,
            baseSpeed: 36,
            moves: [0, 33, 75, 93, 168, 173, 237, 331, 450, 496, 522, 527, 611]
        }, 414: {
            id: 414,
            name: "mothim",
            specie: 414,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 70,
            baseAtk: 94,
            baseDef: 50,
            baseSpeed: 66,
            moves: [0, 16, 33, 93, 129, 168, 173, 189, 237, 239, 314, 318, 332, 450, 466, 496, 512, 522, 527, 611]
        }, 415: {
            id: 415,
            name: "combee",
            specie: 415,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [{to: 416, method: "level_up", minLevel: 21},],
            baseHp: 30,
            baseAtk: 30,
            baseDef: 42,
            baseSpeed: 70,
            moves: [0, 16, 129, 173, 189, 314, 450, 466]
        }, 416: {
            id: 416,
            name: "vespiquen",
            specie: 416,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 70,
            baseAtk: 80,
            baseDef: 102,
            baseSpeed: 40,
            moves: [0, 15, 16, 40, 129, 154, 168, 173, 189, 210, 228, 237, 314, 318, 332, 450, 466, 496, 512, 522, 565, 611]
        }, 417: {
            id: 417,
            name: "pachirisu",
            specie: 417,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 60,
            baseAtk: 45,
            baseDef: 80,
            baseSpeed: 95,
            moves: [0, 15, 44, 98, 129, 173, 189, 205, 237, 343, 351, 451, 496, 497, 527, 609]
        }, 418: {
            id: 418,
            name: "buizel",
            specie: 418,
            is_default: true,
            type1: "water",
            evolutions: [{to: 419, method: "level_up", minLevel: 26},],
            baseHp: 55,
            baseAtk: 63,
            baseDef: 33,
            baseSpeed: 85,
            moves: [0, 3, 55, 98, 129, 154, 173, 189, 196, 210, 228, 237, 249, 250, 317, 352, 453, 458, 496, 497, 541, 612]
        }, 419: {
            id: 419,
            name: "floatzel",
            specie: 419,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 85,
            baseAtk: 95,
            baseDef: 53,
            baseSpeed: 115,
            moves: [0, 55, 98, 129, 173, 189, 196, 228, 237, 249, 250, 317, 352, 371, 453, 458, 496, 497, 612]
        }, 420: {
            id: 420,
            name: "cherubi",
            specie: 420,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 421, method: "level_up", minLevel: 25},],
            baseHp: 45,
            baseAtk: 49,
            baseDef: 49,
            baseSpeed: 35,
            moves: [0, 33, 75, 173, 205, 237, 311, 331, 345, 496]
        }, 421: {
            id: 421, name: "cherrim", specie: 421, is_default: true, type1: "grass", evolutions: [],
            baseHp: 70, baseAtk: 74, baseDef: 74, baseSpeed: 85, moves: [0, 33, 173, 205, 237, 331, 345, 496]
        }, 422: {
            id: 422,
            name: "shellos",
            specie: 422,
            is_default: true,
            type1: "water",
            evolutions: [{to: 423, method: "level_up", minLevel: 30},],
            baseHp: 76,
            baseAtk: 53,
            baseDef: 55,
            baseSpeed: 34,
            moves: [0, 173, 189, 196, 237, 246, 250, 352, 496, 499, 611]
        }, 423: {
            id: 423,
            name: "gastrodon",
            specie: 423,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [],
            baseHp: 111,
            baseAtk: 88,
            baseDef: 75,
            baseSpeed: 39,
            moves: [0, 173, 189, 196, 237, 246, 249, 250, 317, 352, 496, 523, 611]
        }, 424: {
            id: 424,
            name: "ambipom",
            specie: 424,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 75,
            baseAtk: 80,
            baseDef: 66,
            baseSpeed: 115,
            moves: [0, 10, 15, 129, 154, 168, 173, 189, 210, 237, 249, 310, 332, 343, 351, 352, 371, 458, 496, 512, 530, 612]
        }, 425: {
            id: 425,
            name: "drifloon",
            specie: 425,
            is_default: true,
            type1: "ghost",
            type2: "flying",
            evolutions: [{to: 426, method: "level_up", minLevel: 28},],
            baseHp: 90,
            baseAtk: 55,
            baseDef: 39,
            baseSpeed: 70,
            moves: [0, 15, 16, 20, 129, 132, 168, 173, 189, 196, 205, 237, 310, 311, 314, 318, 351, 371, 451, 466, 496, 499, 512]
        }, 426: {
            id: 426,
            name: "drifblim",
            specie: 426,
            is_default: true,
            type1: "ghost",
            type2: "flying",
            evolutions: [],
            baseHp: 150,
            baseAtk: 85,
            baseDef: 49,
            baseSpeed: 80,
            moves: [0, 15, 16, 20, 129, 132, 168, 173, 189, 196, 205, 237, 310, 314, 318, 351, 371, 451, 466, 496, 512]
        }, 427: {
            id: 427,
            name: "buneary",
            specie: 427,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 428, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 55,
            baseDef: 50,
            baseSpeed: 85,
            moves: [0, 1, 15, 98, 129, 173, 189, 237, 249, 252, 343, 351, 352, 451, 458, 496, 509, 612]
        }, 428: {
            id: 428,
            name: "lopunny",
            specie: 428,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 65,
            baseAtk: 65,
            baseDef: 90,
            baseSpeed: 105,
            moves: [0, 1, 15, 98, 129, 173, 189, 210, 237, 249, 343, 351, 352, 451, 496, 612, 693]
        }, 429: {
            id: 429,
            name: "mismagius",
            specie: 429,
            is_default: true,
            type1: "ghost",
            evolutions: [],
            baseHp: 60,
            baseAtk: 83,
            baseDef: 83,
            baseSpeed: 105,
            moves: [0, 129, 168, 173, 196, 237, 310, 332, 345, 351, 371, 451, 466, 496, 497]
        }, 430: {
            id: 430,
            name: "honchkrow",
            specie: 430,
            is_default: true,
            type1: "dark",
            type2: "flying",
            evolutions: [],
            baseHp: 100,
            baseAtk: 115,
            baseDef: 52,
            baseSpeed: 71,
            moves: [0, 17, 129, 168, 173, 189, 196, 228, 237, 239, 310, 314, 332, 365, 371, 466, 496, 510, 555]
        }, 431: {
            id: 431,
            name: "glameow",
            specie: 431,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 432, method: "level_up", minLevel: 38},],
            baseHp: 49,
            baseAtk: 49,
            baseDef: 40,
            baseSpeed: 85,
            moves: [0, 10, 15, 44, 98, 129, 154, 168, 173, 185, 189, 210, 237, 252, 332, 343, 351, 352, 371, 372, 496, 497]
        }, 432: {
            id: 432,
            name: "purugly",
            specie: 432,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 71,
            baseAtk: 73,
            baseDef: 62,
            baseSpeed: 112,
            moves: [0, 10, 15, 129, 154, 168, 173, 185, 189, 205, 210, 237, 252, 332, 343, 351, 352, 371, 496, 497, 523]
        }, 433: {
            id: 433,
            name: "chingling",
            specie: 433,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 358, method: "level_up", minLevel: 32},],
            baseHp: 45,
            baseAtk: 48,
            baseDef: 50,
            baseSpeed: 45,
            moves: [0, 20, 35, 93, 129, 173, 196, 205, 237, 310, 351, 451, 496, 497, 500]
        }, 434: {
            id: 434,
            name: "stunky",
            specie: 434,
            is_default: true,
            type1: "poison",
            type2: "dark",
            evolutions: [{to: 435, method: "level_up", minLevel: 34},],
            baseHp: 63,
            baseAtk: 52,
            baseDef: 44,
            baseSpeed: 74,
            moves: [0, 10, 15, 44, 123, 129, 154, 168, 173, 189, 210, 228, 237, 249, 310, 364, 371, 491, 496, 510, 555]
        }, 435: {
            id: 435,
            name: "skuntank",
            specie: 435,
            is_default: true,
            type1: "poison",
            type2: "dark",
            evolutions: [],
            baseHp: 103,
            baseAtk: 82,
            baseDef: 64,
            baseSpeed: 84,
            moves: [0, 10, 15, 44, 129, 154, 168, 173, 189, 210, 237, 249, 364, 371, 491, 496, 510, 555]
        }, 436: {
            id: 436,
            name: "bronzor",
            specie: 436,
            is_default: true,
            type1: "steel",
            type2: "psychic",
            evolutions: [{to: 437, method: "level_up", minLevel: 33},],
            baseHp: 57,
            baseAtk: 24,
            baseDef: 86,
            baseSpeed: 23,
            moves: [0, 33, 93, 173, 185, 205, 237, 246, 317, 371, 451, 496, 523]
        }, 437: {
            id: 437,
            name: "bronzong",
            specie: 437,
            is_default: true,
            type1: "steel",
            type2: "psychic",
            evolutions: [],
            baseHp: 67,
            baseAtk: 84,
            baseDef: 116,
            baseSpeed: 33,
            moves: [0, 33, 93, 173, 185, 205, 237, 246, 249, 317, 371, 451, 496, 523]
        }, 438: {
            id: 438,
            name: "bonsly",
            specie: 438,
            is_default: true,
            type1: "rock",
            evolutions: [{to: 185, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 45,
            baseDef: 70,
            baseSpeed: 10,
            moves: [0, 88, 168, 173, 185, 205, 237, 317, 328, 343, 479, 496]
        }, 439: {
            id: 439,
            name: "mime-jr",
            specie: 439,
            is_default: true,
            type1: "psychic",
            type2: "fairy",
            evolutions: [{to: 122, method: "level_up", minLevel: 32},],
            baseHp: 20,
            baseAtk: 48,
            baseDef: 68,
            baseSpeed: 60,
            moves: [0, 1, 3, 93, 168, 173, 189, 196, 237, 252, 343, 351, 451, 496, 611]
        }, 440: {
            id: 440,
            name: "happiny",
            specie: 440,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 113, method: "level_up", minLevel: 32},],
            baseHp: 100,
            baseAtk: 10,
            baseDef: 35,
            baseSpeed: 30,
            moves: [0, 1, 173, 189, 196, 205, 237, 343, 351, 352, 496, 497, 510]
        }, 441: {
            id: 441,
            name: "chatot",
            specie: 441,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 76,
            baseAtk: 79,
            baseDef: 44,
            baseSpeed: 91,
            moves: [0, 31, 64, 129, 168, 173, 189, 237, 239, 314, 332, 365, 466, 496, 497]
        }, 442: {
            id: 442,
            name: "spiritomb",
            specie: 442,
            is_default: true,
            type1: "ghost",
            type2: "dark",
            evolutions: [],
            baseHp: 50,
            baseAtk: 92,
            baseDef: 108,
            baseSpeed: 35,
            moves: [0, 168, 173, 185, 196, 228, 237, 317, 318, 351, 352, 425, 466, 496, 555, 611]
        }, 443: {
            id: 443,
            name: "gible",
            specie: 443,
            is_default: true,
            type1: "dragon",
            type2: "ground",
            evolutions: [{to: 444, method: "level_up", minLevel: 24},],
            baseHp: 58,
            baseAtk: 55,
            baseDef: 45,
            baseSpeed: 42,
            moves: [0, 15, 33, 129, 173, 189, 210, 225, 232, 237, 239, 249, 317, 328, 332, 341, 496, 510, 523]
        }, 444: {
            id: 444,
            name: "gabite",
            specie: 444,
            is_default: true,
            type1: "dragon",
            type2: "ground",
            evolutions: [{to: 445, method: "level_up", minLevel: 48},],
            baseHp: 68,
            baseAtk: 70,
            baseDef: 60,
            baseSpeed: 82,
            moves: [0, 15, 33, 129, 173, 189, 210, 237, 239, 249, 317, 328, 332, 496, 510, 523, 530]
        }, 445: {
            id: 445,
            name: "garchomp",
            specie: 445,
            is_default: true,
            type1: "dragon",
            type2: "ground",
            evolutions: [],
            baseHp: 108,
            baseAtk: 105,
            baseDef: 90,
            baseSpeed: 102,
            moves: [0, 15, 33, 129, 173, 189, 206, 210, 237, 239, 249, 250, 317, 328, 332, 496, 510, 523, 525, 530, 693]
        }, 446: {
            id: 446,
            name: "munchlax",
            specie: 446,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 143, method: "level_up", minLevel: 32},],
            baseHp: 135,
            baseAtk: 63,
            baseDef: 63,
            baseSpeed: 5,
            moves: [0, 33, 122, 173, 189, 196, 205, 228, 237, 249, 250, 317, 343, 351, 352, 496, 510, 523, 612]
        }, 447: {
            id: 447,
            name: "riolu",
            specie: 447,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 448, method: "level_up", minLevel: 32},],
            baseHp: 40,
            baseAtk: 53,
            baseDef: 40,
            baseSpeed: 60,
            moves: [0, 44, 98, 129, 173, 189, 210, 237, 249, 317, 364, 371, 395, 410, 418, 496, 509, 523, 530, 612]
        }, 448: {
            id: 448,
            name: "lucario",
            specie: 448,
            is_default: true,
            type1: "fighting",
            type2: "steel",
            evolutions: [],
            baseHp: 70,
            baseAtk: 113,
            baseDef: 70,
            baseSpeed: 90,
            moves: [0, 98, 129, 173, 189, 198, 210, 232, 237, 249, 317, 352, 364, 371, 395, 410, 496, 523, 530, 612]
        }, 449: {
            id: 449,
            name: "hippopotas",
            specie: 449,
            is_default: true,
            type1: "ground",
            evolutions: [{to: 450, method: "level_up", minLevel: 34},],
            baseHp: 68,
            baseAtk: 55,
            baseDef: 60,
            baseSpeed: 32,
            moves: [0, 33, 44, 173, 189, 237, 249, 279, 317, 328, 352, 496, 523]
        }, 450: {
            id: 450,
            name: "hippowdon",
            specie: 450,
            is_default: true,
            type1: "ground",
            evolutions: [],
            baseHp: 108,
            baseAtk: 90,
            baseDef: 95,
            baseSpeed: 47,
            moves: [0, 33, 44, 173, 189, 237, 249, 317, 328, 352, 496, 523]
        }, 451: {
            id: 451,
            name: "skorupi",
            specie: 451,
            is_default: true,
            type1: "poison",
            type2: "bug",
            evolutions: [{to: 452, method: "level_up", minLevel: 40},],
            baseHp: 40,
            baseAtk: 40,
            baseDef: 73,
            baseSpeed: 65,
            moves: [0, 15, 40, 41, 42, 44, 168, 173, 185, 189, 206, 210, 228, 237, 249, 305, 317, 332, 342, 371, 450, 496, 522, 565, 611]
        }, 452: {
            id: 452,
            name: "drapion",
            specie: 452,
            is_default: true,
            type1: "poison",
            type2: "dark",
            evolutions: [],
            baseHp: 70,
            baseAtk: 75,
            baseDef: 93,
            baseSpeed: 95,
            moves: [0, 15, 40, 42, 44, 168, 173, 189, 206, 210, 228, 237, 249, 305, 317, 332, 371, 450, 496, 522, 523, 555, 565, 611, 693]
        }, 453: {
            id: 453,
            name: "croagunk",
            specie: 453,
            is_default: true,
            type1: "poison",
            type2: "fighting",
            evolutions: [{to: 454, method: "level_up", minLevel: 37},],
            baseHp: 48,
            baseAtk: 61,
            baseDef: 40,
            baseSpeed: 50,
            moves: [0, 40, 168, 173, 185, 189, 196, 210, 228, 237, 249, 252, 279, 310, 317, 364, 371, 410, 418, 496, 523, 530, 612]
        }, 454: {
            id: 454,
            name: "toxicroak",
            specie: 454,
            is_default: true,
            type1: "poison",
            type2: "fighting",
            evolutions: [],
            baseHp: 83,
            baseAtk: 96,
            baseDef: 65,
            baseSpeed: 85,
            moves: [0, 15, 40, 168, 173, 185, 189, 196, 210, 228, 237, 249, 279, 310, 317, 371, 410, 496, 523, 530, 612]
        }, 455: {
            id: 455,
            name: "carnivine",
            specie: 455,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 74,
            baseAtk: 95,
            baseDef: 72,
            baseSpeed: 46,
            moves: [0, 15, 20, 22, 44, 75, 168, 173, 185, 189, 210, 237, 331, 345, 371, 450, 491, 496, 611]
        }, 456: {
            id: 456,
            name: "finneon",
            specie: 456,
            is_default: true,
            type1: "water",
            evolutions: [{to: 457, method: "level_up", minLevel: 31},],
            baseHp: 49,
            baseAtk: 49,
            baseDef: 59,
            baseSpeed: 66,
            moves: [0, 1, 16, 55, 129, 173, 196, 237, 239, 250, 314, 318, 352, 371, 466, 496]
        }, 457: {
            id: 457,
            name: "lumineon",
            specie: 457,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 69,
            baseAtk: 69,
            baseDef: 81,
            baseSpeed: 91,
            moves: [0, 1, 16, 55, 129, 173, 196, 237, 239, 250, 314, 318, 352, 371, 466, 496]
        }, 458: {
            id: 458,
            name: "mantyke",
            specie: 458,
            is_default: true,
            type1: "water",
            type2: "flying",
            evolutions: [{to: 226, method: "level_up", minLevel: 32},],
            baseHp: 45,
            baseAtk: 40,
            baseDef: 85,
            baseSpeed: 50,
            moves: [0, 17, 33, 129, 145, 173, 189, 196, 237, 239, 250, 314, 332, 352, 496, 512, 523]
        }, 459: {
            id: 459,
            name: "snover",
            specie: 459,
            is_default: true,
            type1: "grass",
            type2: "ice",
            evolutions: [{to: 460, method: "level_up", minLevel: 40},],
            baseHp: 60,
            baseAtk: 62,
            baseDef: 55,
            baseSpeed: 40,
            moves: [0, 75, 173, 181, 189, 196, 237, 331, 345, 352, 419, 420, 496, 524]
        }, 460: {
            id: 460,
            name: "abomasnow",
            specie: 460,
            is_default: true,
            type1: "grass",
            type2: "ice",
            evolutions: [],
            baseHp: 90,
            baseAtk: 92,
            baseDef: 80,
            baseSpeed: 60,
            moves: [0, 75, 173, 181, 189, 196, 237, 249, 317, 331, 352, 419, 420, 496, 523, 524]
        }, 461: {
            id: 461,
            name: "weavile",
            specie: 461,
            is_default: true,
            type1: "dark",
            type2: "ice",
            evolutions: [],
            baseHp: 70,
            baseAtk: 83,
            baseDef: 75,
            baseSpeed: 125,
            moves: [0, 10, 15, 98, 129, 154, 168, 173, 185, 189, 196, 206, 210, 232, 237, 249, 250, 279, 332, 371, 372, 419, 496, 555, 612]
        }, 462: {
            id: 462,
            name: "magnezone",
            specie: 462,
            is_default: true,
            type1: "electric",
            type2: "steel",
            evolutions: [],
            baseHp: 70,
            baseAtk: 100,
            baseDef: 103,
            baseSpeed: 60,
            moves: [0, 33, 84, 129, 173, 205, 237, 351, 443, 451, 496, 527]
        }, 463: {
            id: 463,
            name: "lickilicky",
            specie: 463,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 110,
            baseAtk: 83,
            baseDef: 95,
            baseSpeed: 50,
            moves: [0, 15, 20, 35, 122, 168, 173, 189, 196, 205, 237, 249, 250, 317, 351, 352, 496, 510, 523, 525, 612, 693]
        }, 464: {
            id: 464,
            name: "rhyperior",
            specie: 464,
            is_default: true,
            type1: "ground",
            type2: "rock",
            evolutions: [],
            baseHp: 115,
            baseAtk: 98,
            baseDef: 93,
            baseSpeed: 40,
            moves: [0, 15, 31, 168, 173, 189, 196, 205, 210, 237, 246, 249, 250, 317, 350, 351, 371, 419, 479, 496, 510, 523, 525, 612, 693]
        }, 465: {
            id: 465,
            name: "tangrowth",
            specie: 465,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 100,
            baseAtk: 105,
            baseDef: 88,
            baseSpeed: 50,
            moves: [0, 15, 20, 22, 71, 72, 132, 168, 173, 189, 237, 246, 249, 317, 331, 332, 351, 371, 496, 523, 611]
        }, 466: {
            id: 466,
            name: "electivire",
            specie: 466,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 75,
            baseAtk: 109,
            baseDef: 76,
            baseSpeed: 95,
            moves: [0, 84, 98, 129, 168, 173, 189, 237, 249, 317, 343, 351, 451, 496, 523, 527, 530, 612]
        }, 467: {
            id: 467,
            name: "magmortar",
            specie: 467,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 75,
            baseAtk: 110,
            baseDef: 81,
            baseSpeed: 83,
            moves: [0, 52, 83, 123, 168, 173, 185, 189, 237, 249, 317, 343, 488, 496, 499, 510, 523, 530, 612]
        }, 468: {
            id: 468,
            name: "togekiss",
            specie: 468,
            is_default: true,
            type1: "fairy",
            type2: "flying",
            evolutions: [],
            baseHp: 85,
            baseAtk: 85,
            baseDef: 105,
            baseSpeed: 80,
            moves: [0, 129, 173, 189, 205, 237, 239, 246, 249, 314, 318, 332, 343, 351, 352, 365, 466, 496, 497, 510]
        }, 469: {
            id: 469,
            name: "yanmega",
            specie: 469,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 86,
            baseAtk: 96,
            baseDef: 71,
            baseSpeed: 95,
            moves: [0, 33, 98, 129, 168, 173, 189, 228, 237, 246, 314, 318, 332, 364, 450, 466, 496, 522]
        }, 470: {
            id: 470,
            name: "leafeon",
            specie: 470,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 65,
            baseAtk: 85,
            baseDef: 98,
            baseSpeed: 95,
            moves: [0, 33, 75, 98, 129, 173, 189, 210, 237, 249, 331, 332, 343, 345, 496, 497]
        }, 471: {
            id: 471,
            name: "glaceon",
            specie: 471,
            is_default: true,
            type1: "ice",
            evolutions: [],
            baseHp: 65,
            baseAtk: 95,
            baseDef: 103,
            baseSpeed: 65,
            moves: [0, 33, 44, 98, 129, 173, 189, 196, 237, 249, 343, 352, 419, 420, 496, 497, 524]
        }, 472: {
            id: 472,
            name: "gliscor",
            specie: 472,
            is_default: true,
            type1: "ground",
            type2: "flying",
            evolutions: [],
            baseHp: 75,
            baseAtk: 70,
            baseDef: 100,
            baseSpeed: 95,
            moves: [0, 15, 98, 129, 168, 173, 185, 189, 206, 210, 237, 249, 317, 332, 371, 450, 496, 512, 522, 523, 693]
        }, 473: {
            id: 473,
            name: "mamoswine",
            specie: 473,
            is_default: true,
            type1: "ice",
            type2: "ground",
            evolutions: [],
            baseHp: 110,
            baseAtk: 100,
            baseDef: 70,
            baseSpeed: 80,
            moves: [0, 31, 64, 173, 181, 189, 196, 210, 237, 246, 249, 317, 419, 458, 496, 523]
        }, 474: {
            id: 474,
            name: "porygon-z",
            specie: 474,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 85,
            baseAtk: 108,
            baseDef: 73,
            baseSpeed: 90,
            moves: [0, 33, 129, 168, 173, 196, 237, 332, 351, 451, 496, 527]
        }, 475: {
            id: 475,
            name: "gallade",
            specie: 475,
            is_default: true,
            type1: "psychic",
            type2: "fighting",
            evolutions: [],
            baseHp: 68,
            baseAtk: 95,
            baseDef: 90,
            baseSpeed: 80,
            moves: [0, 15, 93, 129, 168, 173, 189, 206, 210, 237, 249, 317, 332, 351, 364, 410, 451, 496, 497, 500, 523, 530, 612]
        }, 476: {
            id: 476,
            name: "probopass",
            specie: 476,
            is_default: true,
            type1: "rock",
            type2: "steel",
            evolutions: [],
            baseHp: 60,
            baseAtk: 65,
            baseDef: 148,
            baseSpeed: 40,
            moves: [0, 33, 173, 189, 205, 237, 246, 249, 317, 350, 351, 443, 479, 496, 523]
        }, 477: {
            id: 477,
            name: "dusknoir",
            specie: 477,
            is_default: true,
            type1: "ghost",
            evolutions: [],
            baseHp: 45,
            baseAtk: 83,
            baseDef: 135,
            baseSpeed: 45,
            moves: [0, 20, 168, 173, 189, 196, 228, 237, 249, 310, 317, 325, 371, 425, 451, 466, 496, 523, 611, 612]
        }, 478: {
            id: 478,
            name: "froslass",
            specie: 478,
            is_default: true,
            type1: "ice",
            type2: "ghost",
            evolutions: [],
            baseHp: 70,
            baseAtk: 80,
            baseDef: 70,
            baseSpeed: 110,
            moves: [0, 173, 181, 189, 196, 205, 237, 310, 351, 352, 371, 419, 420, 466, 496, 524, 577]
        }, 479: {
            id: 479,
            name: "rotom",
            specie: 479,
            is_default: true,
            type1: "electric",
            type2: "ghost",
            evolutions: [],
            baseHp: 50,
            baseAtk: 73,
            baseDef: 77,
            baseSpeed: 91,
            moves: [0, 84, 129, 168, 173, 189, 237, 310, 351, 451, 466, 496, 527]
        }, 480: {
            id: 480,
            name: "uxie",
            specie: 480,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 75,
            baseAtk: 75,
            baseDef: 130,
            baseSpeed: 95,
            moves: [0, 93, 129, 173, 189, 237, 351, 352, 451, 496, 512, 612]
        }, 481: {
            id: 481,
            name: "mesprit",
            specie: 481,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 80,
            baseAtk: 105,
            baseDef: 105,
            baseSpeed: 80,
            moves: [0, 93, 129, 173, 189, 237, 351, 352, 451, 496, 512, 612]
        }, 482: {
            id: 482,
            name: "azelf",
            specie: 482,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 75,
            baseAtk: 125,
            baseDef: 70,
            baseSpeed: 115,
            moves: [0, 93, 129, 173, 189, 237, 351, 352, 371, 451, 496, 510, 512, 612]
        }, 483: {
            id: 483,
            name: "dialga",
            specie: 483,
            is_default: true,
            type1: "steel",
            type2: "dragon",
            evolutions: [],
            baseHp: 100,
            baseAtk: 135,
            baseDef: 110,
            baseSpeed: 90,
            moves: [0, 15, 129, 173, 189, 210, 225, 232, 237, 239, 246, 249, 317, 332, 351, 496, 497, 510, 523, 525]
        }, 484: {
            id: 484,
            name: "palkia",
            specie: 484,
            is_default: true,
            type1: "water",
            type2: "dragon",
            evolutions: [],
            baseHp: 90,
            baseAtk: 135,
            baseDef: 110,
            baseSpeed: 100,
            moves: [0, 15, 129, 173, 189, 210, 225, 237, 239, 246, 249, 250, 317, 332, 351, 352, 419, 496, 497, 510, 523, 525]
        }, 485: {
            id: 485,
            name: "heatran",
            specie: 485,
            is_default: true,
            type1: "fire",
            type2: "steel",
            evolutions: [],
            baseHp: 91,
            baseAtk: 110,
            baseDef: 106,
            baseSpeed: 77,
            moves: [0, 83, 173, 189, 237, 246, 249, 317, 371, 450, 488, 496, 510, 523]
        }, 486: {
            id: 486,
            name: "regigigas",
            specie: 486,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 110,
            baseAtk: 120,
            baseDef: 110,
            baseSpeed: 100,
            moves: [0, 173, 189, 196, 237, 246, 249, 279, 317, 332, 351, 371, 419, 479, 496, 523, 612]
        }, 487: {
            id: 487,
            name: "giratina-altered",
            specie: 487,
            is_default: true,
            type1: "ghost",
            type2: "dragon",
            evolutions: [],
            baseHp: 150,
            baseAtk: 100,
            baseDef: 120,
            baseSpeed: 90,
            moves: [0, 15, 129, 173, 189, 196, 210, 225, 237, 239, 246, 249, 314, 318, 332, 351, 371, 425, 451, 466, 496, 497, 523, 525, 693]
        }, 488: {
            id: 488,
            name: "cresselia",
            specie: 488,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 120,
            baseAtk: 73,
            baseDef: 125,
            baseSpeed: 85,
            moves: [0, 93, 129, 173, 189, 196, 210, 237, 451, 496]
        }, 489: {
            id: 489,
            name: "phione",
            specie: 489,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 80,
            baseAtk: 80,
            baseDef: 80,
            baseSpeed: 80,
            moves: [0, 129, 145, 173, 189, 196, 237, 246, 250, 343, 352, 496]
        }, 490: {
            id: 490,
            name: "manaphy",
            specie: 490,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 100,
            baseAtk: 100,
            baseDef: 100,
            baseSpeed: 100,
            moves: [0, 129, 145, 173, 189, 196, 237, 246, 250, 343, 352, 496]
        }, 491: {
            id: 491,
            name: "darkrai",
            specie: 491,
            is_default: true,
            type1: "dark",
            evolutions: [],
            baseHp: 70,
            baseAtk: 113,
            baseDef: 90,
            baseSpeed: 125,
            moves: [0, 15, 98, 129, 168, 173, 185, 189, 196, 228, 237, 249, 317, 332, 351, 371, 451, 466, 496, 510, 555, 612]
        }, 492: {
            id: 492, name: "shaymin-land", specie: 492, is_default: true, type1: "grass", evolutions: [],
            baseHp: 100, baseAtk: 100, baseDef: 100, baseSpeed: 100, moves: [0, 129, 173, 189, 237, 331, 343, 345, 496]
        }, 493: {
            id: 493,
            name: "arceus",
            specie: 493,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 120,
            baseAtk: 120,
            baseDef: 120,
            baseSpeed: 120,
            moves: [0, 15, 129, 173, 189, 196, 210, 237, 239, 246, 249, 250, 317, 318, 331, 332, 351, 352, 371, 419, 451, 466, 496, 497, 510, 523, 555]
        }, 494: {
            id: 494,
            name: "victini",
            specie: 494,
            is_default: true,
            type1: "psychic",
            type2: "fire",
            evolutions: [],
            baseHp: 100,
            baseAtk: 100,
            baseDef: 100,
            baseSpeed: 100,
            moves: [0, 93, 98, 173, 237, 249, 351, 451, 488, 496, 500, 510, 612]
        }, 495: {
            id: 495,
            name: "snivy",
            specie: 495,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 496, method: "level_up", minLevel: 17},],
            baseHp: 45,
            baseAtk: 45,
            baseDef: 55,
            baseSpeed: 63,
            moves: [0, 15, 20, 22, 33, 35, 72, 173, 228, 237, 239, 332, 345, 496]
        }, 496: {
            id: 496,
            name: "servine",
            specie: 496,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 497, method: "level_up", minLevel: 36},],
            baseHp: 60,
            baseAtk: 60,
            baseDef: 75,
            baseSpeed: 83,
            moves: [0, 15, 20, 22, 33, 35, 72, 173, 237, 332, 496]
        }, 497: {
            id: 497,
            name: "serperior",
            specie: 497,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 75,
            baseAtk: 75,
            baseDef: 95,
            baseSpeed: 113,
            moves: [0, 15, 20, 22, 33, 35, 72, 173, 237, 249, 332, 496, 525, 693]
        }, 498: {
            id: 498,
            name: "tepig",
            specie: 498,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 499, method: "level_up", minLevel: 17},],
            baseHp: 65,
            baseAtk: 54,
            baseDef: 45,
            baseSpeed: 45,
            moves: [0, 33, 52, 123, 173, 205, 237, 249, 317, 343, 372, 488, 496, 497, 510]
        }, 499: {
            id: 499,
            name: "pignite",
            specie: 499,
            is_default: true,
            type1: "fire",
            type2: "fighting",
            evolutions: [{to: 500, method: "level_up", minLevel: 36},],
            baseHp: 90,
            baseAtk: 82,
            baseDef: 55,
            baseSpeed: 55,
            moves: [0, 33, 52, 123, 173, 205, 237, 249, 292, 317, 343, 372, 488, 496, 497, 510, 523, 612]
        }, 500: {
            id: 500,
            name: "emboar",
            specie: 500,
            is_default: true,
            type1: "fire",
            type2: "fighting",
            evolutions: [],
            baseHp: 110,
            baseAtk: 112,
            baseDef: 65,
            baseSpeed: 65,
            moves: [0, 33, 52, 123, 173, 205, 237, 249, 292, 317, 343, 372, 479, 488, 496, 497, 510, 523, 612]
        }, 501: {
            id: 501,
            name: "oshawott",
            specie: 501,
            is_default: true,
            type1: "water",
            evolutions: [{to: 502, method: "level_up", minLevel: 17},],
            baseHp: 55,
            baseAtk: 59,
            baseDef: 45,
            baseSpeed: 45,
            moves: [0, 15, 33, 55, 173, 196, 206, 210, 237, 249, 279, 332, 343, 352, 372, 453, 496]
        }, 502: {
            id: 502,
            name: "dewott",
            specie: 502,
            is_default: true,
            type1: "water",
            evolutions: [{to: 503, method: "level_up", minLevel: 36},],
            baseHp: 75,
            baseAtk: 79,
            baseDef: 60,
            baseSpeed: 60,
            moves: [0, 15, 33, 55, 173, 196, 206, 210, 237, 249, 279, 332, 343, 352, 453, 496]
        }, 503: {
            id: 503,
            name: "samurott",
            specie: 503,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 95,
            baseAtk: 104,
            baseDef: 78,
            baseSpeed: 70,
            moves: [0, 15, 33, 55, 173, 196, 206, 210, 237, 249, 279, 332, 343, 352, 453, 496, 525]
        }, 504: {
            id: 504,
            name: "patrat",
            specie: 504,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 505, method: "level_up", minLevel: 20},],
            baseHp: 45,
            baseAtk: 45,
            baseDef: 39,
            baseSpeed: 42,
            moves: [0, 15, 33, 44, 173, 228, 237, 279, 331, 343, 351, 372, 496]
        }, 505: {
            id: 505,
            name: "watchog",
            specie: 505,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 60,
            baseAtk: 73,
            baseDef: 69,
            baseSpeed: 77,
            moves: [0, 15, 33, 44, 173, 237, 249, 343, 351, 496, 612]
        }, 506: {
            id: 506,
            name: "lillipup",
            specie: 506,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 507, method: "level_up", minLevel: 16},],
            baseHp: 45,
            baseAtk: 43,
            baseDef: 45,
            baseSpeed: 55,
            moves: [0, 33, 44, 122, 173, 189, 228, 237, 249, 317, 332, 343, 351, 496, 555]
        }, 507: {
            id: 507,
            name: "herdier",
            specie: 507,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 508, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 58,
            baseDef: 65,
            baseSpeed: 60,
            moves: [0, 33, 44, 173, 237, 249, 317, 332, 343, 351, 371, 496, 555]
        }, 508: {
            id: 508,
            name: "stoutland",
            specie: 508,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 85,
            baseAtk: 78,
            baseDef: 90,
            baseSpeed: 80,
            moves: [0, 33, 44, 173, 237, 249, 317, 332, 343, 351, 371, 496, 555]
        }, 509: {
            id: 509,
            name: "purrloin",
            specie: 509,
            is_default: true,
            type1: "dark",
            evolutions: [{to: 510, method: "level_up", minLevel: 20},],
            baseHp: 41,
            baseAtk: 50,
            baseDef: 37,
            baseSpeed: 66,
            moves: [0, 6, 10, 15, 154, 168, 173, 185, 228, 237, 252, 332, 343, 371, 372, 496, 497, 555]
        }, 510: {
            id: 510,
            name: "liepard",
            specie: 510,
            is_default: true,
            type1: "dark",
            evolutions: [],
            baseHp: 64,
            baseAtk: 88,
            baseDef: 50,
            baseSpeed: 106,
            moves: [0, 10, 15, 154, 168, 173, 228, 237, 249, 252, 332, 343, 371, 372, 496, 497, 555]
        }, 511: {
            id: 511,
            name: "pansage",
            specie: 511,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 512, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 53,
            baseDef: 48,
            baseSpeed: 64,
            moves: [0, 10, 15, 22, 44, 122, 154, 168, 173, 237, 249, 310, 317, 331, 343, 345, 371, 496, 512, 574]
        }, 512: {
            id: 512,
            name: "simisage",
            specie: 512,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 75,
            baseAtk: 98,
            baseDef: 63,
            baseSpeed: 101,
            moves: [0, 15, 122, 154, 168, 173, 237, 249, 317, 343, 371, 496, 512, 612]
        }, 513: {
            id: 513,
            name: "pansear",
            specie: 513,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 514, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 53,
            baseDef: 48,
            baseSpeed: 64,
            moves: [0, 10, 15, 44, 83, 122, 154, 168, 173, 237, 249, 310, 317, 343, 371, 488, 496, 510, 512, 574]
        }, 514: {
            id: 514,
            name: "simisear",
            specie: 514,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 75,
            baseAtk: 98,
            baseDef: 63,
            baseSpeed: 101,
            moves: [0, 15, 122, 154, 168, 173, 237, 249, 317, 343, 371, 488, 496, 510, 512, 612]
        }, 515: {
            id: 515,
            name: "panpour",
            specie: 515,
            is_default: true,
            type1: "water",
            evolutions: [{to: 516, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 53,
            baseDef: 48,
            baseSpeed: 64,
            moves: [0, 10, 15, 44, 55, 122, 154, 168, 173, 196, 237, 249, 310, 317, 343, 352, 371, 496, 512, 574]
        }, 516: {
            id: 516,
            name: "simipour",
            specie: 516,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 75,
            baseAtk: 98,
            baseDef: 63,
            baseSpeed: 101,
            moves: [0, 15, 122, 154, 168, 173, 196, 237, 249, 317, 343, 352, 371, 496, 512, 612]
        }, 517: {
            id: 517,
            name: "munna",
            specie: 517,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 518, method: "level_up", minLevel: 32},],
            baseHp: 76,
            baseAtk: 46,
            baseDef: 50,
            baseSpeed: 24,
            moves: [0, 129, 173, 237, 317, 351, 451, 496, 500]
        }, 518: {
            id: 518, name: "musharna", specie: 518, is_default: true, type1: "psychic", evolutions: [],
            baseHp: 116, baseAtk: 81, baseDef: 90, baseSpeed: 29, moves: [0, 173, 237, 317, 351, 451, 496]
        }, 519: {
            id: 519,
            name: "pidove",
            specie: 519,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 520, method: "level_up", minLevel: 21},],
            baseHp: 50,
            baseAtk: 46,
            baseDef: 40,
            baseSpeed: 43,
            moves: [0, 16, 98, 173, 237, 314, 332, 365, 496, 497]
        }, 520: {
            id: 520,
            name: "tranquill",
            specie: 520,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 521, method: "level_up", minLevel: 32},],
            baseHp: 62,
            baseAtk: 64,
            baseDef: 52,
            baseSpeed: 65,
            moves: [0, 16, 98, 173, 237, 314, 332, 365, 496, 497]
        }, 521: {
            id: 521, name: "unfezant", specie: 521, is_default: true, type1: "normal", type2: "flying", evolutions: [],
            baseHp: 80, baseAtk: 90, baseDef: 68, baseSpeed: 93, moves: [0, 16, 98, 173, 237, 314, 332, 365, 496, 497]
        }, 522: {
            id: 522,
            name: "blitzle",
            specie: 522,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 523, method: "level_up", minLevel: 27},],
            baseHp: 45,
            baseAtk: 55,
            baseDef: 32,
            baseSpeed: 76,
            moves: [0, 24, 98, 99, 173, 228, 237, 351, 364, 451, 488, 496]
        }, 523: {
            id: 523, name: "zebstrika", specie: 523, is_default: true, type1: "electric", evolutions: [],
            baseHp: 75, baseAtk: 90, baseDef: 63, baseSpeed: 116, moves: [0, 98, 173, 228, 237, 249, 351, 451, 488, 496]
        }, 524: {
            id: 524,
            name: "roggenrola",
            specie: 524,
            is_default: true,
            type1: "rock",
            evolutions: [{to: 525, method: "level_up", minLevel: 25},],
            baseHp: 55,
            baseAtk: 50,
            baseDef: 55,
            baseSpeed: 15,
            moves: [0, 33, 173, 189, 237, 249, 317, 350, 479, 496, 523]
        }, 525: {
            id: 525,
            name: "boldore",
            specie: 525,
            is_default: true,
            type1: "rock",
            evolutions: [{to: 526, method: "level_up", minLevel: 32},],
            baseHp: 70,
            baseAtk: 78,
            baseDef: 73,
            baseSpeed: 20,
            moves: [0, 33, 173, 189, 237, 249, 317, 350, 479, 496, 523]
        }, 526: {
            id: 526,
            name: "gigalith",
            specie: 526,
            is_default: true,
            type1: "rock",
            evolutions: [],
            baseHp: 85,
            baseAtk: 98,
            baseDef: 105,
            baseSpeed: 25,
            moves: [0, 33, 173, 189, 237, 249, 317, 350, 479, 496, 523]
        }, 527: {
            id: 527,
            name: "woobat",
            specie: 527,
            is_default: true,
            type1: "psychic",
            type2: "flying",
            evolutions: [{to: 528, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 50,
            baseDef: 43,
            baseSpeed: 72,
            moves: [0, 16, 93, 168, 173, 237, 314, 332, 351, 365, 372, 451, 496, 500, 512, 531]
        }, 528: {
            id: 528,
            name: "swoobat",
            specie: 528,
            is_default: true,
            type1: "psychic",
            type2: "flying",
            evolutions: [],
            baseHp: 67,
            baseAtk: 67,
            baseDef: 55,
            baseSpeed: 114,
            moves: [0, 16, 93, 168, 173, 237, 314, 332, 351, 365, 372, 451, 496, 512, 531]
        }, 529: {
            id: 529,
            name: "drilbur",
            specie: 529,
            is_default: true,
            type1: "ground",
            evolutions: [{to: 530, method: "level_up", minLevel: 31},],
            baseHp: 60,
            baseAtk: 58,
            baseDef: 43,
            baseSpeed: 68,
            moves: [0, 10, 15, 154, 173, 189, 229, 232, 237, 249, 317, 332, 496, 523]
        }, 530: {
            id: 530,
            name: "excadrill",
            specie: 530,
            is_default: true,
            type1: "ground",
            type2: "steel",
            evolutions: [],
            baseHp: 110,
            baseAtk: 93,
            baseDef: 63,
            baseSpeed: 88,
            moves: [0, 10, 15, 154, 173, 189, 229, 232, 237, 249, 317, 332, 496, 523, 693]
        }, 531: {
            id: 531,
            name: "audino",
            specie: 531,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 103,
            baseAtk: 60,
            baseDef: 86,
            baseSpeed: 50,
            moves: [0, 1, 3, 173, 196, 237, 343, 451, 496, 497, 510, 574, 577, 612]
        }, 532: {
            id: 532,
            name: "timburr",
            specie: 532,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 533, method: "level_up", minLevel: 25},],
            baseHp: 75,
            baseAtk: 53,
            baseDef: 45,
            baseSpeed: 35,
            moves: [0, 1, 4, 88, 173, 183, 237, 249, 317, 371, 395, 479, 496, 612, 693]
        }, 533: {
            id: 533,
            name: "gurdurr",
            specie: 533,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 534, method: "level_up", minLevel: 32},],
            baseHp: 85,
            baseAtk: 73,
            baseDef: 68,
            baseSpeed: 40,
            moves: [0, 1, 88, 173, 237, 249, 317, 371, 479, 496, 612, 693]
        }, 534: {
            id: 534,
            name: "conkeldurr",
            specie: 534,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 105,
            baseAtk: 98,
            baseDef: 80,
            baseSpeed: 45,
            moves: [0, 1, 88, 173, 237, 249, 317, 371, 479, 496, 523, 612, 693]
        }, 535: {
            id: 535,
            name: "tympole",
            specie: 535,
            is_default: true,
            type1: "water",
            evolutions: [{to: 536, method: "level_up", minLevel: 25},],
            baseHp: 50,
            baseAtk: 50,
            baseDef: 40,
            baseSpeed: 64,
            moves: [0, 145, 173, 196, 237, 341, 352, 496, 497, 611]
        }, 536: {
            id: 536,
            name: "palpitoad",
            specie: 536,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [{to: 537, method: "level_up", minLevel: 36},],
            baseHp: 75,
            baseAtk: 65,
            baseDef: 55,
            baseSpeed: 69,
            moves: [0, 145, 173, 196, 237, 249, 341, 352, 496, 497, 523, 611]
        }, 537: {
            id: 537,
            name: "seismitoad",
            specie: 537,
            is_default: true,
            type1: "water",
            type2: "ground",
            evolutions: [],
            baseHp: 105,
            baseAtk: 90,
            baseDef: 75,
            baseSpeed: 74,
            moves: [0, 51, 145, 173, 196, 237, 249, 317, 341, 352, 371, 496, 497, 523, 611, 612]
        }, 538: {
            id: 538,
            name: "throh",
            specie: 538,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 120,
            baseAtk: 65,
            baseDef: 85,
            baseSpeed: 45,
            moves: [0, 20, 173, 237, 249, 279, 317, 371, 480, 496, 509, 523, 612]
        }, 539: {
            id: 539,
            name: "sawk",
            specie: 539,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 75,
            baseAtk: 78,
            baseDef: 75,
            baseSpeed: 85,
            moves: [0, 2, 24, 173, 237, 249, 317, 371, 496, 523, 530, 612]
        }, 540: {
            id: 540,
            name: "sewaddle",
            specie: 540,
            is_default: true,
            type1: "bug",
            type2: "grass",
            evolutions: [{to: 541, method: "level_up", minLevel: 20},],
            baseHp: 45,
            baseAtk: 47,
            baseDef: 65,
            baseSpeed: 42,
            moves: [0, 15, 33, 75, 173, 237, 318, 371, 450, 496, 522, 527]
        }, 541: {
            id: 541,
            name: "swadloon",
            specie: 541,
            is_default: true,
            type1: "bug",
            type2: "grass",
            evolutions: [{to: 542, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 57,
            baseDef: 85,
            baseSpeed: 42,
            moves: [0, 15, 33, 75, 173, 237, 371, 450, 496, 522, 527]
        }, 542: {
            id: 542,
            name: "leavanny",
            specie: 542,
            is_default: true,
            type1: "bug",
            type2: "grass",
            evolutions: [],
            baseHp: 75,
            baseAtk: 87,
            baseDef: 80,
            baseSpeed: 92,
            moves: [0, 15, 33, 75, 173, 206, 237, 332, 371, 450, 496, 522, 527, 565]
        }, 543: {
            id: 543,
            name: "venipede",
            specie: 543,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [{to: 544, method: "level_up", minLevel: 22},],
            baseHp: 30,
            baseAtk: 38,
            baseDef: 49,
            baseSpeed: 57,
            moves: [0, 40, 41, 42, 173, 205, 228, 237, 249, 342, 371, 450, 496, 522, 611]
        }, 544: {
            id: 544,
            name: "whirlipede",
            specie: 544,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [{to: 545, method: "level_up", minLevel: 30},],
            baseHp: 40,
            baseAtk: 48,
            baseDef: 89,
            baseSpeed: 47,
            moves: [0, 40, 173, 205, 228, 237, 249, 342, 371, 450, 496, 522, 611]
        }, 545: {
            id: 545,
            name: "scolipede",
            specie: 545,
            is_default: true,
            type1: "bug",
            type2: "poison",
            evolutions: [],
            baseHp: 60,
            baseAtk: 78,
            baseDef: 79,
            baseSpeed: 112,
            moves: [0, 15, 40, 173, 205, 228, 237, 249, 317, 342, 371, 450, 496, 522, 523, 611]
        }, 546: {
            id: 546,
            name: "cottonee",
            specie: 546,
            is_default: true,
            type1: "grass",
            type2: "fairy",
            evolutions: [{to: 547, method: "level_up", minLevel: 32},],
            baseHp: 40,
            baseAtk: 32,
            baseDef: 55,
            baseSpeed: 66,
            moves: [0, 71, 72, 75, 173, 237, 343, 496, 584]
        }, 547: {
            id: 547, name: "whimsicott", specie: 547, is_default: true, type1: "grass", type2: "fairy", evolutions: [],
            baseHp: 60, baseAtk: 72, baseDef: 80, baseSpeed: 116, moves: [0, 16, 72, 168, 173, 237, 343, 496]
        }, 548: {
            id: 548,
            name: "petilil",
            specie: 548,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 549, method: "level_up", minLevel: 32},],
            baseHp: 45,
            baseAtk: 53,
            baseDef: 50,
            baseSpeed: 30,
            moves: [0, 15, 71, 72, 173, 237, 343, 345, 496]
        }, 549: {
            id: 549, name: "lilligant", specie: 549, is_default: true, type1: "grass", evolutions: [],
            baseHp: 70, baseAtk: 85, baseDef: 75, baseSpeed: 90, moves: [0, 15, 72, 173, 237, 343, 496]
        }, 550: {
            id: 550,
            name: "basculin-red-striped",
            specie: 550,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 70,
            baseAtk: 86,
            baseDef: 60,
            baseSpeed: 98,
            moves: [0, 15, 33, 44, 55, 99, 129, 173, 196, 237, 250, 279, 341, 453, 496]
        }, 551: {
            id: 551,
            name: "sandile",
            specie: 551,
            is_default: true,
            type1: "ground",
            type2: "dark",
            evolutions: [{to: 552, method: "level_up", minLevel: 29},],
            baseHp: 50,
            baseAtk: 54,
            baseDef: 35,
            baseSpeed: 65,
            moves: [0, 15, 44, 99, 168, 173, 189, 228, 237, 317, 328, 371, 372, 496, 510, 523, 555, 681]
        }, 552: {
            id: 552,
            name: "krokorok",
            specie: 552,
            is_default: true,
            type1: "ground",
            type2: "dark",
            evolutions: [{to: 553, method: "level_up", minLevel: 40},],
            baseHp: 60,
            baseAtk: 64,
            baseDef: 45,
            baseSpeed: 74,
            moves: [0, 15, 44, 99, 168, 173, 189, 237, 249, 317, 328, 371, 372, 496, 510, 523, 555, 612, 693]
        }, 553: {
            id: 553,
            name: "krookodile",
            specie: 553,
            is_default: true,
            type1: "ground",
            type2: "dark",
            evolutions: [],
            baseHp: 95,
            baseAtk: 91,
            baseDef: 75,
            baseSpeed: 92,
            moves: [0, 15, 44, 99, 168, 173, 189, 237, 249, 317, 328, 332, 371, 372, 479, 496, 510, 523, 525, 555, 612, 681, 693]
        }, 554: {
            id: 554,
            name: "darumaka",
            specie: 554,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 555, method: "level_up", minLevel: 35},],
            baseHp: 70,
            baseAtk: 53,
            baseDef: 45,
            baseSpeed: 50,
            moves: [0, 33, 99, 168, 172, 173, 205, 237, 249, 317, 488, 496, 510, 612]
        }, 555: {
            id: 555,
            name: "darmanitan-standard",
            specie: 555,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 105,
            baseAtk: 85,
            baseDef: 55,
            baseSpeed: 95,
            moves: [0, 33, 99, 168, 173, 205, 237, 249, 317, 371, 479, 488, 496, 510, 523, 612]
        }, 556: {
            id: 556,
            name: "maractus",
            specie: 556,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 75,
            baseAtk: 96,
            baseDef: 67,
            baseSpeed: 60,
            moves: [0, 42, 64, 71, 72, 173, 237, 302, 331, 332, 496]
        }, 557: {
            id: 557,
            name: "dwebble",
            specie: 557,
            is_default: true,
            type1: "bug",
            type2: "rock",
            evolutions: [{to: 558, method: "level_up", minLevel: 34},],
            baseHp: 50,
            baseAtk: 50,
            baseDef: 60,
            baseSpeed: 55,
            moves: [0, 15, 173, 185, 210, 237, 249, 317, 328, 332, 350, 450, 479, 496, 522, 523]
        }, 558: {
            id: 558,
            name: "crustle",
            specie: 558,
            is_default: true,
            type1: "bug",
            type2: "rock",
            evolutions: [],
            baseHp: 70,
            baseAtk: 85,
            baseDef: 100,
            baseSpeed: 45,
            moves: [0, 15, 173, 185, 237, 249, 317, 332, 350, 450, 479, 496, 522, 523]
        }, 559: {
            id: 559,
            name: "scraggy",
            specie: 559,
            is_default: true,
            type1: "dark",
            type2: "fighting",
            evolutions: [{to: 560, method: "level_up", minLevel: 39},],
            baseHp: 50,
            baseAtk: 55,
            baseDef: 70,
            baseSpeed: 48,
            moves: [0, 173, 185, 237, 249, 252, 317, 371, 479, 491, 496, 510, 525, 530, 555, 612]
        }, 560: {
            id: 560,
            name: "scrafty",
            specie: 560,
            is_default: true,
            type1: "dark",
            type2: "fighting",
            evolutions: [],
            baseHp: 65,
            baseAtk: 68,
            baseDef: 115,
            baseSpeed: 58,
            moves: [0, 168, 173, 185, 237, 249, 317, 371, 479, 496, 510, 525, 530, 555, 612]
        }, 561: {
            id: 561,
            name: "sigilyph",
            specie: 561,
            is_default: true,
            type1: "psychic",
            type2: "flying",
            evolutions: [],
            baseHp: 72,
            baseAtk: 81,
            baseDef: 80,
            baseSpeed: 97,
            moves: [0, 16, 168, 173, 196, 237, 246, 314, 332, 351, 365, 451, 479, 496, 500]
        }, 562: {
            id: 562,
            name: "yamask",
            specie: 562,
            is_default: true,
            type1: "ghost",
            evolutions: [{to: 563, method: "level_up", minLevel: 34},],
            baseHp: 38,
            baseAtk: 43,
            baseDef: 75,
            baseSpeed: 30,
            moves: [0, 168, 173, 237, 310, 351, 371, 466, 496, 611]
        }, 563: {
            id: 563,
            name: "cofagrigus",
            specie: 563,
            is_default: true,
            type1: "ghost",
            evolutions: [],
            baseHp: 58,
            baseAtk: 73,
            baseDef: 125,
            baseSpeed: 30,
            moves: [0, 168, 173, 237, 310, 351, 371, 466, 496, 611]
        }, 564: {
            id: 564,
            name: "tirtouga",
            specie: 564,
            is_default: true,
            type1: "water",
            type2: "rock",
            evolutions: [{to: 565, method: "level_up", minLevel: 37},],
            baseHp: 54,
            baseAtk: 66,
            baseDef: 74,
            baseSpeed: 22,
            moves: [0, 44, 55, 88, 173, 196, 205, 237, 246, 249, 250, 317, 352, 453, 479, 496, 523]
        }, 565: {
            id: 565,
            name: "carracosta",
            specie: 565,
            is_default: true,
            type1: "water",
            type2: "rock",
            evolutions: [],
            baseHp: 74,
            baseAtk: 96,
            baseDef: 99,
            baseSpeed: 32,
            moves: [0, 44, 55, 173, 196, 205, 237, 246, 249, 317, 352, 453, 479, 496, 523]
        }, 566: {
            id: 566,
            name: "archen",
            specie: 566,
            is_default: true,
            type1: "rock",
            type2: "flying",
            evolutions: [{to: 567, method: "level_up", minLevel: 37},],
            baseHp: 55,
            baseAtk: 93,
            baseDef: 45,
            baseSpeed: 70,
            moves: [0, 15, 17, 44, 88, 98, 173, 225, 237, 246, 249, 317, 332, 365, 479, 496, 512, 523]
        }, 567: {
            id: 567,
            name: "archeops",
            specie: 567,
            is_default: true,
            type1: "rock",
            type2: "flying",
            evolutions: [],
            baseHp: 75,
            baseAtk: 126,
            baseDef: 65,
            baseSpeed: 110,
            moves: [0, 15, 17, 88, 98, 173, 225, 237, 246, 249, 317, 332, 365, 479, 496, 512, 523, 525]
        }, 568: {
            id: 568,
            name: "trubbish",
            specie: 568,
            is_default: true,
            type1: "poison",
            evolutions: [{to: 569, method: "level_up", minLevel: 36},],
            baseHp: 50,
            baseAtk: 45,
            baseDef: 62,
            baseSpeed: 65,
            moves: [0, 1, 3, 168, 173, 205, 237, 350, 371, 491, 496, 499, 611]
        }, 569: {
            id: 569,
            name: "garbodor",
            specie: 569,
            is_default: true,
            type1: "poison",
            evolutions: [],
            baseHp: 80,
            baseAtk: 78,
            baseDef: 82,
            baseSpeed: 75,
            moves: [0, 1, 3, 168, 173, 237, 371, 479, 491, 496, 499, 611]
        }, 570: {
            id: 570,
            name: "zorua",
            specie: 570,
            is_default: true,
            type1: "dark",
            evolutions: [{to: 571, method: "level_up", minLevel: 30},],
            baseHp: 40,
            baseAtk: 73,
            baseDef: 40,
            baseSpeed: 65,
            moves: [0, 10, 15, 154, 168, 173, 185, 228, 237, 332, 343, 371, 496, 510, 555]
        }, 571: {
            id: 571,
            name: "zoroark",
            specie: 571,
            is_default: true,
            type1: "dark",
            evolutions: [],
            baseHp: 60,
            baseAtk: 113,
            baseDef: 60,
            baseSpeed: 105,
            moves: [0, 10, 15, 154, 168, 173, 185, 228, 237, 249, 332, 343, 371, 496, 510, 555]
        }, 572: {
            id: 572,
            name: "minccino",
            specie: 572,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 573, method: "level_up", minLevel: 32},],
            baseHp: 55,
            baseAtk: 45,
            baseDef: 40,
            baseSpeed: 75,
            moves: [0, 1, 3, 129, 168, 173, 189, 237, 343, 351, 496, 497, 541]
        }, 573: {
            id: 573,
            name: "cinccino",
            specie: 573,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 75,
            baseAtk: 80,
            baseDef: 60,
            baseSpeed: 115,
            moves: [0, 168, 173, 237, 331, 343, 350, 351, 496, 497, 541]
        }, 574: {
            id: 574,
            name: "gothita",
            specie: 574,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 575, method: "level_up", minLevel: 32},],
            baseHp: 45,
            baseAtk: 43,
            baseDef: 58,
            baseSpeed: 45,
            moves: [0, 1, 3, 93, 168, 173, 185, 237, 317, 343, 351, 371, 451, 496]
        }, 575: {
            id: 575,
            name: "gothorita",
            specie: 575,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 576, method: "level_up", minLevel: 41},],
            baseHp: 60,
            baseAtk: 60,
            baseDef: 78,
            baseSpeed: 55,
            moves: [0, 1, 3, 93, 168, 173, 185, 237, 317, 343, 351, 371, 451, 496]
        }, 576: {
            id: 576,
            name: "gothitelle",
            specie: 576,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 70,
            baseAtk: 75,
            baseDef: 103,
            baseSpeed: 65,
            moves: [0, 1, 3, 93, 168, 173, 185, 237, 317, 343, 351, 371, 451, 496, 612]
        }, 577: {
            id: 577,
            name: "solosis",
            specie: 577,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 578, method: "level_up", minLevel: 32},],
            baseHp: 45,
            baseAtk: 68,
            baseDef: 45,
            baseSpeed: 20,
            moves: [0, 173, 205, 237, 310, 317, 351, 496, 611]
        }, 578: {
            id: 578,
            name: "duosion",
            specie: 578,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 579, method: "level_up", minLevel: 41},],
            baseHp: 65,
            baseAtk: 83,
            baseDef: 55,
            baseSpeed: 30,
            moves: [0, 173, 205, 237, 317, 351, 496, 611]
        }, 579: {
            id: 579,
            name: "reuniclus",
            specie: 579,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 110,
            baseAtk: 95,
            baseDef: 80,
            baseSpeed: 30,
            moves: [0, 173, 205, 237, 249, 317, 351, 496, 611, 612]
        }, 580: {
            id: 580,
            name: "ducklett",
            specie: 580,
            is_default: true,
            type1: "water",
            type2: "flying",
            evolutions: [{to: 581, method: "level_up", minLevel: 35},],
            baseHp: 62,
            baseAtk: 44,
            baseDef: 50,
            baseSpeed: 55,
            moves: [0, 16, 17, 55, 173, 196, 237, 314, 332, 352, 365, 453, 496]
        }, 581: {
            id: 581, name: "swanna", specie: 581, is_default: true, type1: "water", type2: "flying", evolutions: [],
            baseHp: 75, baseAtk: 87, baseDef: 63, baseSpeed: 98, moves: [0, 17, 55, 173, 196, 237, 332, 352, 365, 496]
        }, 582: {
            id: 582,
            name: "vanillite",
            specie: 582,
            is_default: true,
            type1: "ice",
            evolutions: [{to: 583, method: "level_up", minLevel: 35},],
            baseHp: 36,
            baseAtk: 58,
            baseDef: 55,
            baseSpeed: 44,
            moves: [0, 173, 181, 196, 237, 310, 333, 352, 419, 420, 496, 524]
        }, 583: {
            id: 583,
            name: "vanillish",
            specie: 583,
            is_default: true,
            type1: "ice",
            evolutions: [{to: 584, method: "level_up", minLevel: 47},],
            baseHp: 51,
            baseAtk: 73,
            baseDef: 70,
            baseSpeed: 59,
            moves: [0, 173, 196, 237, 310, 333, 352, 419, 496, 524]
        }, 584: {
            id: 584,
            name: "vanilluxe",
            specie: 584,
            is_default: true,
            type1: "ice",
            evolutions: [],
            baseHp: 71,
            baseAtk: 103,
            baseDef: 90,
            baseSpeed: 79,
            moves: [0, 173, 196, 237, 310, 311, 333, 352, 419, 496, 524]
        }, 585: {
            id: 585,
            name: "deerling",
            specie: 585,
            is_default: true,
            type1: "normal",
            type2: "grass",
            evolutions: [{to: 586, method: "level_up", minLevel: 34},],
            baseHp: 60,
            baseAtk: 50,
            baseDef: 50,
            baseSpeed: 75,
            moves: [0, 24, 33, 173, 185, 237, 496, 497]
        }, 586: {
            id: 586, name: "sawsbuck", specie: 586, is_default: true, type1: "normal", type2: "grass", evolutions: [],
            baseHp: 80, baseAtk: 80, baseDef: 70, baseSpeed: 95, moves: [0, 15, 24, 33, 173, 185, 237, 249, 496, 497]
        }, 587: {
            id: 587,
            name: "emolga",
            specie: 587,
            is_default: true,
            type1: "electric",
            type2: "flying",
            evolutions: [],
            baseHp: 55,
            baseAtk: 75,
            baseDef: 60,
            baseSpeed: 103,
            moves: [0, 15, 84, 98, 173, 228, 237, 310, 332, 343, 351, 451, 496, 512, 527, 609]
        }, 588: {
            id: 588,
            name: "karrablast",
            specie: 588,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 589, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 58,
            baseDef: 45,
            baseSpeed: 60,
            moves: [0, 15, 31, 64, 173, 185, 206, 210, 228, 237, 332, 450, 496, 522, 611]
        }, 589: {
            id: 589,
            name: "escavalier",
            specie: 589,
            is_default: true,
            type1: "bug",
            type2: "steel",
            evolutions: [],
            baseHp: 70,
            baseAtk: 98,
            baseDef: 105,
            baseSpeed: 20,
            moves: [0, 15, 31, 41, 64, 173, 206, 237, 249, 332, 450, 496, 522, 565, 611, 693]
        }, 590: {
            id: 590,
            name: "foongus",
            specie: 590,
            is_default: true,
            type1: "grass",
            type2: "poison",
            evolutions: [{to: 591, method: "level_up", minLevel: 39},],
            baseHp: 69,
            baseAtk: 55,
            baseDef: 50,
            baseSpeed: 15,
            moves: [0, 71, 72, 173, 185, 205, 237, 310, 371, 496, 499]
        }, 591: {
            id: 591, name: "amoonguss", specie: 591, is_default: true, type1: "grass", type2: "poison", evolutions: [],
            baseHp: 114, baseAtk: 85, baseDef: 75, baseSpeed: 30, moves: [0, 71, 72, 173, 185, 237, 310, 371, 496, 499]
        }, 592: {
            id: 592,
            name: "frillish",
            specie: 592,
            is_default: true,
            type1: "water",
            type2: "ghost",
            evolutions: [{to: 593, method: "level_up", minLevel: 40},],
            baseHp: 55,
            baseAtk: 53,
            baseDef: 68,
            baseSpeed: 40,
            moves: [0, 20, 71, 132, 145, 173, 196, 237, 351, 352, 466, 496]
        }, 593: {
            id: 593,
            name: "jellicent",
            specie: 593,
            is_default: true,
            type1: "water",
            type2: "ghost",
            evolutions: [],
            baseHp: 100,
            baseAtk: 73,
            baseDef: 88,
            baseSpeed: 60,
            moves: [0, 20, 71, 145, 173, 196, 237, 351, 352, 466, 496]
        }, 594: {
            id: 594, name: "alomomola", specie: 594, is_default: true, type1: "water", evolutions: [],
            baseHp: 165, baseAtk: 58, baseDef: 63, baseSpeed: 65, moves: [0, 1, 3, 173, 196, 237, 250, 352, 453, 496]
        }, 595: {
            id: 595,
            name: "joltik",
            specie: 595,
            is_default: true,
            type1: "bug",
            type2: "electric",
            evolutions: [{to: 596, method: "level_up", minLevel: 36},],
            baseHp: 50,
            baseAtk: 52,
            baseDef: 50,
            baseSpeed: 65,
            moves: [0, 15, 40, 42, 71, 168, 173, 185, 210, 228, 237, 351, 450, 451, 496, 522, 527, 611]
        }, 596: {
            id: 596,
            name: "galvantula",
            specie: 596,
            is_default: true,
            type1: "bug",
            type2: "electric",
            evolutions: [],
            baseHp: 70,
            baseAtk: 87,
            baseDef: 60,
            baseSpeed: 108,
            moves: [0, 15, 71, 168, 173, 210, 237, 351, 450, 451, 496, 522, 527, 611]
        }, 597: {
            id: 597,
            name: "ferroseed",
            specie: 597,
            is_default: true,
            type1: "grass",
            type2: "steel",
            evolutions: [{to: 598, method: "level_up", minLevel: 40},],
            baseHp: 44,
            baseAtk: 37,
            baseDef: 89,
            baseSpeed: 10,
            moves: [0, 33, 42, 173, 205, 232, 237, 249, 331, 371, 491, 496]
        }, 598: {
            id: 598,
            name: "ferrothorn",
            specie: 598,
            is_default: true,
            type1: "grass",
            type2: "steel",
            evolutions: [],
            baseHp: 74,
            baseAtk: 74,
            baseDef: 124,
            baseSpeed: 20,
            moves: [0, 15, 33, 42, 173, 205, 232, 237, 249, 332, 371, 496, 523]
        }, 599: {
            id: 599,
            name: "klink",
            specie: 599,
            is_default: true,
            type1: "steel",
            evolutions: [{to: 600, method: "level_up", minLevel: 38},],
            baseHp: 40,
            baseAtk: 50,
            baseDef: 65,
            baseSpeed: 30,
            moves: [0, 11, 20, 84, 173, 237, 249, 351, 451, 496, 544]
        }, 600: {
            id: 600,
            name: "klang",
            specie: 600,
            is_default: true,
            type1: "steel",
            evolutions: [{to: 601, method: "level_up", minLevel: 49},],
            baseHp: 60,
            baseAtk: 75,
            baseDef: 90,
            baseSpeed: 50,
            moves: [0, 11, 20, 84, 173, 237, 249, 351, 451, 496, 544]
        }, 601: {
            id: 601,
            name: "klinklang",
            specie: 601,
            is_default: true,
            type1: "steel",
            evolutions: [],
            baseHp: 60,
            baseAtk: 85,
            baseDef: 100,
            baseSpeed: 90,
            moves: [0, 11, 20, 84, 173, 237, 249, 351, 451, 496, 544]
        }, 602: {
            id: 602,
            name: "tynamo",
            specie: 602,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 603, method: "level_up", minLevel: 39},],
            baseHp: 35,
            baseAtk: 50,
            baseDef: 40,
            baseSpeed: 60,
            moves: [0, 33, 451]
        }, 603: {
            id: 603,
            name: "eelektrik",
            specie: 603,
            is_default: true,
            type1: "electric",
            evolutions: [{to: 604, method: "level_up", minLevel: 32},],
            baseHp: 65,
            baseAtk: 80,
            baseDef: 70,
            baseSpeed: 40,
            moves: [0, 20, 51, 173, 237, 351, 451, 491, 496, 512]
        }, 604: {
            id: 604,
            name: "eelektross",
            specie: 604,
            is_default: true,
            type1: "electric",
            evolutions: [],
            baseHp: 85,
            baseAtk: 110,
            baseDef: 80,
            baseSpeed: 50,
            moves: [0, 15, 20, 51, 173, 237, 249, 317, 351, 451, 496, 512, 525, 612]
        }, 605: {
            id: 605,
            name: "elgyem",
            specie: 605,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 606, method: "level_up", minLevel: 42},],
            baseHp: 55,
            baseAtk: 70,
            baseDef: 55,
            baseSpeed: 30,
            moves: [0, 93, 168, 173, 237, 310, 317, 351, 451, 496, 497]
        }, 606: {
            id: 606, name: "beheeyem", specie: 606, is_default: true, type1: "psychic", evolutions: [],
            baseHp: 75, baseAtk: 100, baseDef: 85, baseSpeed: 40, moves: [0, 93, 168, 173, 237, 317, 351, 451, 496, 497]
        }, 607: {
            id: 607,
            name: "litwick",
            specie: 607,
            is_default: true,
            type1: "ghost",
            type2: "fire",
            evolutions: [{to: 608, method: "level_up", minLevel: 41},],
            baseHp: 50,
            baseAtk: 48,
            baseDef: 55,
            baseSpeed: 20,
            moves: [0, 51, 52, 83, 123, 168, 173, 237, 310, 351, 371, 488, 496, 499, 510]
        }, 608: {
            id: 608,
            name: "lampent",
            specie: 608,
            is_default: true,
            type1: "ghost",
            type2: "fire",
            evolutions: [{to: 609, method: "level_up", minLevel: 32},],
            baseHp: 60,
            baseAtk: 68,
            baseDef: 60,
            baseSpeed: 55,
            moves: [0, 52, 83, 123, 168, 173, 237, 310, 351, 371, 488, 496, 510]
        }, 609: {
            id: 609,
            name: "chandelure",
            specie: 609,
            is_default: true,
            type1: "ghost",
            type2: "fire",
            evolutions: [],
            baseHp: 60,
            baseAtk: 100,
            baseDef: 90,
            baseSpeed: 80,
            moves: [0, 123, 168, 173, 237, 351, 371, 488, 496, 510]
        }, 610: {
            id: 610,
            name: "axew",
            specie: 610,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 611, method: "level_up", minLevel: 38},],
            baseHp: 46,
            baseAtk: 59,
            baseDef: 50,
            baseSpeed: 57,
            moves: [0, 10, 15, 173, 206, 237, 249, 317, 332, 351, 371, 372, 496, 510, 530]
        }, 611: {
            id: 611,
            name: "fraxure",
            specie: 611,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 612, method: "level_up", minLevel: 48},],
            baseHp: 66,
            baseAtk: 79,
            baseDef: 60,
            baseSpeed: 67,
            moves: [0, 10, 15, 173, 206, 237, 249, 317, 332, 351, 371, 372, 496, 510, 525, 530]
        }, 612: {
            id: 612,
            name: "haxorus",
            specie: 612,
            is_default: true,
            type1: "dragon",
            evolutions: [],
            baseHp: 76,
            baseAtk: 104,
            baseDef: 80,
            baseSpeed: 97,
            moves: [0, 10, 15, 173, 206, 237, 249, 317, 332, 351, 371, 372, 496, 510, 523, 525, 530, 693]
        }, 613: {
            id: 613,
            name: "cubchoo",
            specie: 613,
            is_default: true,
            type1: "ice",
            evolutions: [{to: 614, method: "level_up", minLevel: 37},],
            baseHp: 55,
            baseAtk: 65,
            baseDef: 40,
            baseSpeed: 40,
            moves: [0, 15, 154, 173, 181, 196, 237, 249, 317, 332, 343, 352, 372, 419, 496, 497, 524, 612]
        }, 614: {
            id: 614,
            name: "beartic",
            specie: 614,
            is_default: true,
            type1: "ice",
            evolutions: [],
            baseHp: 95,
            baseAtk: 100,
            baseDef: 80,
            baseSpeed: 50,
            moves: [0, 15, 154, 173, 181, 196, 237, 249, 317, 332, 343, 352, 453, 496, 497, 523, 524, 612]
        }, 615: {
            id: 615,
            name: "cryogonal",
            specie: 615,
            is_default: true,
            type1: "ice",
            evolutions: [],
            baseHp: 80,
            baseAtk: 73,
            baseDef: 93,
            baseSpeed: 105,
            moves: [0, 20, 173, 196, 229, 237, 246, 352, 420, 496, 512, 524]
        }, 616: {
            id: 616,
            name: "shelmet",
            specie: 616,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 617, method: "level_up", minLevel: 32},],
            baseHp: 50,
            baseAtk: 40,
            baseDef: 75,
            baseSpeed: 25,
            moves: [0, 51, 71, 72, 173, 189, 228, 237, 364, 450, 496, 522, 611]
        }, 617: {
            id: 617,
            name: "accelgor",
            specie: 617,
            is_default: true,
            type1: "bug",
            evolutions: [],
            baseHp: 80,
            baseAtk: 85,
            baseDef: 50,
            baseSpeed: 145,
            moves: [0, 71, 72, 98, 129, 173, 237, 450, 491, 496, 522, 594, 611]
        }, 618: {
            id: 618,
            name: "stunfisk",
            specie: 618,
            is_default: true,
            type1: "ground",
            type2: "electric",
            evolutions: [],
            baseHp: 109,
            baseAtk: 74,
            baseDef: 92,
            baseSpeed: 32,
            moves: [0, 33, 55, 84, 173, 189, 237, 279, 310, 317, 341, 351, 352, 371, 496, 523, 527, 611]
        }, 619: {
            id: 619,
            name: "mienfoo",
            specie: 619,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 620, method: "level_up", minLevel: 50},],
            baseHp: 45,
            baseAtk: 70,
            baseDef: 50,
            baseSpeed: 65,
            moves: [0, 1, 3, 129, 173, 237, 249, 252, 317, 332, 364, 371, 395, 496, 512, 530, 612]
        }, 620: {
            id: 620,
            name: "mienshao",
            specie: 620,
            is_default: true,
            type1: "fighting",
            evolutions: [],
            baseHp: 65,
            baseAtk: 110,
            baseDef: 60,
            baseSpeed: 105,
            moves: [0, 1, 3, 129, 173, 237, 249, 252, 317, 332, 371, 395, 496, 512, 530, 612]
        }, 621: {
            id: 621,
            name: "druddigon",
            specie: 621,
            is_default: true,
            type1: "dragon",
            evolutions: [],
            baseHp: 77,
            baseAtk: 90,
            baseDef: 90,
            baseSpeed: 48,
            moves: [0, 10, 15, 44, 173, 185, 228, 232, 237, 249, 279, 317, 332, 342, 351, 371, 451, 479, 496, 510, 523, 525, 555, 612]
        }, 622: {
            id: 622,
            name: "golett",
            specie: 622,
            is_default: true,
            type1: "ground",
            type2: "ghost",
            evolutions: [{to: 623, method: "level_up", minLevel: 43},],
            baseHp: 59,
            baseAtk: 55,
            baseDef: 50,
            baseSpeed: 35,
            moves: [0, 1, 168, 173, 189, 196, 205, 237, 249, 310, 317, 325, 351, 496, 523, 612]
        }, 623: {
            id: 623,
            name: "golurk",
            specie: 623,
            is_default: true,
            type1: "ground",
            type2: "ghost",
            evolutions: [],
            baseHp: 89,
            baseAtk: 90,
            baseDef: 80,
            baseSpeed: 55,
            moves: [0, 1, 168, 173, 189, 196, 205, 237, 249, 310, 317, 325, 351, 451, 496, 523, 612]
        }, 624: {
            id: 624,
            name: "pawniard",
            specie: 624,
            is_default: true,
            type1: "dark",
            type2: "steel",
            evolutions: [{to: 625, method: "level_up", minLevel: 52},],
            baseHp: 45,
            baseAtk: 63,
            baseDef: 55,
            baseSpeed: 60,
            moves: [0, 10, 15, 168, 173, 185, 206, 210, 228, 232, 237, 249, 279, 317, 332, 371, 372, 496, 530, 555, 612]
        }, 625: {
            id: 625,
            name: "bisharp",
            specie: 625,
            is_default: true,
            type1: "dark",
            type2: "steel",
            evolutions: [],
            baseHp: 65,
            baseAtk: 93,
            baseDef: 85,
            baseSpeed: 70,
            moves: [0, 10, 15, 168, 173, 185, 206, 210, 232, 237, 249, 317, 332, 371, 372, 496, 530, 555, 612]
        }, 626: {
            id: 626,
            name: "bouffalant",
            specie: 626,
            is_default: true,
            type1: "normal",
            evolutions: [],
            baseHp: 95,
            baseAtk: 75,
            baseDef: 95,
            baseSpeed: 55,
            moves: [0, 15, 31, 99, 173, 189, 228, 237, 249, 279, 317, 332, 341, 371, 496, 523]
        }, 627: {
            id: 627,
            name: "rufflet",
            specie: 627,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 628, method: "level_up", minLevel: 54},],
            baseHp: 70,
            baseAtk: 60,
            baseDef: 50,
            baseSpeed: 60,
            moves: [0, 15, 17, 31, 64, 173, 237, 249, 317, 332, 365, 496, 507]
        }, 628: {
            id: 628,
            name: "braviary",
            specie: 628,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [],
            baseHp: 100,
            baseAtk: 90,
            baseDef: 75,
            baseSpeed: 80,
            moves: [0, 15, 17, 31, 64, 173, 237, 249, 317, 332, 365, 496, 507]
        }, 629: {
            id: 629,
            name: "vullaby",
            specie: 629,
            is_default: true,
            type1: "dark",
            type2: "flying",
            evolutions: [{to: 630, method: "level_up", minLevel: 54},],
            baseHp: 70,
            baseAtk: 50,
            baseDef: 70,
            baseSpeed: 60,
            moves: [0, 15, 16, 31, 168, 173, 185, 237, 249, 317, 332, 365, 371, 496, 510, 555]
        }, 630: {
            id: 630,
            name: "mandibuzz",
            specie: 630,
            is_default: true,
            type1: "dark",
            type2: "flying",
            evolutions: [],
            baseHp: 110,
            baseAtk: 60,
            baseDef: 100,
            baseSpeed: 80,
            moves: [0, 15, 16, 31, 168, 173, 185, 198, 237, 249, 317, 332, 365, 371, 496, 510, 555]
        }, 631: {
            id: 631,
            name: "heatmor",
            specie: 631,
            is_default: true,
            type1: "fire",
            evolutions: [],
            baseHp: 85,
            baseAtk: 101,
            baseDef: 66,
            baseSpeed: 65,
            moves: [0, 15, 20, 33, 35, 83, 122, 154, 168, 173, 185, 228, 237, 249, 317, 332, 450, 496, 510, 612]
        }, 632: {
            id: 632,
            name: "durant",
            specie: 632,
            is_default: true,
            type1: "bug",
            type2: "steel",
            evolutions: [],
            baseHp: 58,
            baseAtk: 79,
            baseDef: 80,
            baseSpeed: 109,
            moves: [0, 11, 15, 44, 173, 185, 210, 232, 237, 249, 317, 332, 450, 496, 522]
        }, 633: {
            id: 633,
            name: "deino",
            specie: 633,
            is_default: true,
            type1: "dark",
            type2: "dragon",
            evolutions: [{to: 634, method: "level_up", minLevel: 50},],
            baseHp: 52,
            baseAtk: 55,
            baseDef: 50,
            baseSpeed: 38,
            moves: [0, 33, 44, 168, 173, 225, 237, 249, 310, 351, 372, 458, 496, 510, 525]
        }, 634: {
            id: 634,
            name: "zweilous",
            specie: 634,
            is_default: true,
            type1: "dark",
            type2: "dragon",
            evolutions: [{to: 635, method: "level_up", minLevel: 64},],
            baseHp: 72,
            baseAtk: 75,
            baseDef: 70,
            baseSpeed: 58,
            moves: [0, 44, 168, 173, 225, 237, 249, 351, 458, 496, 510, 525]
        }, 635: {
            id: 635,
            name: "hydreigon",
            specie: 635,
            is_default: true,
            type1: "dark",
            type2: "dragon",
            evolutions: [],
            baseHp: 92,
            baseAtk: 115,
            baseDef: 90,
            baseSpeed: 98,
            moves: [0, 44, 168, 173, 225, 237, 249, 317, 351, 371, 451, 496, 497, 510, 512, 523, 525, 693]
        }, 636: {
            id: 636,
            name: "larvesta",
            specie: 636,
            is_default: true,
            type1: "bug",
            type2: "fire",
            evolutions: [{to: 637, method: "level_up", minLevel: 59},],
            baseHp: 55,
            baseAtk: 68,
            baseDef: 55,
            baseSpeed: 60,
            moves: [0, 52, 71, 172, 173, 237, 450, 488, 496, 510, 512, 522]
        }, 637: {
            id: 637,
            name: "volcarona",
            specie: 637,
            is_default: true,
            type1: "bug",
            type2: "fire",
            evolutions: [],
            baseHp: 85,
            baseAtk: 98,
            baseDef: 85,
            baseSpeed: 100,
            moves: [0, 16, 52, 71, 83, 172, 173, 237, 318, 332, 450, 488, 496, 510, 512, 522]
        }, 638: {
            id: 638,
            name: "cobalion",
            specie: 638,
            is_default: true,
            type1: "steel",
            type2: "fighting",
            evolutions: [],
            baseHp: 91,
            baseAtk: 90,
            baseDef: 101,
            baseSpeed: 108,
            moves: [0, 15, 24, 98, 173, 206, 232, 237, 249, 332, 496]
        }, 639: {
            id: 639,
            name: "terrakion",
            specie: 639,
            is_default: true,
            type1: "rock",
            type2: "fighting",
            evolutions: [],
            baseHp: 91,
            baseAtk: 101,
            baseDef: 90,
            baseSpeed: 108,
            moves: [0, 15, 24, 98, 173, 206, 237, 249, 317, 332, 479, 496, 523]
        }, 640: {
            id: 640,
            name: "virizion",
            specie: 640,
            is_default: true,
            type1: "grass",
            type2: "fighting",
            evolutions: [],
            baseHp: 91,
            baseAtk: 90,
            baseDef: 101,
            baseSpeed: 108,
            moves: [0, 15, 24, 98, 173, 206, 237, 249, 332, 345, 496]
        }, 641: {
            id: 641,
            name: "tornadus-incarnate",
            specie: 641,
            is_default: true,
            type1: "flying",
            evolutions: [],
            baseHp: 79,
            baseAtk: 120,
            baseDef: 75,
            baseSpeed: 111,
            moves: [0, 16, 44, 168, 173, 196, 237, 249, 279, 310, 314, 332, 371, 479, 496, 507, 510, 512, 693]
        }, 642: {
            id: 642,
            name: "thundurus-incarnate",
            specie: 642,
            is_default: true,
            type1: "electric",
            type2: "flying",
            evolutions: [],
            baseHp: 79,
            baseAtk: 120,
            baseDef: 75,
            baseSpeed: 111,
            moves: [0, 44, 84, 168, 173, 237, 249, 279, 310, 351, 371, 451, 479, 496, 507, 510, 527, 693]
        }, 643: {
            id: 643,
            name: "reshiram",
            specie: 643,
            is_default: true,
            type1: "dragon",
            type2: "fire",
            evolutions: [],
            baseHp: 100,
            baseAtk: 135,
            baseDef: 110,
            baseSpeed: 90,
            moves: [0, 15, 173, 225, 237, 246, 249, 317, 371, 488, 496, 497, 510, 525, 693]
        }, 644: {
            id: 644,
            name: "zekrom",
            specie: 644,
            is_default: true,
            type1: "dragon",
            type2: "electric",
            evolutions: [],
            baseHp: 100,
            baseAtk: 135,
            baseDef: 110,
            baseSpeed: 90,
            moves: [0, 15, 173, 225, 237, 246, 249, 317, 351, 371, 451, 496, 497, 525, 693]
        }, 645: {
            id: 645,
            name: "landorus-incarnate",
            specie: 645,
            is_default: true,
            type1: "ground",
            type2: "flying",
            evolutions: [],
            baseHp: 89,
            baseAtk: 120,
            baseDef: 85,
            baseSpeed: 101,
            moves: [0, 88, 173, 237, 249, 317, 341, 371, 479, 496, 523, 693]
        }, 646: {
            id: 646,
            name: "kyurem",
            specie: 646,
            is_default: true,
            type1: "dragon",
            type2: "ice",
            evolutions: [],
            baseHp: 125,
            baseAtk: 130,
            baseDef: 90,
            baseSpeed: 95,
            moves: [0, 15, 173, 196, 225, 237, 246, 249, 317, 371, 496, 497, 525, 693]
        }, 647: {
            id: 647,
            name: "keldeo-ordinary",
            specie: 647,
            is_default: true,
            type1: "water",
            type2: "fighting",
            evolutions: [],
            baseHp: 91,
            baseAtk: 101,
            baseDef: 90,
            baseSpeed: 108,
            moves: [0, 15, 24, 173, 196, 206, 237, 249, 332, 343, 352, 453, 496]
        }, 648: {
            id: 648,
            name: "meloetta-aria",
            specie: 648,
            is_default: true,
            type1: "normal",
            type2: "psychic",
            evolutions: [],
            baseHp: 100,
            baseAtk: 103,
            baseDef: 103,
            baseSpeed: 90,
            moves: [0, 93, 98, 173, 237, 249, 343, 351, 371, 451, 496, 497, 512, 530, 612]
        }, 649: {
            id: 649,
            name: "genesect",
            specie: 649,
            is_default: true,
            type1: "bug",
            type2: "steel",
            evolutions: [],
            baseHp: 71,
            baseAtk: 120,
            baseDef: 95,
            baseSpeed: 99,
            moves: [0, 98, 173, 210, 232, 237, 332, 351, 443, 450, 451, 488, 496, 522, 527, 565, 611]
        }, 650: {
            id: 650,
            name: "chespin",
            specie: 650,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 651, method: "level_up", minLevel: 16},],
            baseHp: 56,
            baseAtk: 55,
            baseDef: 55,
            baseSpeed: 38,
            moves: [0, 15, 22, 33, 42, 44, 173, 205, 237, 249, 317, 332, 341, 371, 479, 496, 523, 530, 612]
        }, 651: {
            id: 651,
            name: "quilladin",
            specie: 651,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 652, method: "level_up", minLevel: 36},],
            baseHp: 61,
            baseAtk: 67,
            baseDef: 77,
            baseSpeed: 57,
            moves: [0, 15, 22, 33, 42, 44, 173, 205, 237, 249, 302, 317, 332, 341, 371, 479, 496, 523, 530, 612]
        }, 652: {
            id: 652,
            name: "chesnaught",
            specie: 652,
            is_default: true,
            type1: "grass",
            type2: "fighting",
            evolutions: [],
            baseHp: 88,
            baseAtk: 91,
            baseDef: 99,
            baseSpeed: 64,
            moves: [0, 15, 22, 33, 42, 44, 173, 205, 237, 249, 302, 317, 332, 341, 364, 371, 479, 496, 523, 530, 612]
        }, 653: {
            id: 653,
            name: "fennekin",
            specie: 653,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 654, method: "level_up", minLevel: 16},],
            baseHp: 40,
            baseAtk: 54,
            baseDef: 50,
            baseSpeed: 60,
            moves: [0, 10, 15, 52, 83, 168, 173, 237, 343, 488, 496, 497, 510, 612]
        }, 654: {
            id: 654,
            name: "braixen",
            specie: 654,
            is_default: true,
            type1: "fire",
            evolutions: [{to: 655, method: "level_up", minLevel: 36},],
            baseHp: 59,
            baseAtk: 75,
            baseDef: 64,
            baseSpeed: 73,
            moves: [0, 10, 15, 52, 83, 168, 173, 237, 343, 351, 488, 496, 497, 510, 612]
        }, 655: {
            id: 655,
            name: "delphox",
            specie: 655,
            is_default: true,
            type1: "fire",
            type2: "psychic",
            evolutions: [],
            baseHp: 75,
            baseAtk: 92,
            baseDef: 86,
            baseSpeed: 104,
            moves: [0, 10, 15, 52, 83, 168, 173, 237, 343, 351, 488, 496, 497, 510, 612]
        }, 656: {
            id: 656,
            name: "froakie",
            specie: 656,
            is_default: true,
            type1: "water",
            evolutions: [{to: 657, method: "level_up", minLevel: 16},],
            baseHp: 41,
            baseAtk: 59,
            baseDef: 42,
            baseSpeed: 71,
            moves: [0, 1, 15, 98, 122, 145, 168, 173, 196, 237, 249, 317, 332, 352, 479, 496, 497, 512, 612]
        }, 657: {
            id: 657,
            name: "frogadier",
            specie: 657,
            is_default: true,
            type1: "water",
            evolutions: [{to: 658, method: "level_up", minLevel: 36},],
            baseHp: 54,
            baseAtk: 73,
            baseDef: 54,
            baseSpeed: 97,
            moves: [0, 1, 15, 98, 122, 145, 168, 173, 196, 237, 249, 317, 332, 352, 479, 496, 497, 512, 612]
        }, 658: {
            id: 658,
            name: "greninja",
            specie: 658,
            is_default: true,
            type1: "water",
            type2: "dark",
            evolutions: [],
            baseHp: 72,
            baseAtk: 99,
            baseDef: 69,
            baseSpeed: 122,
            moves: [0, 1, 15, 98, 122, 145, 168, 173, 185, 196, 237, 249, 317, 332, 352, 425, 479, 496, 497, 512, 594, 612, 693]
        }, 659: {
            id: 659,
            name: "bunnelby",
            specie: 659,
            is_default: true,
            type1: "normal",
            evolutions: [{to: 660, method: "level_up", minLevel: 20},],
            baseHp: 38,
            baseAtk: 34,
            baseDef: 37,
            baseSpeed: 57,
            moves: [0, 3, 15, 24, 33, 98, 168, 173, 189, 205, 237, 249, 317, 341, 371, 479, 496, 523, 612]
        }, 660: {
            id: 660,
            name: "diggersby",
            specie: 660,
            is_default: true,
            type1: "normal",
            type2: "ground",
            evolutions: [],
            baseHp: 85,
            baseAtk: 53,
            baseDef: 77,
            baseSpeed: 78,
            moves: [0, 3, 15, 24, 33, 98, 168, 173, 189, 237, 249, 317, 341, 371, 479, 496, 523, 612, 693]
        }, 661: {
            id: 661,
            name: "fletchling",
            specie: 661,
            is_default: true,
            type1: "normal",
            type2: "flying",
            evolutions: [{to: 662, method: "level_up", minLevel: 17},],
            baseHp: 45,
            baseAtk: 45,
            baseDef: 41,
            baseSpeed: 62,
            moves: [0, 33, 64, 98, 168, 173, 237, 332, 488, 496, 512]
        }, 662: {
            id: 662,
            name: "fletchinder",
            specie: 662,
            is_default: true,
            type1: "fire",
            type2: "flying",
            evolutions: [{to: 663, method: "level_up", minLevel: 35},],
            baseHp: 62,
            baseAtk: 65,
            baseDef: 54,
            baseSpeed: 84,
            moves: [0, 33, 52, 64, 98, 168, 173, 237, 332, 488, 496, 510, 512]
        }, 663: {
            id: 663,
            name: "talonflame",
            specie: 663,
            is_default: true,
            type1: "fire",
            type2: "flying",
            evolutions: [],
            baseHp: 78,
            baseAtk: 78,
            baseDef: 70,
            baseSpeed: 126,
            moves: [0, 33, 52, 64, 98, 168, 173, 237, 332, 488, 496, 510, 512]
        }, 664: {
            id: 664,
            name: "scatterbug",
            specie: 664,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 665, method: "level_up", minLevel: 9},],
            baseHp: 38,
            baseAtk: 31,
            baseDef: 33,
            baseSpeed: 35,
            moves: [0, 33, 450]
        }, 665: {
            id: 665,
            name: "spewpa",
            specie: 665,
            is_default: true,
            type1: "bug",
            evolutions: [{to: 666, method: "level_up", minLevel: 12},],
            baseHp: 45,
            baseAtk: 25,
            baseDef: 45,
            baseSpeed: 29,
            moves: [0, 450, 527]
        }, 666: {
            id: 666,
            name: "vivillon",
            specie: 666,
            is_default: true,
            type1: "bug",
            type2: "flying",
            evolutions: [],
            baseHp: 80,
            baseAtk: 71,
            baseDef: 50,
            baseSpeed: 89,
            moves: [0, 16, 168, 173, 237, 332, 450, 496, 512, 522, 527, 577, 611]
        }, 667: {
            id: 667,
            name: "litleo",
            specie: 667,
            is_default: true,
            type1: "fire",
            type2: "normal",
            evolutions: [{to: 668, method: "level_up", minLevel: 35},],
            baseHp: 62,
            baseAtk: 62,
            baseDef: 56,
            baseSpeed: 72,
            moves: [0, 33, 52, 83, 168, 173, 237, 249, 371, 488, 496, 497, 510, 523, 555]
        }, 668: {
            id: 668,
            name: "pyroar",
            specie: 668,
            is_default: true,
            type1: "fire",
            type2: "normal",
            evolutions: [],
            baseHp: 86,
            baseAtk: 89,
            baseDef: 69,
            baseSpeed: 106,
            moves: [0, 33, 52, 168, 173, 237, 249, 371, 488, 496, 497, 510, 523, 555]
        }, 669: {
            id: 669,
            name: "flabebe",
            specie: 669,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 670, method: "level_up", minLevel: 19},],
            baseHp: 44,
            baseAtk: 50,
            baseDef: 59,
            baseSpeed: 42,
            moves: [0, 22, 33, 75, 173, 237, 343, 345, 496, 497, 584]
        }, 670: {
            id: 670,
            name: "floette",
            specie: 670,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 671, method: "level_up", minLevel: 32},],
            baseHp: 54,
            baseAtk: 60,
            baseDef: 73,
            baseSpeed: 52,
            moves: [0, 22, 33, 75, 173, 237, 343, 345, 496, 497, 584]
        }, 671: {
            id: 671, name: "florges", specie: 671, is_default: true, type1: "fairy", evolutions: [],
            baseHp: 78, baseAtk: 89, baseDef: 111, baseSpeed: 75, moves: [0, 173, 237, 343, 345, 496, 497, 574]
        }, 672: {
            id: 672,
            name: "skiddo",
            specie: 672,
            is_default: true,
            type1: "grass",
            evolutions: [{to: 673, method: "level_up", minLevel: 32},],
            baseHp: 66,
            baseAtk: 64,
            baseDef: 53,
            baseSpeed: 52,
            moves: [0, 22, 33, 75, 173, 205, 237, 249, 371, 496, 523]
        }, 673: {
            id: 673,
            name: "gogoat",
            specie: 673,
            is_default: true,
            type1: "grass",
            evolutions: [],
            baseHp: 123,
            baseAtk: 99,
            baseDef: 72,
            baseSpeed: 68,
            moves: [0, 22, 33, 75, 173, 237, 249, 332, 371, 496, 523]
        }, 674: {
            id: 674,
            name: "pancham",
            specie: 674,
            is_default: true,
            type1: "fighting",
            evolutions: [{to: 675, method: "level_up", minLevel: 32},],
            baseHp: 67,
            baseAtk: 64,
            baseDef: 55,
            baseSpeed: 43,
            moves: [0, 2, 4, 15, 33, 173, 206, 237, 249, 292, 317, 332, 343, 371, 480, 496, 497, 509, 523, 530, 612, 681]
        }, 675: {
            id: 675,
            name: "pangoro",
            specie: 675,
            is_default: true,
            type1: "fighting",
            type2: "dark",
            evolutions: [],
            baseHp: 95,
            baseAtk: 97,
            baseDef: 75,
            baseSpeed: 58,
            moves: [0, 2, 4, 15, 33, 168, 173, 206, 237, 249, 292, 317, 332, 343, 371, 418, 496, 497, 509, 523, 530, 555, 611, 612]
        }, 676: {
            id: 676, name: "furfrou", specie: 676, is_default: true, type1: "normal", evolutions: [],
            baseHp: 75, baseAtk: 73, baseDef: 75, baseSpeed: 102, moves: [0, 33, 44, 173, 237, 249, 451, 496, 497, 555]
        }, 677: {
            id: 677,
            name: "espurr",
            specie: 677,
            is_default: true,
            type1: "psychic",
            evolutions: [{to: 678, method: "level_up", minLevel: 25},],
            baseHp: 62,
            baseAtk: 56,
            baseDef: 57,
            baseSpeed: 68,
            moves: [0, 10, 15, 93, 173, 237, 252, 343, 351, 371, 451, 496, 497, 574]
        }, 678: {
            id: 678,
            name: "meowstic-male",
            specie: 678,
            is_default: true,
            type1: "psychic",
            evolutions: [],
            baseHp: 74,
            baseAtk: 66,
            baseDef: 79,
            baseSpeed: 104,
            moves: [0, 10, 15, 93, 173, 237, 252, 343, 351, 371, 451, 496, 497, 574, 612]
        }, 679: {
            id: 679,
            name: "honedge",
            specie: 679,
            is_default: true,
            type1: "steel",
            type2: "ghost",
            evolutions: [{to: 680, method: "level_up", minLevel: 35},],
            baseHp: 45,
            baseAtk: 58,
            baseDef: 69,
            baseSpeed: 28,
            moves: [0, 15, 33, 173, 206, 210, 228, 237, 249, 332, 351, 425, 693]
        }, 680: {
            id: 680,
            name: "doublade",
            specie: 680,
            is_default: true,
            type1: "steel",
            type2: "ghost",
            evolutions: [{to: 681, method: "level_up", minLevel: 32},],
            baseHp: 59,
            baseAtk: 78,
            baseDef: 100,
            baseSpeed: 35,
            moves: [0, 15, 33, 173, 206, 210, 228, 237, 249, 332, 351, 425, 693]
        }, 681: {
            id: 681,
            name: "aegislash-shield",
            specie: 681,
            is_default: true,
            type1: "steel",
            type2: "ghost",
            evolutions: [],
            baseHp: 60,
            baseAtk: 50,
            baseDef: 150,
            baseSpeed: 60,
            moves: [0, 15, 173, 206, 210, 228, 237, 249, 332, 351, 425, 496, 693]
        }, 682: {
            id: 682,
            name: "spritzee",
            specie: 682,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 683, method: "level_up", minLevel: 32},],
            baseHp: 78,
            baseAtk: 58,
            baseDef: 63,
            baseSpeed: 23,
            moves: [0, 173, 237, 343, 451, 496, 497, 574, 577, 584]
        }, 683: {
            id: 683,
            name: "aromatisse",
            specie: 683,
            is_default: true,
            type1: "fairy",
            evolutions: [],
            baseHp: 101,
            baseAtk: 86,
            baseDef: 81,
            baseSpeed: 29,
            moves: [0, 173, 237, 343, 451, 496, 497, 574, 577, 584]
        }, 684: {
            id: 684,
            name: "swirlix",
            specie: 684,
            is_default: true,
            type1: "fairy",
            evolutions: [{to: 685, method: "level_up", minLevel: 32},],
            baseHp: 62,
            baseAtk: 54,
            baseDef: 62,
            baseSpeed: 49,
            moves: [0, 33, 168, 173, 237, 343, 496, 577, 584]
        }, 685: {
            id: 685, name: "slurpuff", specie: 685, is_default: true, type1: "fairy", evolutions: [],
            baseHp: 82, baseAtk: 83, baseDef: 81, baseSpeed: 72, moves: [0, 33, 168, 173, 237, 343, 496, 577, 584]
        }, 686: {
            id: 686,
            name: "inkay",
            specie: 686,
            is_default: true,
            type1: "dark",
            type2: "psychic",
            evolutions: [{to: 687, method: "level_up", minLevel: 30},],
            baseHp: 53,
            baseAtk: 46,
            baseDef: 50,
            baseSpeed: 45,
            moves: [0, 15, 20, 33, 64, 132, 168, 173, 237, 332, 365, 371, 496]
        }, 687: {
            id: 687,
            name: "malamar",
            specie: 687,
            is_default: true,
            type1: "dark",
            type2: "psychic",
            evolutions: [],
            baseHp: 86,
            baseAtk: 80,
            baseDef: 82,
            baseSpeed: 73,
            moves: [0, 15, 20, 33, 64, 132, 168, 173, 237, 332, 365, 371, 496, 693]
        }, 688: {
            id: 688,
            name: "binacle",
            specie: 688,
            is_default: true,
            type1: "rock",
            type2: "water",
            evolutions: [{to: 689, method: "level_up", minLevel: 39},],
            baseHp: 42,
            baseAtk: 46,
            baseDef: 62,
            baseSpeed: 50,
            moves: [0, 10, 15, 55, 128, 154, 168, 173, 189, 196, 206, 210, 237, 246, 249, 317, 332, 352, 371, 479, 496, 523, 530, 611, 612]
        }, 689: {
            id: 689,
            name: "barbaracle",
            specie: 689,
            is_default: true,
            type1: "rock",
            type2: "water",
            evolutions: [],
            baseHp: 72,
            baseAtk: 80,
            baseDef: 101,
            baseSpeed: 68,
            moves: [0, 10, 15, 55, 128, 154, 168, 173, 189, 196, 206, 210, 237, 246, 249, 317, 332, 352, 371, 479, 496, 523, 530, 611, 612, 693]
        }, 690: {
            id: 690,
            name: "skrelp",
            specie: 690,
            is_default: true,
            type1: "poison",
            type2: "water",
            evolutions: [{to: 691, method: "level_up", minLevel: 48},],
            baseHp: 50,
            baseAtk: 60,
            baseDef: 60,
            baseSpeed: 30,
            moves: [0, 33, 51, 55, 145, 173, 185, 196, 237, 342, 351, 352, 496]
        }, 691: {
            id: 691,
            name: "dragalge",
            specie: 691,
            is_default: true,
            type1: "poison",
            type2: "dragon",
            evolutions: [],
            baseHp: 65,
            baseAtk: 86,
            baseDef: 107,
            baseSpeed: 44,
            moves: [0, 33, 51, 55, 145, 173, 185, 196, 237, 239, 342, 351, 352, 496, 525]
        }, 692: {
            id: 692,
            name: "clauncher",
            specie: 692,
            is_default: true,
            type1: "water",
            evolutions: [{to: 693, method: "level_up", minLevel: 37},],
            baseHp: 50,
            baseAtk: 56,
            baseDef: 63,
            baseSpeed: 44,
            moves: [0, 11, 15, 55, 145, 173, 196, 237, 352, 453, 479, 496]
        }, 693: {
            id: 693,
            name: "clawitzer",
            specie: 693,
            is_default: true,
            type1: "water",
            evolutions: [],
            baseHp: 71,
            baseAtk: 97,
            baseDef: 89,
            baseSpeed: 59,
            moves: [0, 11, 15, 55, 145, 173, 196, 237, 352, 453, 479, 496]
        }, 694: {
            id: 694,
            name: "helioptile",
            specie: 694,
            is_default: true,
            type1: "electric",
            type2: "normal",
            evolutions: [{to: 695, method: "level_up", minLevel: 32},],
            baseHp: 44,
            baseAtk: 50,
            baseDef: 38,
            baseSpeed: 70,
            moves: [0, 1, 15, 84, 98, 173, 189, 237, 317, 351, 451, 496, 523, 525, 527]
        }, 695: {
            id: 695,
            name: "heliolisk",
            specie: 695,
            is_default: true,
            type1: "electric",
            type2: "normal",
            evolutions: [],
            baseHp: 62,
            baseAtk: 82,
            baseDef: 73,
            baseSpeed: 109,
            moves: [0, 15, 98, 173, 237, 317, 351, 451, 496, 523, 525, 527, 693]
        }, 696: {
            id: 696,
            name: "tyrunt",
            specie: 696,
            is_default: true,
            type1: "rock",
            type2: "dragon",
            evolutions: [{to: 697, method: "level_up", minLevel: 39},],
            baseHp: 58,
            baseAtk: 67,
            baseDef: 61,
            baseSpeed: 48,
            moves: [0, 33, 44, 173, 237, 246, 249, 305, 317, 332, 496, 523, 525]
        }, 697: {
            id: 697,
            name: "tyrantrum",
            specie: 697,
            is_default: true,
            type1: "rock",
            type2: "dragon",
            evolutions: [],
            baseHp: 82,
            baseAtk: 95,
            baseDef: 89,
            baseSpeed: 71,
            moves: [0, 33, 44, 173, 237, 246, 249, 317, 332, 496, 523, 525, 693]
        }, 698: {
            id: 698,
            name: "amaura",
            specie: 698,
            is_default: true,
            type1: "rock",
            type2: "ice",
            evolutions: [{to: 699, method: "level_up", minLevel: 39},],
            baseHp: 77,
            baseAtk: 63,
            baseDef: 57,
            baseSpeed: 46,
            moves: [0, 88, 173, 181, 196, 237, 246, 249, 317, 352, 419, 451, 496, 497, 523, 524, 525]
        }, 699: {
            id: 699,
            name: "aurorus",
            specie: 699,
            is_default: true,
            type1: "rock",
            type2: "ice",
            evolutions: [],
            baseHp: 123,
            baseAtk: 88,
            baseDef: 82,
            baseSpeed: 58,
            moves: [0, 88, 173, 181, 196, 237, 246, 249, 317, 352, 419, 451, 496, 497, 523, 524, 525]
        }, 700: {
            id: 700,
            name: "sylveon",
            specie: 700,
            is_default: true,
            type1: "fairy",
            evolutions: [],
            baseHp: 95,
            baseAtk: 88,
            baseDef: 98,
            baseSpeed: 60,
            moves: [0, 15, 33, 98, 129, 173, 237, 343, 496, 497, 574, 577, 584]
        }, 701: {
            id: 701,
            name: "hawlucha",
            specie: 701,
            is_default: true,
            type1: "fighting",
            type2: "flying",
            evolutions: [],
            baseHp: 78,
            baseAtk: 83,
            baseDef: 69,
            baseSpeed: 118,
            moves: [0, 2, 15, 17, 33, 173, 206, 237, 249, 317, 332, 364, 371, 496, 507, 512, 530, 612]
        }, 702: {
            id: 702,
            name: "dedenne",
            specie: 702,
            is_default: true,
            type1: "electric",
            type2: "fairy",
            evolutions: [],
            baseHp: 67,
            baseAtk: 70,
            baseDef: 62,
            baseSpeed: 101,
            moves: [0, 15, 33, 84, 168, 173, 237, 332, 343, 351, 451, 496, 527, 609]
        }, 703: {
            id: 703, name: "carbink", specie: 703, is_default: true, type1: "rock", type2: "fairy", evolutions: [],
            baseHp: 50, baseAtk: 50, baseDef: 150, baseSpeed: 50, moves: [0, 33, 88, 173, 237, 246, 317, 343, 479, 496]
        }, 704: {
            id: 704,
            name: "goomy",
            specie: 704,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 705, method: "level_up", minLevel: 40},],
            baseHp: 45,
            baseAtk: 53,
            baseDef: 55,
            baseSpeed: 40,
            moves: [0, 33, 71, 145, 173, 225, 237, 342, 351, 352, 496, 611]
        }, 705: {
            id: 705,
            name: "sliggoo",
            specie: 705,
            is_default: true,
            type1: "dragon",
            evolutions: [{to: 706, method: "level_up", minLevel: 50},],
            baseHp: 68,
            baseAtk: 79,
            baseDef: 83,
            baseSpeed: 60,
            moves: [0, 33, 71, 145, 173, 225, 237, 351, 352, 496, 611]
        }, 706: {
            id: 706,
            name: "goodra",
            specie: 706,
            is_default: true,
            type1: "dragon",
            evolutions: [],
            baseHp: 90,
            baseAtk: 105,
            baseDef: 110,
            baseSpeed: 80,
            moves: [0, 33, 71, 145, 173, 225, 237, 249, 351, 352, 364, 496, 510, 523, 525, 611, 693]
        }, 707: {
            id: 707,
            name: "klefki",
            specie: 707,
            is_default: true,
            type1: "steel",
            type2: "fairy",
            evolutions: [],
            baseHp: 57,
            baseAtk: 80,
            baseDef: 89,
            baseSpeed: 75,
            moves: [0, 15, 33, 168, 173, 237, 310, 343, 496, 577, 584]
        }, 708: {
            id: 708,
            name: "phantump",
            specie: 708,
            is_default: true,
            type1: "ghost",
            type2: "grass",
            evolutions: [{to: 709, method: "level_up", minLevel: 32},],
            baseHp: 43,
            baseAtk: 60,
            baseDef: 54,
            baseSpeed: 38,
            moves: [0, 15, 33, 168, 173, 185, 237, 249, 310, 496, 523, 612]
        }, 709: {
            id: 709,
            name: "trevenant",
            specie: 709,
            is_default: true,
            type1: "ghost",
            type2: "grass",
            evolutions: [],
            baseHp: 85,
            baseAtk: 88,
            baseDef: 79,
            baseSpeed: 56,
            moves: [0, 15, 33, 168, 173, 185, 237, 249, 310, 496, 523, 612, 693]
        }, 710: {
            id: 710,
            name: "pumpkaboo-average",
            specie: 710,
            is_default: true,
            type1: "ghost",
            type2: "grass",
            evolutions: [],
            baseHp: 49,
            baseAtk: 55,
            baseDef: 63,
            baseSpeed: 51,
            moves: [0, 75, 168, 237, 249, 310, 331, 425, 451, 488, 496, 510]
        }, 711: {
            id: 711,
            name: "gourgeist-average",
            specie: 711,
            is_default: true,
            type1: "ghost",
            type2: "grass",
            evolutions: [],
            baseHp: 65,
            baseAtk: 74,
            baseDef: 99,
            baseSpeed: 84,
            moves: [0, 75, 168, 237, 249, 310, 331, 425, 451, 488, 496, 510]
        }, 712: {
            id: 712,
            name: "bergmite",
            specie: 712,
            is_default: true,
            type1: "ice",
            evolutions: [{to: 713, method: "level_up", minLevel: 37},],
            baseHp: 55,
            baseAtk: 51,
            baseDef: 60,
            baseSpeed: 28,
            moves: [0, 33, 44, 173, 181, 196, 229, 237, 249, 301, 317, 352, 419, 496, 524]
        }, 713: {
            id: 713,
            name: "avalugg",
            specie: 713,
            is_default: true,
            type1: "ice",
            evolutions: [],
            baseHp: 95,
            baseAtk: 81,
            baseDef: 115,
            baseSpeed: 28,
            moves: [0, 33, 44, 173, 181, 196, 229, 237, 249, 301, 317, 352, 419, 496, 523, 524]
        }, 714: {
            id: 714,
            name: "noibat",
            specie: 714,
            is_default: true,
            type1: "flying",
            type2: "dragon",
            evolutions: [{to: 715, method: "level_up", minLevel: 48},],
            baseHp: 40,
            baseAtk: 38,
            baseDef: 38,
            baseSpeed: 55,
            moves: [0, 15, 16, 17, 33, 44, 71, 168, 173, 237, 314, 332, 352, 496, 497, 512]
        }, 715: {
            id: 715,
            name: "noivern",
            specie: 715,
            is_default: true,
            type1: "flying",
            type2: "dragon",
            evolutions: [],
            baseHp: 85,
            baseAtk: 84,
            baseDef: 80,
            baseSpeed: 123,
            moves: [0, 15, 16, 17, 33, 44, 71, 168, 173, 237, 314, 332, 352, 496, 497, 512]
        }, 716: {
            id: 716, name: "xerneas", specie: 716, is_default: true, type1: "fairy", evolutions: [],
            baseHp: 126, baseAtk: 131, baseDef: 97, baseSpeed: 99, moves: [0, 15, 173, 237, 496, 497]
        }, 717: {
            id: 717,
            name: "yveltal",
            specie: 717,
            is_default: true,
            type1: "dark",
            type2: "flying",
            evolutions: [],
            baseHp: 126,
            baseAtk: 131,
            baseDef: 97,
            baseSpeed: 99,
            moves: [0, 15, 168, 173, 237, 332, 496, 507, 512, 555]
        }, 718: {
            id: 718,
            name: "zygarde",
            specie: 718,
            is_default: true,
            type1: "dragon",
            type2: "ground",
            evolutions: [],
            baseHp: 108,
            baseAtk: 91,
            baseDef: 108,
            baseSpeed: 95,
            moves: [0, 20, 44, 173, 225, 237, 249, 351, 496, 523, 525]
        }, 719: {
            id: 719, name: "diancie", specie: 719, is_default: true, type1: "rock", type2: "fairy", evolutions: [],
            baseHp: 50, baseAtk: 100, baseDef: 150, baseSpeed: 50, moves: [0, 33, 88, 173, 237, 246, 317, 343, 479, 496]
        }, 720: {
            id: 720,
            name: "hoopa",
            specie: 720,
            is_default: true,
            type1: "psychic",
            type2: "ghost",
            evolutions: [],
            baseHp: 80,
            baseAtk: 130,
            baseDef: 95,
            baseSpeed: 70,
            moves: [0, 93, 168, 173, 237, 310, 343, 351, 451, 496, 530, 612]
        }, 721: {
            id: 721,
            name: "volcanion",
            specie: 721,
            is_default: true,
            type1: "fire",
            type2: "water",
            evolutions: [],
            baseHp: 80,
            baseAtk: 120,
            baseDef: 105,
            baseSpeed: 70,
            moves: [0, 15, 173, 237, 249, 311, 352, 479, 488, 496, 510, 523]
        },
    }
